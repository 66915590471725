import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { API_URL } from '../../../constants';


export default function GeneralBanner({label, preset, filter}){
    const [imageUrl, setImageUrl] = React.useState("")
    axios.get(`https://${API_URL()}/adminAPI/api/resources/banner/actions/list?filters.page=${preset}`).then((res)=>{

        setImageUrl(res.data.records[0].params.filePath);
      }).catch(error => {console.log(`Error: ${error}`)});

    
    return (
        <Box sx={{width: "100vw", height:"50vh", position:"relative", top:"0", display:'flex', justifyContent: 'center', alignItems:'center'}}>
            <Box component="div"    
                sx={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${imageUrl})`,
                position: "absolute",
                top: "0",
                zIndex: "-1",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                }}>
                    <Box sx={{position: 'absolute', height:'100%', width: '100%', backgroundColor: 'black', opacity: '0.2'}}></Box>

                
                </Box>
                <Typography variant='h1' sx={{color:'white', fontWeight: 'bold', textAlign:'center', px:{xs:'2rem', md:'10rem'}}}>{label}</Typography>
        </Box>
    )
}

