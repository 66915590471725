import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "kr",
  supportedLngs: ["en", "kr"],
  interpolation: {
    escapeValue: false,
  },
  lng: 'kr',
    resources: {
        en: {
            translation: {
                company: "Company",
                aboutus: "About Us",
                aboutus_culture: "Culture",
                aboutus_cert: 'Awards & Certifications',
                nzOffice:'New Zealand Office Address',
                awards:'Awards',
                certification:'Certification',
                patents:'Patents',
                logo: "TOVNET CI",
                headoffice: "Head Office",
                storelocation: "Branch Office",
                products: 'Products',
                media: "Media",
                news: "TOVNET News",
                news_content:"Explore the latest news from TOVNET",
                event: "Event",
                installationcase: "Installation Case",
                catalogue: "Catalogue",
                support: "Support",
                faq: "FAQ",
                notice: 'Announcement',
                userguide: 'User Manual',
                contactus: 'Contact Us',
                marketingmaterials: 'Marketing Materials',
                address: "Floor 8, 680 Tongil-ro, Eunpyeong-gu, Seoul, South Korea",
                phone: "+82 10 5389 3983",
                "General Product": "Products",
                Accessory: 'Accessories',
                "TOVNET News": 'TOVNET News',
                "Other Products":'Other Products',
                Youtube:"Youtube",
                home_banner1: "NEW Innovative Technology",
                home_banner2: "We are community that embraces the sharing of happiness",
                home_banner3: "Enhance your daily life by elevating convenience and security with TOVNET",
                news_banner: "TOVNET Co., Ltd. is a distinguished partner in the establishment of diverse business areas, catering to a wide range of customers, from enterprises to individuals, through its wireless network-based ubiquitous healthcare services and proprietary wireless network technology development. With its technical expertise extending to wireless LED bulbs, CCTV, and digital signage solutions, TOVNET has secured a prominent position in the realm of 'Next-Generation Smart Network Environments'.",
                viewmore: "View More",
                home_viewmore: "View Products",
                contactusInfo: "Enquiries on products and solution development",
                ceo_greeting: "CEO's Greeting",
                ceo_name: "Chan-Young Kim",
                ceo_position: "CEO of TOVNET Co., Ltd.",
                ceo_message1: `TOVNET is a community that shares joy.`,
                ceo_message2: `Based on video compression technology, Internet of Things (IoT) technology, and Ai technology, TOVNET Co., Ltd. has developed a number of innovative products, registered domestic and foreign patents, registered KFDA items, certified NET, certified performance, received software grade 1, technology development product, and innovation prototype certification.`,
                 ceo_message3: `TOVNET Co., Ltd. puts the technology and products needed by consumers as primary priority and will continue to innovate to develop services and products.`,
              ceo_message4: `Please continue to show us your support and prayers for TOVNET.` ,
                history: "History",
                logo_content: `TOV is the ultimate expression of joy. We will continuously develop innovative services and products by first considering the technology and products that consumers need.`,
                headOffice: 'Head Office Address',
                head_address: 'Floor 8, 680, Tongil-ro, Eunpyeong-gu, Seoul, Republic of Korea',
                home_products: 'TOVNET Products',
                home_manual: 'Manual Download',
                event_banner1:'Event',
                event_banner2: "TOVNET Co., Ltd has established a solid presence as an excellent partner in building diverse business areas catering to a wide range of customers, spanning from corporate to individual clientele. This achievement is underpinned by their wireless network-based ubiquitous healthcare services and proprietary wireless network technology development. Furthermore, they have harnessed their technological capabilities to encompass wireless LED lighting, CCTV, and digital signage solutions, solidifying their position as a key player in shaping the next-generation smart network environment.",
                catalogue_banner: 'TOVNET Catalogue download is available below.',
                faq_banner:"We've got the answers for frequently asked questions.",
                none: '',
                youtube:'YouTube',
                strength1: 'Video Compression',
                strength2: 'Transmission Technology',
                strength3: 'Energy-Saving Technology',
                strength4: 'Energy Optimization',
                strength5: 'Development of Eco-Friendly Carbon-Neutral Products',
                strength:'Strength',
                culture1: 'Innovation',
                culture2: 'TOVNET is a company that prioritizes environmental friendliness and complies with carbon neutrality policies. We supply eco-friendly products and artificial intelligence video analysis solutions. TOVNET is committed to achieving greater customer satisfaction and value by efficiently addressing the problems our customers need solving through innovative product development and service enhancements.',
                culture3:'Reliability',
                culture4:'Established in 2012, TOVNET has consistently developed innovative products and gained trust not only in the domestic market but also in the global market. We will provide a trusted total solution through continuous innovation in products and services.',
                culture5:'Customer Satisfaction',
                culture6:'Customer satisfaction for businesses and customers using TOVNET products is at the core of our values. We strive to exceed customer expectations by offering differentiated products and services of the highest quality through innovative product development and efforts.',
                culture7:'Sustainable Growth',
                culture8: 'As a company that adheres to environmentally friendly carbon neutrality policies, TOVNET aims to be a leading company that future generations will remember, focusing on eco-friendly products and upholding corporate values.',
                product_tab1: 'Description',
                product_tab2: 'Manual',
                product_tab3: 'Support',
                contactus1: 'General',
                contactus2: 'Purchase',
                category: 'Category',
                email: 'Email',
                title:'Title',
                message:'Message',
                submit:'Submit',
                headofficebanner:"Head Office",
                korea: 'Korea',
                newzealand: 'New Zealand',
                america:'USA',
                product_viewmore: 'View More',
                download: "Download",
                chatbot_intro1: "Hello, This is TOVNET.",
                chatbot_intro2: "Ask me anything.",
                chatbot_intro3: "Type 'quit' to go back to the first step.",
                chatbot_product: 'You can navigate through our products on the following link.',
                chatbot_general: 'Sorry, We are not able to process what you said. However, you can make any general/business enquiries through our Contact Us form. Follow the link below.',
                home_news_viewmore: 'View More',
                home_product_content: 'Discover Innovative Products of TOVNET.',
                home_solution: "TOVNET Solution",
                home_solution_content: "",
                solution_heading1: "River Pollution Detection",
                solution_heading2: 'Flood Detection in Rivers',
                solution_heading3: 'Unauthorized Trash Dumping Detection',
                solution_heading4: 'Industrial Safety Management Solution',
                solution_content1: "A technology that automatically generates suitable algorithms for the dataset for assessing water quality and disaster response in uncertain situations based on the detection of sewage wastewater, suspended solids (such as garbage), and algal blooms (color changes).",
                solution_content2: "A high-performance object recognition technology that detects water area boundaries in various environments through segmentation and issues warnings for immediate response in the event of a sudden river flood.",
                solution_content3: '"A high-performance recognition technology that captures scenes of aggression through human keypoint detection. It is a system suitable for local government video solutions.',
                solution_content4: 'A solution that analyzes CCTV footage from construction sites in real-time to detect hazardous environments and integrates a system for industrial safety. This solution minimizes corporate losses due to the Serious Accidents Punishment Act.',
                Solution: 'AI Solution',
                history_more: 'Expand',
                history_less: 'Collapse',
                required: 'Required fields',
                chatbot_floating: 'Ask me anything?',
                logo_item3: "Security Camera Operation Sign",
                email_success: 'Your request has sent successfully.',
                email_unsuccess: 'Your request has not sent successfully. Please try again later.',
                file: 'File',
                InstallationCase1: "Solar Cam",
                InstallationCase2: 'Thermal Imaging Cam',
                InstallationCase3: 'Tov Bell',
                InstallationCase4: 'Wireless CCTV'


                
              
                
            }
        },
        kr: {
            translation: {
                company: "회사소개",
                aboutus: "토브넷 소개",
                aboutus_culture:'회사 이념',    
                aboutus_cert: '인증 현황',
                nzOffice:'뉴질랜드 주소',
                awards:'상장',
                certification:'인증',
                patents:'특허',
                logo: "토브넷 CI",
                headoffice: "회사 위치",
                storelocation: "사무실 위치",
                products: '제품 소개',
                media: "미디어",
                news: "토브넷 소식",
                news_content:"토브넷의 새로운 소식을 함께 살펴보세요.",
                event: "이벤트",
                installationcase: "설치사례",
                catalogue: "카탈로그",
                support: "고객문의",
                faq: "FAQ",
                notice: '공지사항',
                userguide: '제품사용설명서',
                contactus: '문의상담',
                marketingmaterials: '영업자료',
                address: "서울특별시 은평구 통일로 680 대일빌딩 8층",
                phone: "02-3216-3983",
                "General Product": '제품',
                Accessory:'액세서리',
                "TOVNET News": '토브넷 소식',
                "Other Products":'기타',
                Youtube:"Youtube",
                home_banner1: "IOT 소나무를 심는 친환경 공동체",
                home_banner2: "삶이 더 편리하고 안전할 수 있도록",
                home_banner3: "일상에 토브넷을 더하다",
                news_banner:'(주)토브넷은 무선 네트워크 기반의 유비쿼터스 헬스케어 서비스와 더불어 독자개발 무선 네트워크 기술을 통해 무선 LED 전구 CCTV와 디지털 사이니지 솔루션까지 기술 역량을 확보하여 기업 및 개인에 이르기까지 다양한 고객을 대상으로 사업영역을 구축한 확보한 "차세대 스마트 네트워크 환경"의 훌륭한 파트너입니다.',
                viewmore:'더보기',
                home_viewmore: '제품 보기',
                contactusInfo: "제품 구매 및 솔루션 개발 문의",
                ceo_greeting: "인사말",
                ceo_name:'김찬영',
                ceo_position: '(주)토브넷 대표이사',
                ceo_message1: `TOVNET은 친환경 IOT 소나무를 심는 친환경 공동체입니다.`,
                ceo_message2: `㈜토브넷은 태양광 충전 및 절전기술, 영상압축기술, 사물인터넷(IoT)기술, 영상인공지능(AI)기술을 기반으로
                 다수의 혁신적인 제품을 개발하여 국내외 특허, 국가신기술(NET)인증, 성능인증, Good Software 1등급,
                 기술개발제품, 혁신시제품 인증 등을 받은 기업입니다.`,
                 ceo_message3: `(주)토브넷은 탄소중립 정책에 기여하는 4차 산업 AI 선도기업으로써,  
              토브넷 태양광 카메라 1대는 소나무 한 그루를 심는 것과 같은 탄소중립 효과를 제공합니다.`,
              ceo_message4: `㈜토브넷은 소비자가 필요로 하는 기술과 제품을 먼저 생각하여
                지속적으로 혁신적인 서비스와 제품을 개발하도록 하겠습니다.` ,
                history:'연혁',
                history_item1: "",
                logo_content: `TOV 는 기쁨이라는 최고표현입니다.<1/>
                소비자가 필요로 하는 기술과 제품을 먼저 생각하여 지속적으로 혁신적인 서비스와 제품을 개발하겠습니다.`,
                headOffice: '본사 주소',
                head_address: '서울특별시 은평구 통일로 680 대일빌딩 8층',
                home_products: '토브넷 제품',
                home_manual: '사용설명서',
                event_banner1:'홍보/이벤트',
                event_banner2: "(주)토브넷은 무선 네트워크 기반의 유비쿼터스 헬스케어 서비스와 더불어 독자개발 무선 네트워크 기술을 통해 무선 LED 전구 CCTV와 디지털 사이니지 솔루션까지 기술 역량을 확보하여 기업 및 개인에 이르기까지 다양한 고객을 대상으로 사업영역을 구축한 확보한 '차세대 스마트 네트워크 환경'의 훌륭한 파트너입니다.",
                catalogue_banner: "토브넷의 카탈로그를 편리하고 간단하게 다운 받으세요.",
                faq_banner:'한눈에 질답을 확인해보세요.',
                none: '',
                youtube:'유투브 동영상',
                strength1: '영상 압축',
                strength2: '전송 기술',
                strength3: '전기 에너지 절감 기술',
                strength4: '에너지 최적화',
                strength5: '친환경 탄소중립 제품 개발',
                strength: '회사 강점',
                culture1: '혁신',
                culture2: '㈜토브넷은 친환경을 먼저 생각하는 기업으로써 탄소중립 정책을 준수하는 친환경 제품과 인공지능 영상 분석 솔루션을 공급하는 회사입니다. ㈜토브넷은 고객이 필요로 하는 문제를 효율적으로 해결하는 혁신적인 제품개발과 서비스 개선을 통해 더 나은 고객 만족과 가치를 실현 하기위해 노력하고 있습니다.',
                culture3:'신뢰',
                culture4:'토브넷은 2012년에 설립하여 현재까지 지속적으로 혁신적인 제품을 개발하여 국내 시장을 넘어 글로벌시장에서도 신뢰성을 인정받고 있습니다. 지속적인 혁신적 제품과 서비스 제공을 통해서 고객이 신뢰하는 토탈 솔루션을 제공하겠습니다.',
                culture5:'고객 만족',
                culture6:'㈜토브넷 제품을 사용하는 기업과 고객의 만족은 ㈜토브넷의 핵심 가치입니다. 고객의 기대치를 넘어서는 차별화된 품질의 제품과 서비스를 제공하고자 혁신적인 제품 개발과 노력에 최선을 다하겠습니다.',
                culture7:'지속 가능 성장',
                culture8: '㈜토브넷은 친환경 탄소중립 정책을 준수하는 기업으로써 미래의 후손들에게도 기억될 수 있는 친환경 제품과 기업의 가치를 지향하는 선도 기업이 되겠습니다.',
                product_tab1: '제품 소개',
                product_tab2: '매뉴얼',
                product_tab3: '문의',
                contactus1: '일반 문의',
                contactus2: '구매 문의',
                category: '문의 종류',
                email: '이메일 주소',
                title:'제목',
                message:'내용',
                submit: '문의 보내기',
                headofficebanner: '토브넷 본사',
                korea: '한국 본사',
                newzealand: '뉴질랜드',
                america:'미국',
                product_viewmore: '제품 더보기',
                download:"다운로드",
                chatbot_intro1: "안녕하세요, 토브넷 입니다.",
                chatbot_intro2: "무엇이든 물어보세요.",
                chatbot_intro3: "처음으로 돌아가시려면 '돌아가기'를 입력하세요.",
                chatbot_product: "토브넷 제품은 아래 링크에서 모두 확인하실 수 있습니다.",
                chatbot_general: '죄송합니다. 무슨 말씀인지 모르겠어요. 토브넷에 문의하기를 원하신 다면 아래의 링크를 참조해 주세요.',
                home_news_viewmore: '더 알아보기',
                home_product_content:'토브넷의 획기적인 제품들을 만나보세요.',
                home_solution: "TOVNET 솔루션",
                home_solution_content: "",
                solution_heading1: "하천 오염 감지",
                solution_heading2: '하천 범람 감지',
                solution_heading3: '쓰레기 무단 투기 감지',
                solution_heading4: '산업 현장 안전 관리',
                solution_content1: "하수도 오폐수, 부유물(쓰레기 등), 녹조(색상 변화) 감지를 통한 수질 상태 점검 및 재난 대응 시스템으로 불확실한 상황에서의 이해요소 판단에 대한 알고리즘으로 데이터셋에 적합한 알고리즘을 자동 생성하는 기술입니다.",
                solution_content2: "영역 감지(Segmentation) 고성능 객체 인식 기술로 다양한 환경에서의 물영역 경계선을 감지하여 급격한 하천 범람시 경고 발생을 주어 대처가 즉각 대처가 가능합니다.",
                solution_content3: '사람 Key point 감지를 통해 투기하는 장면이 포착하는 고성능 인식 기술 입니다. 지자체 영상 솔루션에 적합한 시스템 입니다.',
                solution_content4: '공사 현장의 CCTV 영상을 실시간으로 분석하여 위험한 환경을 탐지하고, 산업 안전을 위한 시스템을 통합하여 확인 할수있습니다. 중대재해 처벌법으로 인한 기업의 손실을 최소화시키는 솔루션 입니다.',
                Solution: 'AI 솔루션',
                history_more: '펼치기',
                history_less: '접기',
                required: '필수 입력 사항',
                chatbot_floating: '무엇이든 물어보세요?',
                logo_item3: "설치안내판",
                email_success: '문의를 성공적으로 접수하였습니다.',
                email_unsuccess: '문의 전송을 실패하였습니다. 잠시 뒤 다시 시도해주세요.',
                file: '첨부파일',
                InstallationCase1: "태양광 카메라",
                InstallationCase2: '열화상 카메라',
                InstallationCase3: '토브벨',
                InstallationCase4: '무선 CCTV',
           

                
                
            }
        }
    },
  });

export default i18n;