import NavBar from "./Navbar/Navbar";
import '../App.css';
import {AppBar, Box, Toolbar, IconButton, Container,Stack,  Divider,  useScrollTrigger, Collapse, List} from '@mui/material';


import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Image } from "mui-image";
import tovnetLogo from "../assets/tovnet_logo_1.png"
import tovnetLogo2 from "../assets/updated_logo2.png"
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ApartmentIcon from '@mui/icons-material/Apartment';
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LanguageSwitcher from "./i18/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom'


function Social() {

    return (
        <div id="social" style={{postion:'absolute'}}>
        <AppBar position="absolute" style={{zIndex:'2',backgroundColor: 'transparent', boxShadow:"none", height: '4vh', display:'flex', alignItems:'center', justifyContent:'center', padding:"2% 0", borderBottom: 1}}>
      
            
            <Container maxWidth='100vw' >
            <Stack direction="row" spacing={2} sx={{padding:'0 5%'}}>

               
            
                
               
                
                
                <div className="socialMedia" style={{marginLeft:"auto", display:'flex', flexDirection:"row"}}>
                <LanguageSwitcher mobile={false}/>
                    <Box sx={{display:'flex', flexDirection: 'row', justifyContent:'space-around', alignContent: 'center'}}>
                        <IconButton href="https://www.facebook.com/100072024465833/videos/" target="_blank" sx={{color:'white'}}><FacebookOutlinedIcon sx={{fontSize: { sm: '1rem', xs: '1rem', md: '1.5rem', lg:'1.5rem' }}} /></IconButton>
                        <IconButton href="https://www.instagram.com/tovnet_official/" target="_blank" sx={{color:'white'}}><InstagramIcon sx={{fontSize: { sm: '1rem', xs: '1rem', md: '1.5rem', lg:'1.5rem' }}}/></IconButton>
                        <IconButton href="https://www.youtube.com/@user-np6zh9ol8i" target="_blank" sx={{color:'white'}}><YouTubeIcon sx={{fontSize: { sm: '1rem', xs: '1rem', md: '1.5rem', lg:'1.5rem' }}}/></IconButton>
                    </Box>
                    
                </div>
            </Stack>
            
                

            </Container>
        </AppBar>

    </div>
    
    );
    
}



function MobileHeader() {
    const { i18n, t } = useTranslation();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 300,
      });
      const [open, setState] = React.useState(false);
      const [openCompany, setOpenCompany] = React.useState(false);
      const [openMedia, setOpenMedia] = React.useState(false);
      const [openSupport, setOpenSupport] = React.useState(false)
      
      const toggleCompany = () => {
        setOpenCompany((prev) => !prev)
      }
      const toggleMedia = () => {
        setOpenMedia((prev) => !prev)
      }
      const toggleSupport = () => {
        setOpenSupport((prev) => !prev)
      }


  //function that is being called every time the drawer should open or close, the keys tab and shift are excluded so the user can focus between the elements with the keys
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
    setOpenCompany(false)
    setOpenMedia(false)
    setOpenSupport(false)
  };

  const toggleDrawerCompany = (open) => {
    setState(open);
    toggleCompany();



  }

  const toggleDrawerMedia = (open) => {
    setState(open);

    toggleMedia();
  }

  const toggleDrawerSupport = (open) => {
    setState(open);
   
    toggleSupport();
  }



  




    return (
        <Box id="mobile-navbar" style={{position:'fixed', width: '100vw', top:'0', zIndex:'1'}}>
             <AppBar className="navbar" sx={{ backgroundColor: trigger? "red":"transparent", boxShadow: 'none', padding:trigger?"5px 0":"3% 0 0 0", transition:'padding 0.3s', animationTimingFunction: 'ease-in'}}>
      <Container maxWidth="100vw" >
        <Toolbar disableGutters sx={{padding:'0 5%'}}>

          <Link to="/" style={{textDecoration: 'none'}}><Image   width="30%" sx={{marginRight:"auto", flexGrow:'1', backgroundCover:'contain'}} src={trigger?tovnetLogo2:tovnetLogo}/></Link>
          

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            sx={{
              ml:'auto',
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* The outside of the drawer */}
          <Drawer
            //from which side the drawer slides in
            anchor="right"
            //if open is true --> drawer is shown
            open={open}
            //function that is called when the drawer should close
            onClose={toggleDrawer(false)}
            //function that is called when the drawer should open
            onTransitionEnd={()=>toggleDrawer(true)}
            sx={{overflow:'scroll'}}
          >
            {/* The inside of the drawer */}
            <Box
              sx={{
                p: 2,
                height: 1,
                backgroundColor: 'white'
              }}
            >
              {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
              <IconButton sx={{ mb: 2 }} onClick={toggleDrawer(false)}>
                <CloseIcon  />
              </IconButton>
              <LanguageSwitcher mobile={true}/>

              <Divider sx={{ mb: 2 }} />

              <Box sx={{ mb: 2 }}>
                {/* Company */}
                <ListItemButton onClick={toggleCompany}>
                <ListItemIcon>
                    <ApartmentIcon sx={{color:'red'}}/>
                </ListItemIcon>
                <ListItemText primary={t("company")} sx={{'.MuiListItemText-primary': {fontWeight:'bold'}}}/>
                {openCompany ? <ExpandLess /> : <ExpandMore />}

                </ListItemButton>
                
                <Collapse in={openCompany} unmountOnExit>
                    <List>
                <ListItemButton component={Link} to="/company/about-us" onClick={()=>toggleDrawerCompany(false)}>
                  
                  <ListItemText primary={t('aboutus')} />
                </ListItemButton>
                <ListItemButton component={Link} to="/company/tovnet-ci" onClick={()=>toggleDrawerCompany(false)}>
                  
                  <ListItemText primary={t('logo')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/company/head-office" onClick={()=>toggleDrawerCompany(false)}>
                  
                  <ListItemText primary={t('headoffice')}/>
                </ListItemButton> 
                <ListItemButton component={Link} to="/company/store-location" onClick={()=>toggleDrawerCompany(false)}>
                  
                  <ListItemText primary={t('storelocation')}/>
                </ListItemButton> 
                </List>
                </Collapse>

                {/* Product */}

                <ListItemButton component={Link} to="/products" onClick={()=>toggleDrawerCompany(false)}>
                <ListItemIcon>
                    <ShoppingCartIcon sx={{color:'red'}}/>
                </ListItemIcon>
                <ListItemText primary={t('products')} sx={{'.MuiListItemText-primary': {fontWeight:'bold'}}}/>

                </ListItemButton>
                
                {/* Media */}
                <ListItemButton onClick={toggleMedia}>
                <ListItemIcon>
                    <SubscriptionsIcon sx={{color:'red'}}/>
                </ListItemIcon>
                <ListItemText primary={t('media')} sx={{'.MuiListItemText-primary': {fontWeight:'bold'}}}/>
                {openMedia ? <ExpandLess /> : <ExpandMore />}

                </ListItemButton>
                
                <Collapse in={openMedia} unmountOnExit>
                <List>
                <ListItemButton component={Link} to="/media/news" onClick={()=>toggleDrawerMedia(false)} sx={{display:i18n.language==='kr'?"block":"none"}}>
                  
                  <ListItemText primary={t('news')} />
                </ListItemButton>
     
                <ListItemButton component={Link} to="/media/installation-case" onClick={()=>toggleDrawerMedia(false)}>
                  
                  <ListItemText primary={t('installationcase')} />
                </ListItemButton> 
                <ListItemButton component={Link} to="/media/catalogue" onClick={()=>toggleDrawerMedia(false)}>
                  
                  <ListItemText primary={t('catalogue')} />
                </ListItemButton> 
                </List>
                   
                </Collapse>

                {/* Support */}
                <ListItemButton onClick={toggleSupport}>
                <ListItemIcon>
                    <ContactSupportIcon sx={{color:'red'}}/>
                </ListItemIcon>
                <ListItemText primary={t('support')} sx={{'.MuiListItemText-primary': {fontWeight:'bold'}}}/>
                {openSupport ? <ExpandLess /> : <ExpandMore />}

                </ListItemButton>
                
                <Collapse in={openSupport} unmountOnExit>
                <List>
                <ListItemButton component={Link} to="/support/faq" onClick={()=>toggleDrawerSupport(false)}>
                  
                  <ListItemText primary="FAQ" />
                </ListItemButton>
                <ListItemButton component={Link} to="/support/notice" onClick={()=>toggleDrawerSupport(false)}>
                  
                  <ListItemText primary={t('notice')} />
                </ListItemButton>
                <ListItemButton component={Link} to="/support/user-guide" onClick={()=>toggleDrawerSupport(false)}>
                  
                  <ListItemText primary={t('userguide')} />
                </ListItemButton> 
                <ListItemButton component={Link} to="/support/contact-us" onClick={()=>toggleDrawerSupport(false)}>
                  
                  <ListItemText primary={t('contactus')} />
                </ListItemButton> 
      
                </List>
                </Collapse>

    
                



              
              </Box>
              </Box>
          </Drawer>

                </Toolbar>
                </Container>
                </AppBar>

        </Box>
    )
}

function Header() {
    return (
        <>
        <Box id="header" sx={{display: {xs:'none', sm:'none', md:'block'}}}>
            <Social/>
            <NavBar/>
        </Box>
        <Box id="header-mobile" sx={{display: {xs:'block', sm:'block', md:'none'}}}>
            <MobileHeader/>
        </Box>
        </>
    )
} 


export default Header;