// import {BrowserRouter, Routes, Route} from "react-router-dom";
// import {Logo, AboutUs, HeadOffice, StoreLocation } from './components/pages/Company';
// import Products from './components/pages//Products/Products.js';
// import {Catalogue, Event, EventPage, InstallationCase, News, NewsPage} from './components/pages/Media';
// import Home from './components/pages/Home.js';
// import {FnQ, Notice, Userguide, ContactUs, MarketingMaterials } from './components/pages/Support';
import Header from './components/Header.js';
import Footer from './components/Footer.js';

import * as React from 'react';

import Box from "@mui/material/Box"

import ChatbotWidget from './components/Chatbot/Widget.js';
import "react-multi-carousel/lib/styles.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { register } from 'swiper/element/bundle';



register();








function App() {

  


  return (
    
      <div className='App'>
        <Header/>
        <Box className="chatbot-wrapper" sx={{position:'fixed', right:'0', bottom:'0', textAlign:'right', marginRight:'3vw', marginBottom:'3vh'}}>
          <ChatbotWidget/>
        </Box>
        <Footer/>
      </div>
 
   
  );
}

export default App;
