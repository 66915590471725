import { IconButton, ThemeProvider, Typography} from "@mui/material";
import { Box } from "@mui/material";
import theme from "../../Theme";
import Intro from "../general_section/Intro";
import { Image } from "mui-image";
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import logo from "../../../assets/png_logo.png";
import logo_ai from "../../../assets/ai_logo.ai";
import operation_sign from "../../../assets/CI3.png";
import operation_sign_eng from "../../../assets/cctv_eng.png";
import GeneralBanner from "../general_section/GeneralBanner";
import React from "react";
import LoadingAnimation from "../general_section/LoadingAnimation";
import logoColor1 from "../../../assets/logo_page_1.png";
import logoColor2 from "../../../assets/logo_page_2.png"
import i18n from "../../i18/i18n";
import { useTranslation } from "react-i18next";



function Logo() {
    const [playAnimation, setPlayAnimation] = React.useState(true);
    const {t} = useTranslation();

    const onPageLoad = () => {
        setTimeout(()=> {
          setPlayAnimation(false)}, 1000)
        
      }
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }

      document.body.style.overflow = 'scroll'

   

    return (

        <div id="logo">
            <ThemeProvider theme={theme}>
            {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

            <GeneralBanner label={t('logo')} preset={"logo"}/>
            <Box sx={{marginTop: "5vh", boxShadow: '0 15px 18px #eeeeee'}}>
            <Box sx={{display: 'flex', width: '100vw', padding: '0 10% 10vh 10%', flexDirection: {xs:'column', md:'row'}, gap:'5%', justifyContent:'space-around'}}>
                <Box sx={{display:'flex', marginBottom:{xs:'5vh', md:'0'},backgroundColor: 'backgroundGrey', flexDirection:'column', alignItems: 'center', width: '100%', aspectRatio: '16/9', justifyContent: 'space-around', padding:{xs:'10% 0', md:'2% 0'}}}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>PNG</Typography>
                    <Image src={logo} sx={{width:{xs:'70% !important', lg:'20vw'}}}/>
                    <IconButton href={logo} download="png_logo" target='_blank' style={{ backgroundColor: "white"}} sx={{ aspectRatio:'1', '.MuiSvgIcon-root':{fontSize:{xs:'2rem', lg:'2.5rem'}}, borderRadius: '5px', stroke: "white", strokeWidth: 1, boxShadow:10, '.MuiIconButton-root&:hover':{backgroundColor:'#AE2424 !important', color:'white !important', stroke: '#AE2424 !important', strokeWidth: 1}}}><ArrowCircleDownOutlinedIcon/></IconButton>

                </Box>
                <Box sx={{display:'flex', marginBottom:{xs:'5vh', md:'0'},backgroundColor: 'backgroundGrey', flexDirection:'column', alignItems: 'center', width: '100%', aspectRatio: '16/9', justifyContent: 'space-around', padding:{xs:'10% 0', md:'2% 0'}}}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>AI</Typography>
                    <Image src={logo} sx={{width:{xs:'70% !important', lg:'20vw'}}}/>
                    <IconButton href={logo} download="png_logo" target='_blank' style={{ backgroundColor: "white"}} sx={{ aspectRatio:'1', '.MuiSvgIcon-root':{fontSize:{xs:'2rem', lg:'2.5rem'}}, borderRadius: '5px', stroke: "white", strokeWidth: 1, boxShadow:10, '.MuiIconButton-root&:hover':{backgroundColor:'#AE2424 !important', color:'white !important', stroke: '#AE2424 !important', strokeWidth: 1}}}><ArrowCircleDownOutlinedIcon/></IconButton>

                </Box>
                <Box sx={{display:'flex', backgroundColor: 'backgroundGrey', flexDirection:'column', alignItems: 'center', width: '100%', '.MuiSvgIcon-root':{fontSize:{xs:'2rem', lg:'2.5rem'}},aspectRatio: '16/9', justifyContent: 'space-around', padding:{xs:'10% 0', md:'2% 0'}}}>
                    <Typography variant="h5" sx={{fontWeight:'bold', textAlign:'center'}}>{t('logo_item3')}</Typography>
                    <Image src={i18n.language==="kr"?operation_sign:operation_sign_eng} sx={{padding:'0.5em', width:{xs:'70% !important', lg:'20vw'}}}/>
                    <IconButton href={i18n.language==="kr"?operation_sign:operation_sign_eng} download="operation_sign" target='_blank' style={{ backgroundColor: "white"}} sx={{ aspectRatio:'1',  borderRadius: '5px', stroke: "white", strokeWidth: 1, boxShadow:10, '.MuiIconButton-root&:hover':{backgroundColor:'#AE2424 !important', color:'white !important', stroke: '#AE2424 !important', strokeWidth: 1}}}><ArrowCircleDownOutlinedIcon/></IconButton>

                </Box>
            </Box>
            <Box sx={{objectFit:'contain',  textAlign:'center', padding:'0 10% 10vh 10%'}}>
                <img src={logoColor1} alt="logocolor1" style={{padding:'0 5%', maxWidth:'100%'}}/>
                <img src={logoColor2} alt="logocolor2" style={{padding:'0 5%', maxWidth:'100%'}}/>
                <Box sx={{marginTop:'1em', display: i18n.language==="kr"?"block":"none"}}>
                출원번호 : 40-2023-0116019 <br/>
                출원인 : 주식회사 토브넷
                </Box>


            </Box>

            </Box>
            </ThemeProvider>
           
        </div>


    )
}

export {Logo};