import { CssBaseline, ThemeProvider, Box, Typography, IconButton, Pagination, createTheme } from "@mui/material";
import Grid from "@mui/material/Grid"
import theme from "../../Theme";
import GeneralBanner from "../general_section/GeneralBanner";
import Intro from "../general_section/Intro";
import React from "react";
import axios from "axios";
import { calculatePagesCount } from "../../Functions/maxPage";
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import LoadingAnimation from "../general_section/LoadingAnimation";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { API_URL } from "../../../constants";

const defaultTheme = createTheme(theme, {
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2.5rem'
                }
            }
        }
    }
});



function MarketingMaterials() {

    const [marketingMaterials, setmarketingMaterials] = React.useState([]);
    const [maxPageNum, setMaxPageNum] = React.useState(1);
    const {i18n, t} = useTranslation()
    

    const handlePageChange = (event, newValue) => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/marketing_material/actions/list?page=${newValue}&perPage=4&filters.language=${i18n.language}`).then((res)=> {
            setmarketingMaterials(res.data.records);
        }).catch(error => {console.log(`Error: ${error}`)});
    }

    const [playAnimation, setPlayAnimation] = React.useState(true)
    document.body.style.overflow = 'scroll'


    React.useEffect( () => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/marketing_material/actions/list?page=1&perPage=4&filters.language=${i18n.language}`).then((res)=>{
          setmarketingMaterials(res.data.records);
          setMaxPageNum(calculatePagesCount(4, res.data.meta.total));
          
        }).catch(error => {console.log(`Error: ${error}`)});

        const onPageLoad = () => {
            
            setTimeout(()=> {
              setPlayAnimation(false)}, 1000)
            
          }
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }

          i18next.on('languageChanged', function(lng) {
            axios.get(`https://${API_URL()}/adminAPI/api/resources/marketing_material/actions/list?page=1&perPage=4&filters.language=${i18n.language}`).then((res)=>{
                setmarketingMaterials(res.data.records);
                setMaxPageNum(calculatePagesCount(4, res.data.meta.total));
                
              }).catch(error => {console.log(`Error: ${error}`)});
        })

        
        
      });

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

            <GeneralBanner label={t('marketingmaterials')} preset={'marketingmaterials'}/>
            <Box sx={{mt:'10vh'}}>
            <Intro title={t('marketingmaterials')} content={"none"}/>
            </Box>
            <Box sx={{marginTop: '10vh',boxShadow: '0 15px 18px #eeeeee'}}>
            <Grid container spacing={5} padding={{xs:"0 10%", md:"0 20%"}} pb="10vh">
        {marketingMaterials.map((ele, index) => (
           

<Grid item key={ele.params.id} xs={12} sm={6} md={6} >

<Box sx={{ padding:"0 0 10% 0", width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
    <Box sx={{boxShadow:3}}>
    <img src={ele.params.thumbnailFilePath} style={{width:'100%', padding:index===0?"10%":"5%"}} alt="thumbnail"></img>

    </Box>

<Typography variant="h4" sx={{color:'red', fontWeight:'bold', mt:'5%', mb:'5%'}}>
    {ele.params.title}
    </Typography>
<IconButton href={ele.params.filePath} style={{ backgroundColor: "white"}} sx={{ aspectRatio:'1', borderRadius: '5px', stroke: "white", strokeWidth: 1, height:'fit-content', boxShadow:10,  '.MuiIconButton-root&:hover':{backgroundColor:'#AE2424 !important', color:'white !important', stroke: '#AE2424 !important', strokeWidth: 1}}}><ArrowCircleDownOutlinedIcon/></IconButton>
</Box>


</Grid>
        ))}
        </Grid>
        <Box sx={{display:'flex', justifyContent: 'center', paddingBottom: '10vh'}}>

        <Pagination count={maxPageNum} onChange={handlePageChange} size='large'/>

        </Box>
    
            </Box>
                
           




        </ThemeProvider>
    )
}

export {MarketingMaterials};