import { CssBaseline, ThemeProvider, Typography, Box, Divider} from "@mui/material";
import React from "react";
import theme from "../../Theme";
import GeneralBanner from "../general_section/GeneralBanner";
import { useSearchParams } from 'react-router-dom';
import axios from "axios";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../constants";


function NewsPage() {
    const [searchParams] = useSearchParams();
    const [newsPage, setNewsPage] = React.useState([]);
    const {i18n, t} = useTranslation();
    const setYoutube = (language) => {
   
            if (newsPage.video1 && newsPage.video2 && newsPage.video3) {
                return (
    <>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:newsPage.video1}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:newsPage.video2}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:newsPage.video3}}/>
                </>
                )
            } else if (newsPage.video1 && newsPage.video2) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:newsPage.video1}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:newsPage.video2}}/>
                </>
                )
            } else if (newsPage.video1 && newsPage.video3) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:newsPage.video1}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:newsPage.video3}}/>
                </>
                )
            } else if (newsPage.video2 && newsPage.video3) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:newsPage.video2}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:newsPage.video3}}/>
                </>
                )
            } else if (newsPage.video1) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:newsPage.video1}}/>

                )
            } else if (newsPage.video2) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:newsPage.video2}}/>

                )
            } else if (newsPage.video3) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:newsPage.video3}}/>

                )
            }
        } 
   
        
        
        


    React.useEffect( () => {
        
        axios.get(`https://${API_URL()}/adminAPI/api/resources/news/records/${searchParams.get('newsid')}/show`).then((res)=>{
          setNewsPage(res.data.record.params)
            
        }).catch(error => {console.log(`Error: ${error}`)});


        
        
      });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <GeneralBanner label={newsPage.title} preset={'news'}/>
            <Box sx={{padding:'5% 10%'}}>
            <Link to="/media/news" id="backNewsButton" style={{display:'flex', textDecoration:'none', color:'black', "&:hover": {color:'red', textDecoration:'underline'}}} ><KeyboardArrowLeft/><Typography variant="h6">Back</Typography></Link>

                <Box sx={{borderBottom:'0.5px solid grey', paddingLeft:'3%', mt:'2vh'}}>
                    <Typography variant="h3" sx={{color: 'red', fontWeight:'bold'}}>{newsPage.title}</Typography>
                </Box>

                <Box sx={{width:'100%', padding:'0 15%', mt:'5%', display:'flex', flexDirection:{xs:'column', lg:'row'}, justifyContent:'space-between'}}>

               
               {setYoutube(i18n.language)}


                </Box>
                    <Box className="page-content" sx={{ marginTop:'5vh'}} dangerouslySetInnerHTML={{__html:newsPage.content}}/>
                    
                    {newsPage.file?
                    
                    <Box sx={{display: 'grid'}}>
                    <Typography sx={{border:'0.5px solid lightgrey', gridColumn: '1 / span 1', textAlign:'center', padding: '0.5em 0'}}>{t("file")}</Typography>
                    <Typography sx={{'&:hover': {color:'red', textDecoration:'underline'}, paddingTop: '0.5em', paddingBottom:'0.5em', paddingLeft:'2em', gridColumn: '2 / span 6', border:'0.5px solid lightgrey', borderLeft: '0', width:'100%'}}><Link to={newsPage.downloadFilePath}>{newsPage.downloadfilename}</Link></Typography>
                    </Box>:""
                        
                    }

                

            </Box>


        </ThemeProvider>
    )
}

export {NewsPage}