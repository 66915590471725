import { Tab, Box, CardContent } from "@mui/material";
import axios from 'axios';
import React from "react";
import GeneralBanner from "../general_section/GeneralBanner";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from '@mui/lab/TabList';
import Pagination from '@mui/material/Pagination';
import { calculatePagesCount } from "../../Functions/maxPage";

import LoadingAnimation from "../general_section/LoadingAnimation";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import i18next from "i18next";
import { API_URL } from "../../../constants";



function News() {
    const {i18n, t} = useTranslation()
    const [currentTab, setCurrentTab] = React.useState('1');
    const [tabs, setTabs] = React.useState([]);
    const [page, setPage] = React.useState(null);
    const [maxPageNum, setMaxPageNum] = React.useState(1);



    const [news, setNews] = React.useState([]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        axios.get(`https://${API_URL()}/adminAPI/api/resources/news/actions/list?filters.news_type_id=${newValue}&page=${page}&perPage=9&sortBy=post_date&direction=desc&filters.language=${i18n.language}`).then((response) => {
            setNews(response.data.records);
            setMaxPageNum(calculatePagesCount(9, response.data.meta.total));
        }).catch(error => {console.log(`Error: ${error}`)});

    }

    const handlePageChange = (event, newValue) => {
        setPage(newValue);
        axios.get(`https://${API_URL()}/adminAPI/api/resources/news/actions/list?filters.news_type_id=${currentTab}&page=${newValue}&perPage=9&sortBy=post_date&direction=desc&filters.language=${i18n.language}`).then((response) => {
            setNews(response.data.records)
        }).catch(error => {console.log(`Error: ${error}`)});
    }
    const [playAnimation, setPlayAnimation] = React.useState(true)
    document.body.style.overflow = 'scroll'
    

    

    React.useEffect( () => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/news/actions/list?filters.news_type_id=1&page=1&perPage=9&sortBy=post_date&direction=desc&filters.language=${i18n.language}`).then((response) => {
            setCurrentTab("1")
            setNews(response.data.records)
            setMaxPageNum(calculatePagesCount(9,response.data.meta.total))
        }).catch(error => {console.log(`Error: ${error}`)});

        axios.get(`https://${API_URL()}/adminAPI/api/resources/news_type/actions/list?sortBy=news_type_id`).then((response) => {
            setTabs(response.data.records)
        }).catch(error => {console.log(`Error: ${error}`)});
       

        i18next.on('languageChanged', function(lng) {
            axios.get(`https://${API_URL()}/adminAPI/api/resources/news/actions/list?filters.news_type_id=1&page=1&perPage=9&sortBy=post_date&direction=desc&filters.language=${i18n.language}`).then((response) => {
            setCurrentTab("1")
            setNews(response.data.records)
            setMaxPageNum(calculatePagesCount(9,response.data.meta.total))
        }).catch(error => {console.log(`Error: ${error}`)});
           
        })

        



       const onPageLoad = () => {
         setTimeout(()=> {
          setPlayAnimation(false)}, 1000)
        
      }
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
        
    }, []);







    
    return (
        <div>
        {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}
        <GeneralBanner label={t('news')} preset={"news"}/>
        <Box sx={{boxShadow: '0 15px 18px #eeeeee'}}>
        <Box sx={{marginTop: '5vh',  padding:"0 10%"}}>

        <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent:'center', display: 'flex', gap: "7%"}}>
                <TabList onChange={handleTabChange} sx={{'.MuiTabs-indicator': {background: "#AE2424"}, '.Mui-selected': {color: "#000000 !important"}, '.MuiTab-root': {fontWeight:'bold', fontSize:{xs:'small', md:'medium', lg:'x-large'}}}}>
    
      
                
                {tabs.map((tab) => (
                    
                    <Tab key={`${tab.params.type_name}-tab`} label={t(tab.params.type_name)} value={tab.params.news_type_id} sx={{width: '30vw'}}/>
                  
                ))}

                </TabList>
                </Box>
                {tabs.map((tab) => (
                <TabPanel key={`tabpanel-${tab.params.news_type_id}`} value={tab.params.news_type_id}>
                    <Grid container  spacing={4}>
                    {news.map((singlenews, index) => (
                        
                        <Grid item key={singlenews.params.news_id} xs={12} sm={6} md={6} lg={4}>
                        <Link style={{textDecoration:'none'}} to={`/media/news/news-page?newsid=${singlenews.params.news_id}`}>
                        <Box position='relative' sx={{boxShadow:1, padding:'3%', height:'100%', '&:hover': {backgroundColor:'#eee'}}}>

                        <Card sx={{ display: 'flex', flexDirection: 'column', boxShadow: 'none', height:'100%', backgroundColor:'transparent'}} >
                        
                            <div style={{overflow:'hidden'}}>
                            <CardMedia component="div" sx={{ aspectRatio:'1.35', zIndex:'0', objectFit:'contain', "&:hover": {transform:'scale(1.2)'}, transition:'transform .2s'}} image={singlenews.params?.filePath}/>

                            </div>
                           
                                <CardContent sx={{flexGrow:1, display:'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                                    <Typography variant="h6" sx={{fontWeight:'bold'}}>
                                        {singlenews.params.title}
                                        </Typography>
                                    <Typography sx={{textDecoration:'none', color:'black', '&:hover': {color:'red', textDecoration:'underline'}}}>Read More</Typography>
                                </CardContent>

                            

                        </Card>
                        </Box>
                        </Link>
                    </Grid>
                    
                        
                    ))}
                    
                    




                </Grid>
                    



                </TabPanel>
               

            ))}
            

      
        
        

        </TabContext>



        </Box>
        
        <Box sx={{display:'flex', justifyContent: 'center', paddingBottom: '10vh', paddingTop:'10vh'}}>

        <Pagination count={maxPageNum|0} onChange={handlePageChange} size='large'/>

        </Box>
        
        </Box>
      </div>
    )
}

export {News};