import { Button, Typography } from '@mui/material';
import { createChatBotMessage } from 'react-chatbot-kit';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import i18n from '../i18/i18n';
import './chatbot.css';




const CustomProductLinkMessage = () => {
  const {i18n} = useTranslation();
    return (
        <Button href="/products">{i18n.language==='kr'?'제품 바로가기':'View our products'}</Button>
        
    )
}
const CustomContactUsMessage = () => {
    const {i18n} = useTranslation()
    return (
        <Box sx={{padding:'5%', boxShadow:2, backgroundColor:'lightgrey', textAlign:'left'}}>
            {i18n.language==="kr"?<Typography sx={{fontWeight:'bold'}}>고객 지원 센터 안내</Typography>:<Typography sx={{fontWeight:'bold'}}>Contact Us</Typography>}
            {i18n.language==="kr"?<Typography>전화: 02-3216-3983</Typography>:<Typography>TEL: 02-3216-3983</Typography>}
            {i18n.language==="kr"?<Typography>팩스: 02-3216-1900</Typography>:<Typography>FAX: 02-3216-1900</Typography>}
            <Typography>E-Mail : info@tovnet.co.kr</Typography>
            {i18n.language==="kr"?<Typography>운영 시간</Typography>:<Typography>Operating Hours</Typography>}
            {i18n.language==="kr"?<Typography>사무실: 평일 09:00~18:00</Typography>:<Typography>Office: Weekdays 09:00~18:00</Typography>}
            {i18n.language==="kr"?<Typography>기술 상담: 24시</Typography>:<Typography>Tech Consulting: 24/7</Typography>}
        </Box>
        
    )
}

const CustomGeneralMessage = () => {
  const {i18n} = useTranslation();
  return (
    <Button href="/support/contact-us" sx={{backgroundColor:'white', color:'red', transition:'background-color 0.2s ease-in-out', border:'1px solid #AE2424', fontWeight:'bold', '&:hover': {color:'white', backgroundColor: 'red'}}}>{i18n.language==='kr'?'문의하기':'Contact Us'}</Button>
    
)


}

const CustomServiceMessage = () => {
  const {i18n} = useTranslation();
  return (
    <Box className="chatbot-message" sx={{padding:'5%', boxShadow:2, backgroundColor:'lightgrey', textAlign:'left'}}>
            {i18n.language==="kr"?<Typography sx={{fontWeight:'bold'}}>소비자 과실의 예</Typography>:<Typography sx={{fontWeight:'bold'}}>Examples of consumer faults</Typography>}

      <ul >
            {i18n.language==="kr"?<li>소비자의 취급 부주의 또는 수리, 개조하여 고장 발생시</li>:<li>Faults resulting from consumer mishandling, repair, or modification.</li>}
            {i18n.language==="kr"?<li>판매원이나 서비스센터 기사가 아닌 사람이 수리하여 고장 발생 시</li>:<li>Faults occurring when someone other than a salesperson or service center technician attempts to repair the product.</li>}
            {i18n.language==="kr"?<li>설치 후 이동 시 떨어뜨림 등에 의한 고장, 손상 발생시</li>:<li>Faults or damage resulting from dropping during or after installation.</li>}
            {i18n.language==="kr"?<li>규정 전압을 사용하지 않거나 외부기기에 의한 불량 발생시</li>:<li>Faults occurring due to non-compliance with specified voltage or external device-related issues.</li>}
      </ul>
            
        </Box>
   
  )
}

const CustomWarrantyMessage = () => {
  const {i18n} = useTranslation();
  return (
    <Box className="chatbot-message" sx={{padding:'5%', boxShadow:2, backgroundColor:'lightgrey', textAlign:'left'}}>
            {i18n.language==="kr"?<Typography sx={{fontWeight:'bold'}}>제품 보증기간</Typography>:<Typography sx={{fontWeight:'bold'}}>Product Warranty Period</Typography>}

      <ul >
            {i18n.language==="kr"?<li>제품 보증기간이라 함은 제조사 (또는 판매자)가 소비자에게 정상적인 상태에서 자연 발생한 품질, 성능, 기능 하자에 대하여 무상 수리를 해주겠다고 약속한 기간을 말합니다.</li>:<li>The product warranty period refers to the duration during which the manufacturer (or seller) promises to provide free repairs for any quality, performance, or functional defects that occur naturally in the product when it is in normal condition.</li>}
            {i18n.language==="kr"?<li>제품 무상 보증 기간 : 1년</li>:<li>Free product warranty period: 1 year</li>}
            {i18n.language==="kr"?<li>당사와 별도 계약에 한하여 납품된 제품의 보증은 그 계약을 기준으로 적용합니다.</li>:<li>For products delivered under separate agreements with us, the warranty terms will be based on those specific agreements.</li>}
      </ul>
            
        </Box>
        
   
  )
}

const CustomWarrantyMessage2 = () => {
  const {i18n} = useTranslation();
  return (
    <Box className="chatbot-message" sx={{padding:'5%', boxShadow:2, backgroundColor:'lightgrey', textAlign:'left'}}>
        {i18n.language==="kr"?<Typography sx={{fontWeight:'bold'}}>부품의 보증기간</Typography>:<Typography sx={{fontWeight:'bold'}}>Component Warranty Period</Typography>}

  <ul >
        {i18n.language==="kr"?<li>부품보증이라 함은 제품을 구성하는 각 부품에 대한 품질 보증을 말하며 그 기간은 다음과 같습니다.</li>:<li>Component warranty refers to the quality guarantee for each component that makes up the product, and the warranty period is as follows:</li>}
        {i18n.language==="kr"?<li>유상으로 수리한 경우 수리한 날부터 60일 이내에 정상적으로 제품을 사용하는 과정에서 종전과 동일한 부품 고장이 재발한 경우는 무상수리 적용합니다.</li>:<li>If, within 60 days from the date of paid repair, the same component failure occurs during normal product usage, we will provide free repair.</li>}
  </ul>
        
    </Box>
  )
}

const CustomRepairMessage = () => {
  const {i18n} = useTranslation();
  return (
    <Box className="chatbot-message" sx={{padding:'5%', boxShadow:2, backgroundColor:'lightgrey', textAlign:'left'}}>
        {i18n.language==="kr"?<Typography sx={{fontWeight:'bold'}}>무상수리</Typography>:<Typography sx={{fontWeight:'bold'}}>Free Repairs</Typography>}

  <ul >
        {i18n.language==="kr"?<li>품질보증 기간 이내에 정상적인 사용 상태에서 발생된 성능, 기능상의 고장인 경우</li>:<li>Performance or functional defects that occur during the quality guarantee period while the product is in normal use.</li>}
        {i18n.language==="kr"?<li>고장 수리받은 후 60일 내에 제품의 동일 부위 고장으로 수리를 받는 경우</li>:<li>If the same component experiences a failure within 60 days after it was repaired for a different issue.</li>}
  </ul>

  {i18n.language==="kr"?<Typography sx={{fontWeight:'bold'}}>유상수리</Typography>:<Typography sx={{fontWeight:'bold'}}>Paid Repairs</Typography>}

  <ul >
        {i18n.language==="kr"?<li>보증기간이 경과된 제품</li>:<li>Products whose warranty period has expired.</li>}
        {i18n.language==="kr"?<li>고객 과실에 의한 경우 (파손, 과전압인가, 떨어뜨림, 설정오류 등)</li>:<li>Issues caused by customer negligence (damage, overvoltage, dropping, configuration errors, etc.).</li>}
        {i18n.language==="kr"?<li>천재지변(낙뢰, 화재, 지진, 풍수해, 해일 등)으로 인한 고장</li>:<li>Failures resulting from natural disasters (lightning, fire, earthquake, flood, etc.).</li>}
        {i18n.language==="kr"?<li>소모성 부품의 수명이 다한 경우(배터리, 기타)</li>:<li>When consumable parts have reached the end of their lifespan (e.g., batteries, and others).</li>}
        {i18n.language==="kr"?<li>당사 센터가 아닌 다른 곳에서 수리하여 문제가 된 경우</li>:<li>When the product was repaired elsewhere, not by our service center, and this caused the problem.</li>}
        {i18n.language==="kr"?<li>고객의 요청에 의한 작업(HDD 추가 작업, 장비설정, 사용설명, 이설, 조정 등)우</li>:<li>Any work performed at the customer's request (e.g., additional HDD installation, equipment configuration, user instructions, relocation, adjustments, etc.).</li>}
        {i18n.language==="kr"?<li>제품 내부의 먼지 세척 및 이물 제거 시</li>:<li>When cleaning dust and removing foreign objects from inside the product.</li>}
        {i18n.language==="kr"?<li>설치자 과실로 인해 발생한 제품 불량인 경우</li>:<li>If the product is defective due to installer negligence.</li>}
        {i18n.language==="kr"?<li>당사 제품 외 타사 제품 또는 기타 부품(자재)의 문제인 경우</li>:<li>When the issue is related to third-party products or other components (materials) not provided by our company.</li>}

  </ul>
    </Box>
  )
}




const Options = (props) => {
  const {i18n} = useTranslation();

  const options = [
    {
      text: ["서비스 접수 방법", "Service Request Method"],
      handler: props.actionProvider.handleService,
      id: 1,
    },
    { text: ["보증기간 안내", "Warranty Period Information"], handler: props.actionProvider.handleWarranty, id: 2 },
    { text: ["유/무상 수리기준", "Criteria for Free/Paid Repairs"], handler: props.actionProvider.handleRepair, id: 3 },
    { text: ["고객 지원 센터 안내", "Support Center"], handler: props.actionProvider.handleContact, id: 4},
    { text: ["질문하기", "Ask Questions"], handler: props.actionProvider.handleQuestion, id: 5 },
    { text: ["견적문의", "Request for Quotation"], handler: props.actionProvider.handleQuestion, id: 6 },
  ];



  return( 
  <div className="options-container">
    {options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {i18n.language==='kr'?option.text[0]:option.text[1]}
    </button>
  ))}
  </div>
  )
};

const getConfig = () => {
  return  {
    initialMessages: [
      createChatBotMessage(i18n.t("chatbot_intro1")),
      createChatBotMessage(i18n.t("chatbot_intro3")),
      createChatBotMessage(
        i18n.t("chatbot_intro2"),
          {
            withAvatar: false,
            delay: 500,
            widget: "options",
          }
          
        ),
    ],
  
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    }
    
    ],
  
    customStyles: {
      botMessageBox: {
          backgroundColor: '#AE2424',
          color: 'white'
      },
      chatButton: {
          backgroundColor: '#AE2424',
          color:'white'
      }
    },
    customMessages: {
      custom: (props) => <CustomProductLinkMessage {...props}/>,
      contactus: (props) => <CustomContactUsMessage {...props}/>,
      general: (props) => <CustomGeneralMessage {...props}/>,
      service: (props) => <CustomServiceMessage {...props}/>,
      warranty: (props) => <CustomWarrantyMessage {...props}/>,
      warranty2: (props) => <CustomWarrantyMessage2 {...props}/>,
      repair: (props) => <CustomRepairMessage {...props}/>
    }
  
  };
  
}






export default getConfig;