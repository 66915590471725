import { Box, CssBaseline, ThemeProvider, Typography, Pagination, Fade, Button, Tab } from "@mui/material";
import theme from "../../Theme";

import GeneralBanner from "../general_section/GeneralBanner";
import React from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import {Card, CardMedia} from "@mui/material";
import { calculatePagesCount } from "../../Functions/maxPage";
import LoadingAnimation from "../general_section/LoadingAnimation";
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {Link} from "react-router-dom"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { API_URL } from "../../../constants";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from '@mui/lab/TabList';



function InstallationCasePage() {
    const [searchParams] = useSearchParams();
    const [insPage, setInsPage] = React.useState([]);
    const {i18n} = useTranslation();

    const setYoutube = (language) => {

            if (insPage.video1 && insPage.video2 && insPage.video3) {
                return (
    <>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:insPage.video1}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:insPage.video2}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:insPage.video3}}/>
                </>
                )
            } else if (insPage.video1 && insPage.video2) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:insPage.video1}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:insPage.video2}}/>
                </>
                )
            } else if (insPage.video1 && insPage.video3) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:insPage.video1}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:insPage.video3}}/>
                </>
                )
            } else if (insPage.video2 && insPage.video3) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:insPage.video2}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:insPage.video3}}/>
                </>
                )
            } else if (insPage.video1) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:insPage.video1}}/>

                )
            } else if (insPage.video2) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:insPage.video2}}/>

                )
            } else if (insPage.video3) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:insPage.video3}}/>

                )
            }
    
      
        
        
        
    }
    

    React.useEffect( () => {
        
        axios.get(`https://${API_URL()}/adminAPI/api/resources/installation_case/records/${searchParams.get('id')}/show`).then((res)=>{
          setInsPage(res.data.record.params)
            
        }).catch(error => {console.log(`Error: ${error}`)});


        
        
      });
    return (
        <div>
        <GeneralBanner label={insPage.title} preset={"installationcase"}/>
            <Box sx={{padding:'5% 10%'}}>
            <Link to="/media/installation-case" id="backInstallationCaseButton" style={{display:'flex', textDecoration:'none', color:'black', "&:hover": {color:'red', textDecoration:'underline'}}} ><KeyboardArrowLeft/><Typography variant="h6">Back</Typography></Link>

<Box sx={{borderBottom:'0.5px solid grey', paddingLeft:'3%', mt:'2vh'}}>
                    <Typography variant="h3" sx={{color: 'red'}}>{insPage.title}</Typography>
                </Box>
                <Box sx={{width:'100%', padding:'0 15%', mt:'5%', display:'flex', flexDirection:{xs:'column', lg:'row'}, justifyContent:'space-between'}}>

               {setYoutube(i18n.language)}


</Box>
                <Box sx={{paddingLeft:'3%', mt:'5vh', width:'100%'}}  className="page-content" dangerouslySetInnerHTML={{__html:insPage.content}}>

                </Box>

            </Box>
        </div>
    )
}


const sm = 6;
const md = 4;


function InstallationCase() {
    const [isHovering, setIsHovering] = React.useState(null);
    const [page, ] = React.useState(null);
    const [maxPageNum, setMaxPageNum] = React.useState(1);
    const [currentTab, setCurrentTab] = React.useState('0');
    const tabs = [['0','InstallationCase1'], ['1','InstallationCase2'], ['2','InstallationCase3'],['3','InstallationCase4']];
    const {i18n, t} = useTranslation();
    const container = React.useRef(null);


    const [installationCase, setInstallationCase] = React.useState([]);

    const handleMouseOver = (key) => {
        setIsHovering(key);
    };

    const handleMouseOut = () => {
        setIsHovering(null);
    };

    const handleTabChange = (event, newValue) => {
        var idx = Number(newValue)
        setCurrentTab(newValue);
        axios.get(`https://${API_URL()}/adminAPI/api/resources/installation_case/actions/list?filters.category=${tabs[idx][1]}&page=${page}&perPage=9&filters.language=${i18n.language}`).then((response) => {
            setInstallationCase(response.data.records);
            setMaxPageNum(calculatePagesCount(9, response.data.meta.total));
        }).catch(error => {console.log(`Error: ${error}`)});

    }

    const handlePageChange = (event, newValue) => {
        var idx = Number(currentTab)
        axios.get(`https://${API_URL()}/adminAPI/api/resources/installation_case/actions/list?filters.category=${tabs[idx][1]}&page=${newValue}&perPage=9&filters.language=${i18n.language}`).then((res)=>{
          setInstallationCase(res.data.records);
        }).catch(error => {console.log(`Error: ${error}`)});
        
      }
      
      const [playAnimation, setPlayAnimation] = React.useState(true)
      document.body.style.overflow = 'scroll';


    React.useEffect( () => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/installation_case/actions/list?filters.category=InstallationCase1&page=1&perPage=9&filters.language=${i18n.language}`).then((res)=>{
        setCurrentTab('0')
        setInstallationCase(res.data.records);
        setMaxPageNum(calculatePagesCount(9, res.data.meta.total));
        }).catch(error => {console.log(`Error: ${error}`)});

        const onPageLoad = () => {
           setTimeout(()=> {
              setPlayAnimation(false)}, 1000)
            
          }
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }

          i18next.on('languageChanged', function(lng) {
            axios.get(`https://${API_URL()}/adminAPI/api/resources/installation_case/actions/list?filters.category=InstallationCase1&page=1&perPage=9&filters.language=${i18n.language}`).then((res)=>{
                setCurrentTab('0')
                setInstallationCase(res.data.records);
                setMaxPageNum(calculatePagesCount(9, res.data.meta.total));
                }).catch(error => {console.log(`Error: ${error}`)});
        })
    
  }, []);

    return (
        <ThemeProvider theme={theme}>
        <CssBaseline/>
        {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

        <GeneralBanner label={t("installationcase")} preset={"installationcase"}/>
       
        <Box sx={{marginTop: '5vh',boxShadow: '0 15px 18px #eeeeee'}}>


        <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent:'center', display: 'flex', gap: "7%"}}>
                <TabList onChange={handleTabChange} sx={{'.MuiTabs-indicator': {background: "#AE2424"}, '.Mui-selected': {color: "#000000 !important"}, '.MuiTab-root': {fontWeight:'bold', fontSize:{xs:'small', md:'medium', lg:'x-large'}}}}>
      
                
                {tabs.map((tab) => (
                    
                    <Tab key={`installation-case-tab-${tab[1]}`} label={t(tab[1])} value={tab[0]} sx={{width: '30vw'}}/>
                  
                ))}

                </TabList>
                </Box>
                {tabs.map((tab) => (
                <TabPanel key={`tabpanel-${tab[0]}`} value={tab[0]}>
                    <Grid container padding="0 10%" spacing={4}>
                    {installationCase.map((singleCase, index) => (
                        <Grid item key={singleCase.params.id} xs={12} sm={sm} md={md}>
                        
                        <Box position='relative'>

                        <Card sx={{ aspectRatio:'1.35', display: 'flex', flexDirection: 'column', boxShadow: 'none'}} onMouseOver={() => handleMouseOver(singleCase.params.id)}  onMouseOut={handleMouseOut} overflow='hidden' ref={container}>
                        

                            <CardMedia component="div" sx={{ aspectRatio:'1.35', zIndex:'0'}} image={singleCase.params.filePath}/>

                                {isHovering === singleCase.params.id && (
                                    <Fade in={Boolean(isHovering)}>
                                <Box>

                                <Box sx={{position: 'absolute', height: '100%', width: '100%', backgroundColor: 'black', opacity: '0.86', top:'0', borderRadius: '4px'}}>
                                    
                                </Box>
                                <Box sx={{position: 'absolute', height: '100%',  width:'100%', top:'0', display: 'flex', flexDirection: 'column', justifyContent:'space-evenly',alignItems:'center', padding:'10%', textAlign: 'center'}}>
                                    
                                    
                                   <Typography sx={{color:'white', fontWeight:'bold'}}>{singleCase.params.title}</Typography>
                                   
                                   <Button size='large' component={Link} sx={{fontWeight:'bold', color: 'white', width:{xs:'50%', md:'40%'}, mx:'auto',  aspectRatio:'3', backgroundColor: 'red', '.MuiButton-root&:hover': {backgroundColor:'white !important', color:'red', boxShadow:10}}} to={`/media/installation-case/installation-case-page?id=${singleCase.params.id}`}>View More</Button>
                                  

                   
                                </Box>

                                </Box>
                                </Fade>)}

                            

                        </Card>
                        </Box>
                    </Grid>
                        
                    ))}
                    
                    




                </Grid>




                    



                </TabPanel>
               

            ))}
            

      
        
        

        </TabContext>










                <Box sx={{display:'flex', justifyContent: 'center', paddingBottom: '10vh', paddingTop:'10vh'}}>

                    <Pagination count={maxPageNum} onChange={handlePageChange} size='large'/>

                </Box>
        </Box>
       
        </ThemeProvider>
    )
}
export {InstallationCase, InstallationCasePage};