import { CssBaseline, ThemeProvider, Typography, Box, Button, createTheme } from "@mui/material";
import { Image } from "mui-image";
import theme from "../../Theme";
import TabContext from "@mui/lab/TabContext";
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import React from "react";
import TabList from '@mui/lab/TabList';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSearchParams } from 'react-router-dom';
import axios from "axios";
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../constants";

const defaultTheme = createTheme(theme, {
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2.5rem'
                }
            }
        }
    }
});



export default function ProductSpecification() {
    const {i18n, t} = useTranslation();
    const tabs = [t('product_tab1'), t('product_tab3')];
    const [value, setValue] = React.useState('0');
    const [searchParams] = useSearchParams();
    const [product, setProduct] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const setYoutube = (language) => {
        if (language === 'kr') {
            if (product.video1 && product.video2 && product.video3) {
                return (
    <>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:product.video1}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:product.video2}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:product.video3}}/>
                </>
                )
            } else if (product.video1 && product.video2) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video1}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video2}}/>
                </>
                )
            } else if (product.video1 && product.video3) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video1}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video3}}/>
                </>
                )
            } else if (product.video2 && product.video3) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video2}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video3}}/>
                </>
                )
            } else if (product.video1) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:product.video1}}/>

                )
            } else if (product.video2) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:product.video2}}/>

                )
            } else if (product.video3) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:product.video3}}/>

                )
            }
        } else {
            if (product.video4 && product.video5 && product.video6) {
                return (
    <>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:product.video4}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:product.video5}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:product.video6}}/>
                </>
                )
            } else if (product.video4 && product.video5) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video4}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video5}}/>
                </>
                )
            } else if (product.video4 && product.video6) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video4}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video6}}/>
                </>
                )
            } else if (product.video5 && product.video6) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video5}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:product.video6}}/>
                </>
                )
            } else if (product.video4) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:product.video4}}/>

                )
            } else if (product.video5) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:product.video5}}/>

                )
            } else if (product.video6) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:product.video6}}/>

                )
            }
        }
        
        
        
    }
   

    React.useEffect( () => {

        axios.get(`https://${API_URL()}/adminAPI/api/resources/product/records/${searchParams.get('productid')}/show`).then((res)=>{
            setProduct(res.data.record.params)

          
            
        }).catch(error => {console.log(`Error: ${error}`)});
      
        
        
      });


    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            {/* <GeneralBanner label={i18n.language==='kr'?product.name_kr:product.name} preset={'product'}/> */}
            <Box sx={{width: "100vw", height:"50vh", position:"relative", top:"0", display:'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center', background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(85,85,85,1) 100%)'}}>
                <Image sx={{aspectRatio: '1', width:'90% !important'}} src={product.filePath?product.filePath:""} ></Image>

                <Typography sx={{typography: {xs:'h4', md:'h3'}, color:'white', fontWeight: 'bold !important', textAlign:'center', position:'absolute', bottom:'7%',}}>{i18n.language==='kr'?product.name_kr:product.name}</Typography>
            </Box>



            <Box display='flex' sx={{flexDirection: {xs:'column-reverse', lg:'row'}}}>
            {i18n.language==='kr'?<Typography className="product-specification-content" sx={{color:'darkGrey', fontWeight:'bold', mx:'auto'}} dangerouslySetInnerHTML={{__html: product.content_kr}}></Typography>:<Typography className="product-specification-content" sx={{color:'darkGrey', fontWeight:'bold', mx:'auto'}} dangerouslySetInnerHTML={{__html: product.content}}></Typography>}


            </Box>

            <Box>


                


            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent:'center', display: 'flex', gap: "7%"}}>
            <TabList onChange={handleChange} sx={{'.MuiTabs-indicator': {background: "#AE2424"}, '.Mui-selected': {color: "#000000 !important"}, '.MuiTab-root': {fontWeight:'bold', fontSize:{xs:'medium', lg:'x-large'}}}}>

            {tabs.map((tabs, index) => (
                <Tab key={`product-spec-tab-${index}`} label={tabs} value={`${index}`} sx={{width:'30vw'}}/>
                
            ))}
            </TabList>
            </Box>
            <TabPanel value='0' sx={{padding:'0'}}>
            <Box sx={{width:'100%', padding:'0 15%', mt:'5%', display:'flex', flexDirection:{xs:'column', lg:'row'}, justifyContent:'space-between'}}>

               {setYoutube(i18n.language)}

                </Box>
                <Box sx={{display:'flex', padding:{xs:'0 10%', lg:'0 20%'}, justifyContent:'center', mt:'5%'}}>
                    {i18n.language==='kr'?<Typography sx={{textAlign:'center', width: '100%'}} className="page-content" dangerouslySetInnerHTML={{__html:product.specifications_kr}}/>:<Typography sx={{textAlign:'center', width: '100%'}} className="page-content" dangerouslySetInnerHTML={{__html:product.specifications}}/>}
                </Box>
            </TabPanel>

            <TabPanel value='1' sx={{padding:'0'}}>
                <Box sx={{display:'flex', flexDirection:{xs:'column', md:'row'}, padding: '5%'}}>
                    <Box sx={{display:'flex', flexDirection:'row',my:{xs:'5%', md:'0'}, mx:'auto', alignItems:'center'}}>
                            <EmailIcon sx={{color:'red', pr:'5%', fontSize:{xs:'2rem',md:'4rem'}}}/>
                        <Typography  variant="h5" sx={{fontWeight:'bold'}}>info@tovnet.co.kr</Typography>
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'row',my:{xs:'5%', md:'0'}, mx:'auto', alignItems:'center'}}>
                        <CallIcon sx={{color:'red', pr:'5%', fontSize:{xs:'2rem',md:'4rem'}}}></CallIcon>
                        <Typography  variant="h5" sx={{fontWeight:'bold'}}>{t('phone')}</Typography>
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'row',my:{xs:'5%', md:'0'}, mx:'auto', alignItems:'center'}}>
                        <AccessTimeIcon sx={{color:'red', pr:'5%', fontSize:{xs:'2rem',md:'4rem'}}}></AccessTimeIcon>
                        <Typography  variant="h5" sx={{fontWeight:'bold'}}>9:00am - 6:00pm</Typography>
                    </Box>
                </Box>
                <Box sx={{display:'flex', justifyContent:'center'}}>
                <Button variant='contained' size='large' sx={{backgroundColor:'red', fontWeight:'bold !important', '&:hover': {backgroundColor:'white', color:'red'}}}  component={Link} to="../support/contact-us">{i18n.language==='kr'?"문의하기":"Send Message"}</Button>


                </Box>


            </TabPanel>
            </TabContext>
        </Box>
        </ThemeProvider>
    )
}