// new file called DogPicture.jsx
import { Avatar, Collapse, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import chatbotImage from "../../assets/chatbot.png"
import ChatBot from './ChatBot';

const ChatbotWidget = () => {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen((prev) => !prev)
    }
    const {t} = useTranslation()


  return (
        <>
        <div className="speech-bubble pbottom aright" style={{"--bbColor":"white", display:open?'none':'block'}}>
            <div className="title">{t('chatbot_floating')}</div>
        </div>
        <IconButton onClick={handleOpen}  className='chatbot' sx={{boxShadow:15, padding:'0', backgroundColor:'transparent'}}>
            <Avatar src={chatbotImage} alt='chatbotWidget'  sx={{marginLeft:'auto', width:{xs:'15vw', md:'8vw', lg:'5vw', xl:'4vw'}, height:{xs:'15vw',md:'8vw', lg:'5vw', xl:'4vw'}}}/>
        </IconButton>
        <Collapse in={open}>
            <ChatBot/>
        </Collapse>
    </>
  );
};

export default ChatbotWidget;