import { Box, CssBaseline, ThemeProvider} from "@mui/material";
import theme from "../../Theme";
import GeneralBanner from "../general_section/GeneralBanner";
import React from "react";
import LoadingAnimation from "../general_section/LoadingAnimation";
import { useTranslation } from "react-i18next";
import axios from "axios";



     

function StoreLocation() {
  

  const {i18n, t} = useTranslation();
  const [playAnimation, setPlayAnimation] = React.useState(true)
  const [, setLocations] = React.useState([])

  document.body.style.overflow = 'scroll'

  


  React.useEffect(() => {


    const onPageLoad = () => {
      setTimeout(()=> {
         setPlayAnimation(false)}, 1000)
       
     }
     if (document.readyState === 'complete') {
       onPageLoad();
     } else {
       window.addEventListener('load', onPageLoad, false);
       // Remove the event listener when component unmounts
       return () => window.removeEventListener('load', onPageLoad);
     }

  
    
  }, [])



 
  return (
  <>
    {playAnimation && (document.body.style.overflow = "hidden") && (<LoadingAnimation/>)}
            <GeneralBanner label ={t('storelocation')} preset={"storelocation"}/>

          <Box className="map-container" sx={{ padding:'5vh 10%'}}>
  



<iframe title="storeLocator" src="https://www.google.com/maps/d/u/0/embed?mid=1B7u3lxaXtykijICMGSCSowpC8m-co8E&ehbc=2E312F&noprof=1" width="640" height="480"></iframe>



    

  </Box>



            
            
          </>
  )
}

export {StoreLocation};