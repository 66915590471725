import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css';
import "./components/i18/i18n";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Logo, AboutUs, HeadOffice, StoreLocation } from './components/pages/Company';
import Products from './components/pages//Products/Products.js';
import {Catalogue, Event, EventPage, InstallationCase, News, NewsPage} from './components/pages/Media';
import Home from './components/pages/Home.js';
import {FnQ, Notice, Userguide, ContactUs, MarketingMaterials } from './components/pages/Support';
import ProductSpecification from './components/pages/Products/ProductSpecification.js';
import ScrollToTop from './components/Functions/scrollToTop.js';
import { InstallationCasePage } from './components/pages/Media/InstallationCase.js';
import { ThemeProvider } from '@mui/material/styles';
import theme  from './components/Theme.js';
import { CssBaseline} from '@mui/material';
import { RecoilRoot } from 'recoil';
import { AiSolutionPage } from './components/pages/Products/AiSolution';


const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RecoilRoot>

      
    <BrowserRouter>
      <ScrollToTop/>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
      <Routes>
    
        
        <Route path="/" element={<Home/>}/>
        <Route path="/company/about-us" element={<AboutUs/>}/>
        <Route path="/company/tovnet-ci" element={<Logo/>}/>
        <Route path="/company/head-office" element={<HeadOffice/>}/>
        <Route path="/company/store-location" element={<StoreLocation/>}/>
        <Route path="/products" element={<Products/>}/>
        <Route path="/media/news" element={<News/>}/>
        <Route path="/media/event" element={<Event/>}/>
        <Route path="/media/installation-case" element={<InstallationCase/>}/>
        <Route path="/media/catalogue" element={<Catalogue/>}/>
        <Route path="/support/faq" element={<FnQ/>}/>
        <Route path="/support/notice" element={<Notice/>}/>
        <Route path="/support/user-guide" element={<Userguide/>}/>
        <Route path="/support/contact-us" element={<ContactUs/>}/>
        <Route path="/products/product-specification" element={<ProductSpecification/>}></Route>
        <Route path="/media/news/news-page" element={<NewsPage/>}></Route>
        <Route path="/media/event/event-page" element={<EventPage/>}></Route>
        <Route path="/media/installation-case/installation-case-page" element={<InstallationCasePage/>}></Route>
        <Route path="/products/ai-solution" element={<AiSolutionPage/>}></Route>



      </Routes>
      <App/>
      </ThemeProvider>
      </BrowserRouter>
      </RecoilRoot>
    </I18nextProvider>
      
    

  </React.StrictMode>
);

