import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

import { styled } from '@mui/material/styles';


const CustomAccordion = styled(Accordion)(({ theme }) => {
  return {
    boxShadow: 'none', // this styles directly apply to accordion
    border: '0'
    } 
  
  });

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));

export {CustomAccordion, AccordionSummary};