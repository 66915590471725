import { CssBaseline, ThemeProvider, Typography, Box, Grid, Card, CardMedia, CardContent, Pagination} from "@mui/material";
import React from "react";
import theme from "../../Theme";
import GeneralBanner from "../general_section/GeneralBanner";
import { useSearchParams } from 'react-router-dom';
import axios from "axios";
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../constants";

function AiSolution() {

    const [aiSol, setAiSol] = React.useState([]);
    const [, setPageNum] = React.useState(1);
    const [maxPageNum, ] = React.useState(1);
    const {i18n} = useTranslation();
  

    const handlePageChange = (event, newValue) => {
      setPageNum(newValue);
      
      axios.get(`https://${API_URL()}/adminAPI/api/resources/ai_solution/actions/list?page=${newValue}&perPage=12&sortBy=post_date&direction=desc&filters.language=${i18n.language}`).then((res)=>{

        setAiSol(res.data.records);
      }).catch(error => {console.log(`Error: ${error}`)});
      
    }
    

    React.useEffect( () => {
      
    
      axios.get(`https://${API_URL()}/adminAPI/api/resources/ai_solution/actions/list?page=1&perPage=12&sortBy=post_date&direction=desc&filters.language=${i18n.language}`).then((res)=>{
        setAiSol(res.data.records);
        }).catch(error => {console.log(`Error: ${error}`)});
      
    },[]);
  
  
  
  
  
  
    return (
        <>
    <Grid container spacing={4} sx={{mt:'2vh'}}>
        {aiSol.map((item,index) => (
        <Grid item key={item.params.id} xs={12} sm={6} md={4} lg={4} xl={3}>
            <Link to={`/products/ai-solution?id=${item.params.id}`} style={{textDecoration:'none'}}>
            <Card className='product-card' sx={{position:'relative', display: 'flex', flexDirection: 'column' , boxShadow: '15px', height:'100%', '&:hover':{borderTop:'3px solid #AE2424'}, transition: 'border-color 0.2s ease-in-out'}} >
                <div className="product-card-img-wrapper" style={{overflow:'hidden'}}>
                <CardMedia className="product-img" component="div" sx={{pt: '80%', }} image={item.params.filePath}/>
                </div>
                <CardContent  sx={{display:'flex', flexDirection:'column', flexGrow:1, width:'100%', backgroundColor:'#f6f6f6', paddingBottom:'0'}}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', flexDirection:'column',height:'100%', textAlign:'center'}}>
                    <Typography className="product-card-content" variant='h5' sx={{color:'red', fontWeight:'bold', marginBottom:'5%'}}>
                    {item.params.title}
                    </Typography>
                </div>
                </CardContent>
            </Card>
            </Link>
        </Grid>
        ))}
    </Grid>
  
           
          <Box sx={{display:'flex', justifyContent: 'center', paddingBottom: '10vh', paddingTop:'10vh'}}>
  
                      <Pagination count={maxPageNum} onChange={handlePageChange} size='large'/>
           
  
  
                  </Box>
                  </>
    );
  }
  

  
function AiSolutionPage() {
    const [searchParams] = useSearchParams();
    const [aiPage, setAiPage] = React.useState([]);
    const {t} = useTranslation();

    React.useEffect( () => {
        
        axios.get(`https://${API_URL()}/adminAPI/api/resources/ai_solution/records/${searchParams.get('id')}/show`).then((res)=>{

        setAiPage(res.data.record.params)
            
        }).catch(error => {console.log(`Error: ${error}`)});


        
        
      });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <GeneralBanner label={aiPage.title} preset={'product'}/>
            <Box sx={{padding:'5% 10%'}}>

                    <Box className="ai-content" sx={{ marginTop:'5vh'}} dangerouslySetInnerHTML={{__html:aiPage.content}}/>
                    
                    {aiPage.file?
                    
                    <Box sx={{display: 'grid'}}>
                    <Typography sx={{border:'0.5px solid lightgrey', gridColumn: '1 / span 1', textAlign:'center', padding: '0.5em 0'}}>{t("file")}</Typography>
                    <Typography sx={{'&:hover': {color:'red', textDecoration:'underline'}, paddingTop: '0.5em', paddingBottom:'0.5em', paddingLeft:'2em', gridColumn: '2 / span 6', border:'0.5px solid lightgrey', borderLeft: '0', width:'100%'}}><Link to={aiPage.downloadFilePath}>{aiPage.downloadfilename}</Link></Typography>
                    </Box>:""
                        
                    }

                

            </Box>


        </ThemeProvider>
    )
}

export {AiSolution, AiSolutionPage}