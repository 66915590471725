import {  CssBaseline, Pagination, ThemeProvider} from "@mui/material";
import Intro from "../general_section/Intro";
import theme from "../../Theme.js"

import Box from "@mui/material/Box";
import GeneralBanner from "../general_section/GeneralBanner";
import React from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { calculatePagesCount } from "../../Functions/maxPage";
import Fade from '@mui/material/Fade';

import LoadingAnimation from "../general_section/LoadingAnimation";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom"
import { API_URL } from "../../../constants";

const sm = 6;
const md = 4;





function Event() {
    const [isHovering, setIsHovering] = React.useState(null);
    const [maxPageNum, setMaxPageNum] = React.useState(1);
    const container = React.useRef(null);
    const {i18n, t} = useTranslation();

    

    const [event, setEvent] = React.useState([]);

    const handleMouseOver = (key) => {
        setIsHovering(key);
    };

    const handleMouseOut = () => {
        setIsHovering(null);
    };

    const handlePageChange = (event, newValue) => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/event_board/actions/list?page=${newValue}&perPage=9&filters.language=${i18n.language}`).then((res)=>{
          setEvent(res.data.records);
        }).catch(error => {console.log(`Error: ${error}`)});
        
      }
      
      const [playAnimation, setPlayAnimation] = React.useState(true)
      document.body.style.overflow = 'scroll'

      


    React.useEffect( () => {
        
        axios.get(`https://${API_URL()}/adminAPI/api/resources/event_board/actions/list?page=1&perPage=9&filters.language=${i18n.language}`).then((res)=>{
        setEvent(res.data.records);
        setMaxPageNum(calculatePagesCount(9,res.data.meta.total));
        }).catch(error => {console.log(`Error: ${error}`)});

        const onPageLoad = () => {
            setTimeout(()=> {
              setPlayAnimation(false)}, 1000)
            
          }
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }
    
          i18next.on('languageChanged', function(lng) {
            axios.get(`https://${API_URL()}/adminAPI/api/resources/event_board/actions/list?page=1&perPage=9&filters.language=${i18n.language}`).then((res)=>{
                setEvent(res.data.records);
                setMaxPageNum(calculatePagesCount(9,res.data.meta.total));
                }).catch(error => {console.log(`Error: ${error}`)});
           
        })
    
    
  });


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

            <GeneralBanner label={t("event")} preset={"event"}/>
            <Box sx={{marginTop: '10vh'}}>
            <Intro title={t('event_banner1')} content={'event_banner2'}/>
            </Box>
            <Box sx={{marginTop: '10vh',boxShadow: '0 15px 18px #eeeeee'}}>
            <Grid container padding="0 10%" spacing={4}>
                    {event.map((singleEvent, index) => (
                        <Grid item key={singleEvent.params.id} xs={12} sm={sm} md={md}>
                        
                        <Box position='relative'>

                        <Card sx={{ aspectRatio:'1.35', display: 'flex', flexDirection: 'column', boxShadow: 'none'}} onMouseOver={() => handleMouseOver(singleEvent.params.id)}  onMouseOut={handleMouseOut} overflow='hidden' ref={container}>
                        

                            <CardMedia component="div" sx={{ aspectRatio:'1.35', zIndex:'0'}} image={singleEvent.params.filePath}/>

                                {isHovering === singleEvent.params.id && (
                                    <Fade in={Boolean(isHovering)}>
                                <Box>

                                <Box sx={{position: 'absolute', height: '100%', width: '100%', backgroundColor: 'black', opacity: '0.86', top:'0', borderRadius: '4px'}}>
                                    
                                </Box>
                                <Box sx={{position: 'absolute', height: '100%',  width:'100%', top:'0', display: 'flex', flexDirection: 'column', justifyContent:'space-evenly',alignItems:'center', padding:'10%', textAlign: 'center'}}>
                                    
                                    
                                <Typography  sx={{color:'white', fontWeight:'bold !important', typography:{xs:'body', md:'h6'}}}>{singleEvent.params.title}</Typography>

                                <Button size='large' component={Link} sx={{fontWeight:'bold', color: 'white', width:{xs:'50%', md:'40%'}, mx:'auto', aspectRatio:'3', backgroundColor: 'red', '.MuiButton-root&:hover': {backgroundColor:'white !important', color:'red', boxShadow:10}}} to={`/media/event/event-page?eventid=${singleEvent.params.id}`}>View More</Button>

                                </Box>

                                </Box>
                                </Fade>)}

                            

                        </Card>
                        </Box>
                    </Grid>
                        
                    ))}
                    
                    




                </Grid>


        <Box sx={{display:'flex', justifyContent: 'center', paddingBottom: '10vh', paddingTop:'10vh'}}>

                    <Pagination count={maxPageNum} onChange={handlePageChange} size='large'/>

                </Box>

            </Box>
        
        </ThemeProvider>
    )
}

export {Event};