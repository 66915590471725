import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../constants";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import React from "react";




export default function Modal(props) {
    const [state, setState] = React.useState({});
    const {i18n} = useTranslation();
    const {onClose} = props;



    React.useEffect(() => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/modal/actions/list?filters.language=${i18n.language}&filters.active=1`).then((res)=>{
            if (res.data.records.length === 0) setState({})
            else setState(res.data.records[0].params)
        }).catch(error => {console.log(`Error: ${error}`)});
    }, [i18n.language])


    return (
            Object.keys(state).length > 0?
            (<div className={`modal-${state.loc}`}>
                <div className="modal-wrapper">
                    <img className={`modal-img`} src={`${state?.filePath}`}/>
                    <IconButton className="close-button" onClick={() => onClose(false)}><CloseIcon/></IconButton> 
                </div>
                    
            </div>):null
       
        


    )
}