import { Box, Grid, Typography, Tab} from "@mui/material";


import GeneralBanner from "../general_section/GeneralBanner";
import GoogleMapReact from 'google-map-react';
import React from "react";
import LoadingAnimation from "../general_section/LoadingAnimation";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from "@mui/lab/TabContext";
import TabList from '@mui/lab/TabList';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../../../constants";




function HeadOffice() {


      const renderMarkers = (map, maps, position, content) => {
        let marker = new maps.Marker({
        position: { lat: position.lat, lng: position.lng },
        map,
        title: 'location'
        });
        let infowindow = new maps.InfoWindow({
            content: `<h3>${content.title}</h3><br/><p>${content.info}</p>`,
        })
        marker.addListener("click", () => {
            infowindow.open({
                anchor: marker,
                map,
            })
        })  
        return marker;
       };

       const [playAnimation, setPlayAnimation] = React.useState(true)
       const [tabValue, setTabValue] = React.useState("kr");
       const [krOffice, setKrOffice] = React.useState({});
       const [nzOffice, setNzOffice] = React.useState({});
       const [usaOffice, setUsaOffice] = React.useState({});
       const [kr_lat, setKrLat] = React.useState();
       const [kr_lng, setKrLng] = React.useState();
        const [nz_lat, setnzLat] = React.useState();
       const [nz_lng, setnzLng] = React.useState();
       const [usa_lat, setusaLat] = React.useState();
       const [usa_lng, setusaLng] = React.useState();
       const {i18n, t} = useTranslation();
       document.body.style.overflow = 'scroll'

       React.useEffect( () => {
        const onPageLoad = () => {
           setTimeout(()=> {
              setPlayAnimation(false)}, 1000)
            
          }
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
          }

          axios.get(`https://${API_URL()}/adminAPI/api/resources/location/actions/list?filters.office_type=kr`).then((res)=>{
            setKrOffice(res.data.records[0].params)
            setKrLat(res.data.records[0].params.latitude)
            setKrLng(res.data.records[0].params.longitude)
            

          }).catch(error => {console.log(`Error: ${error}`)});

          axios.get(`https://${API_URL()}/adminAPI/api/resources/location/actions/list?filters.office_type=nz`).then((res)=>{
            setNzOffice(res.data.records[0].params)
            setnzLat(res.data.records[0].params.latitude)
            setnzLng(res.data.records[0].params.longitude)
            

          }).catch(error => {console.log(`Error: ${error}`)});

          axios.get(`https://${API_URL()}/adminAPI/api/resources/location/actions/list?filters.office_type=usa`).then((res)=>{
            setUsaOffice(res.data.records[0].params)
            setusaLat(res.data.records[0].params.latitude)
            setusaLng(res.data.records[0].params.longitude)
            

          }).catch(error => {console.log(`Error: ${error}`)});

          
   

     
        
      


        
        
      }, []);



       

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        

        
    };





    return (
        <div id="headOffice">
            {playAnimation && (document.body.style.overflow = "hidden") && (<LoadingAnimation/>)}

            <GeneralBanner label={t("headofficebanner")} preset={"headoffice"} filter={"unset"}/>

            <TabContext value={`${tabValue}`}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent:'center', display: 'flex', gap: "7%", marginTop:'5vh'}}>
            <TabList onChange={handleTabChange} sx={{'.MuiTabs-indicator': {background: "#AE2424"}, '.Mui-selected': {color: "#000000 !important"}, '.MuiTab-root': {fontWeight:'bold', fontSize:{xs:'medium', lg:'x-large'}}}}>
                <Tab key={`headofficetab-1`} label={t("korea")} value="kr" sx={{width: '30vw'}}/>
                <Tab key={`headofficetab-2`} label={t("newzealand")} value="nz" sx={{width: '30vw'}}/>
                <Tab key={`headofficetab-3`} label={t("america")} value="usa" sx={{width: '30vw'}}/>

            </TabList>
            </Box>

                <TabPanel key={`headofficetab-1`} value={'kr'} >

            <Box sx={{padding: {xs:'0 10%', lg:'0 25%'}, marginTop:'3vh', display:'flex', justifyContent:'center'}}>

                <Grid container direction='row' display='flex' alignItems='center' spacing={{xs:1}}>
                    <Grid item xs={4}>
                        <Typography  sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>{i18n.language==='kr'?' 주소':'Address'}</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography  sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>{t('head_address')}</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography  sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>TEL</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography  sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>+82 10-5389-3983</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography  sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>EMAIL</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography  sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>info@tovnet.co.kr</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>FAX</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography  sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>+82 2-3216-1900</Typography>
                    </Grid>
                </Grid>


            </Box>
            <Box sx={{width:'100%', height:'80vh', marginTop:'5vh', padding:'0 10%'}}>
    
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY}}
                center={{"lat": kr_lat,"lng": kr_lng}}
                zoom={17}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps, {lat:krOffice.latitude , lng: krOffice.longitude}, {title:'Head Office', info:krOffice.address})}

                >


            </GoogleMapReact>

            </Box>



                </TabPanel>


            <TabPanel key={`headofficetab-2`} value={'nz'} >

            <Box sx={{padding: {xs:'0 5%', lg:'0 25%'}, marginTop:'3vh', display:'flex', justifyContent:'center'}}>

                <Grid container direction='row' display='flex' alignItems='center' spacing={{xs:1}}>
                    <Grid item xs={4}>
                        <Typography  sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>{i18n.language==='kr'?' 주소':'Address'}</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        
                        <Typography  sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>{nzOffice.address}</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography  sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>TEL</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography  sx={{fontWeight:'bold !important',fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>+64 9-579-2603 </Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography  sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>EMAIL</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography  sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>info@tovnet.co.kr</Typography>
                    </Grid>
                </Grid>


            </Box>
            <Box sx={{width:'100%', height:'80vh', marginTop:'5vh', padding:'0 10%'}}>

            <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
            center={{"lat": nz_lat,"lng":nz_lng}}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps, {lat:nzOffice.latitude , lng: nzOffice.longitude}, {title:'New Zealand Office', info: nzOffice.address})}

            >


            </GoogleMapReact>

            </Box>



                </TabPanel>


            <TabPanel key={`headofficetab-3`} value={'usa'} >

            <Box sx={{padding: {xs:'0 5%', lg:'0 25%'}, marginTop:'3vh', display:'flex', justifyContent:'center'}}>

                <Grid container direction='row' display='flex' alignItems='center' spacing={{xs:1}}>
                    <Grid item xs={4}>
                        <Typography variant="h5" sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>{i18n.language==='kr'?' 주소':'Address'}</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography variant="h6" sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>{usaOffice.address}</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography variant="h5" sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>TEL</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography variant="h6" sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>+1 949-303-9994</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography variant="h5" sx={{color:'red', fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h5'}}}>EMAIL</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography variant="h6" sx={{fontWeight:'bold !important', fontSize:{xl:'1.6rem'},typography:{xs:'body1', lg:'h6'}}}>info@tovnet.co.kr</Typography>
                    </Grid>
                </Grid>


            </Box>
            <Box sx={{width:'100%', height:'80vh', marginTop:'5vh', padding:'0 10%'}}>

            <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
            center={{"lat": usa_lat,"lng":usa_lng}}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps, {lat:usaOffice.latitude , lng: usaOffice.longitude}, {title:'LA Office', info:usaOffice.address})}

            >


            </GoogleMapReact>

        </Box>



        </TabPanel>
    </TabContext>


    </div>
    )
}

export {HeadOffice};