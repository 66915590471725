import { Avatar,  Button,  Fade, Grid, Typography, useScrollTrigger, Zoom } from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import theme from '../../Theme.js';
import Box from '@mui/material/Box';
import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import GeneralBanner from '../general_section/GeneralBanner.js';
import ceoImage from "../../../assets/ceo.jpeg"
import LoadingAnimation from '../general_section/LoadingAnimation.js';
import { useTranslation } from 'react-i18next';
import history18 from '../../../assets/history-18.jpeg';
import history19 from '../../../assets/history-19.jpeg';
import history20_1 from '../../../assets/history-20-1.jpeg';
import history20_2 from '../../../assets/history-20-2.jpeg';
import history20_3 from '../../../assets/history-20-3.jpeg';
import history20_4 from '../../../assets/history-20-4.jpeg';
import history20_5 from '../../../assets/history-20-5.jpeg';
import history20_6 from '../../../assets/history-20-6.jpeg';
import history21_1 from '../../../assets/history-21-1.jpeg';
import history21_2 from '../../../assets/history-21-2.jpeg';
import history13 from "../../../assets/history-13.jpeg";
import history14_1 from '../../../assets/history-14-1.jpeg';
import history14_2 from '../../../assets/history-14-2.jpeg';
import history14_3 from '../../../assets/history-14-3.jpeg';
import history14_4 from '../../../assets/history-14-4.jpeg';
import history14_5 from '../../../assets/history-14-5.jpeg';
import history15_1 from '../../../assets/history-15-1.jpeg';
import history15_2 from '../../../assets/history-15-2.jpeg';
import history15_3 from '../../../assets/history-15-3.jpeg';
import history15_4 from '../../../assets/history-15-4.jpeg';
import history16_1 from '../../../assets/history-16-1.jpeg';
import history16_2 from '../../../assets/history-16-2.jpeg';
import history16_3 from '../../../assets/history-16-3.jpeg';
import history16_4 from '../../../assets/history-16-4.jpeg';
import history20_1_eng from '../../../assets/history-20-1_eng.png';
import history20_2_eng from '../../../assets/history-20-2_eng.png';
import history19_eng from '../../../assets/history-19_eng.png';
import history20_4_eng from '../../../assets/history-20-4_eng.png';
import history15_1_eng from '../../../assets/history-15-1_eng.png';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown.js';
import KeyboardArrowDUp from '@mui/icons-material/KeyboardArrowUp.js';

let customTheme = createTheme(theme, {
    overrides: {
        MuiTabs: {
          indicator: {
            backgroundColor: "#AE2424"
          }
        }
}});

function CustomTabPanel(props) { 
    const { children, value, index, ...other } = props;
   

  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{color:'darkGrey'}}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CertificationTabs() {
    const {i18n, t} = useTranslation();

    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
        <div className="cert-tab">            
      <Box sx={{overflow:'hidden', flexGrow:1}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:'5vh' }}>
          <Tabs value={value} onChange={handleChange} aria-label="history-tabs" sx={{'.MuiTabs-scroller':{ overflow:{xs:"scroll !important", md:'unset !important'}},'.MuiTabs-indicator': {background: "#AE2424"}, '.Mui-selected': {color: "#000000 !important"}, '.MuiTab-root': {fontWeight: 'bold', fontSize:{xs:'medium', lg:'x-large'}}, ".MuiTabs-flexContainer": {gap:'5%'}}}>
          <Tab label={t("awards")} {...a11yProps(0)}/>
            <Tab label={t('certification')} {...a11yProps(1)}/>
            <Tab label={t('patents')} {...a11yProps(2)} />
           
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={4} sx={{textAlign:'center'}}>
            <figure style={{width:'min-content'}}><img src="/images/prize/p1.jpeg" alt="prize1"></img><figcaption>{i18n.language==='kr'?'':'2018 Ministry of Commerce, Industry and Energy Citation'}</figcaption></figure>
            <figure style={{width:'min-content'}}><img src="/images/prize/p3.jpeg" alt="prize3"></img><figcaption>{i18n.language==='kr'?'':'Minister of Science and ICT Award'}</figcaption></figure>
            <figure style={{width:'min-content'}}><img src="/images/prize/p4.jpeg" alt="prize4"></img><figcaption>{i18n.language==='kr'?'':"Prime Minister's Award"}</figcaption></figure>
            <figure style={{width:'min-content'}}><img src="/images/prize/p6.jpeg" alt="prize6"></img><figcaption>{i18n.language==='kr'?'':"Minister of Trade, Industry and Energy's commendatio"}</figcaption></figure>
            <figure style={{width:'min-content'}}><img src="/images/prize/p7.jpeg" alt="prize7"></img><figcaption>{i18n.language==='kr'?'':'Citation (Minister of Small and Medium Business Venture)'}</figcaption></figure>
            <figure style={{width:'min-content'}}><img src="/images/prize/p8.jpeg" alt="prize8"></img><figcaption>{i18n.language==='kr'?'':'Commendation of the Head of the Seoul Regional Headquarters of the Federation of SMEs'}</figcaption></figure>
            
          </Grid>

        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Grid container spacing={0}  sx={{textAlign:'center'}}>

            <figure style={{width:'min-content'}}><img style={{width:'250px', height:'360px'}} src="/images/cert/c1.jpg" alt="cert1"></img><figcaption>{i18n.language==='kr'?"":"TOVNET Co., Ltd. PMS Certification"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}><img style={{width:'250px', height:'360px'}} src="/images/cert/c2.jpg" alt="cert2"></img><figcaption>{i18n.language==='kr'?"":"Korea Defense Acquisition Program Administration TOVNET Reliable Camera"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}><img style={{width:'250px', height:'360px'}} src="/images/cert/c3.jpg" alt="cert3"></img>
            <figcaption>{i18n.language==='kr'?"":"Global SME 1000+"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}> <img style={{width:'250px', height:'360px'}} src="/images/cert/c4.jpg" alt="cert4"></img>
            <figcaption>{i18n.language==='kr'?"":"Designation of Technology Development Product"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}> <img style={{width:'250px', height:'360px'}} src="/images/cert/c5.jpg" alt="cert5"></img><figcaption>{i18n.language==='kr'?"":"MainBiz"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>  <img style={{width:'250px', height:'360px'}} src="/images/cert/c6.jpg" alt="cert6"></img><figcaption>{i18n.language==='kr'?"":"Venture Nara Certification"}</figcaption></figure>

            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c7.jpg" alt="cert7"></img><figcaption>{i18n.language==='kr'?"":"Performance Certification (NET)"}</figcaption></figure>

            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c8.jpg" alt="cert8"></img><figcaption>{i18n.language==='kr'?"":"Small Business Specialized Enterprise Confirmation"}</figcaption></figure>

            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c9.jpg" alt="cert9"></img><figcaption>{i18n.language==='kr'?"":"New technology (NET) certification English certificate"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c10.jpg" alt="cert10"></img><figcaption>{i18n.language==='kr'?"":"Best small business product certification - Thermal imaging"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c11.jpg" alt="cert11"></img><figcaption>{i18n.language==='kr'?"":"Best small business product certification - light bulb camera"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c12.jpg" alt="cert12"></img><figcaption>{i18n.language==='kr'?"":"Light bulb camera new technology certificate"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c13.jpg" alt="cert13"></img><figcaption>{i18n.language==='kr'?"":"Public Procurement Service G-PASS certificate TOVNET Solar Came"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c14.jpg" alt="cert14"></img><figcaption>{i18n.language==='kr'?"":"TOVNET GS level 1 certificate"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c15.jpg" alt="cert15"></img><figcaption>{i18n.language==='kr'?"":"TOVNET New Technology (NET) Certificate 2"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c16.jpg" alt="cert16"></img><figcaption>{i18n.language==='kr'?"":"TOVNET light bulb CCTV performance certificate"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c17.jpg" alt="cert17"></img><figcaption>{i18n.language==='kr'?"":"Innovative product designation certificate"}</figcaption></figure>
            

            <figure style={{width:'min-content'}}>   <img style={{width:'250px', height:'360px'}} src="/images/cert/c18.jpg" alt="cert18"></img><figcaption>{i18n.language==='kr'?"":"Ministry of Environment innovative product - Solar Cam 2"}</figcaption></figure>

            

           
          </Grid>

        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Grid container spacing={4} sx={{textAlign:'center'}}>
          <figure style={{width:'min-content'}}>    <img src="/images/license/l1.jpeg" alt="license1"></img><figcaption>{i18n.language==='kr'?"":"Wirless DoorBell"}</figcaption></figure>
          <figure style={{width:'min-content'}}><img src="/images/license/l2.jpeg" alt="license2"></img><figcaption>{i18n.language==='kr'?"":"Detachable Antenna"}</figcaption></figure>
          <figure style={{width:'min-content'}}><img src="/images/license/l3.jpeg" alt="license3"></img><figcaption>{i18n.language==='kr'?"":"Long Distance Wireless"}</figcaption></figure>
          <figure style={{width:'min-content'}}><img src="/images/license/l4.jpeg" alt="license4"></img><figcaption>{i18n.language==='kr'?"":"Surveillance System"}</figcaption></figure>
          <figure style={{width:'min-content'}}>    <img src="/images/license/l5.jpeg" alt="license5"></img><figcaption>{i18n.language==='kr'?"":"TOVNET Solar"}</figcaption></figure>
          <figure style={{width:'min-content'}}>  <img src="/images/license/l6.jpeg" alt="license6"></img><figcaption>{i18n.language==='kr'?"":"TOVNET Solar"}</figcaption></figure>
          <figure style={{width:'min-content'}}> <img src="/images/license/l7.jpeg" alt="license7"></img><figcaption>{i18n.language==='kr'?"":"Performance certificate"}</figcaption></figure>
          <figure style={{width:'min-content'}}>   <img src="/images/license/l8.jpeg" alt="license8"></img><figcaption>{i18n.language==='kr'?"":"Software quality certificate"}</figcaption></figure>
          <figure style={{width:'min-content'}}>      <img src="/images/license/l9.jpeg" alt="license9"></img><figcaption>{i18n.language==='kr'?"":"Thermal imaging camera KC"}</figcaption></figure>
          <figure style={{width:'min-content'}}>      <img src="/images/license/l10.jpg" alt="license10" style={{width:'250px', height:'360px', border:'1px solid #f1f1f1'}}></img><figcaption>{i18n.language==='kr'?"":"TOVNET US Patent 147LG"}</figcaption></figure>
          <figure style={{width:'min-content'}}>      <img src="/images/license/l11.jpg" alt="license11" style={{width:'250px', height:'360px', border:'1px solid #f1f1f1'}}></img><figcaption>{i18n.language==='kr'?"":"TOVNET US Patent"}</figcaption></figure>

          
           


          </Grid>

        </CustomTabPanel>
        </Box>
        </div>
    )
  }

function HistoryTabs() {
    const [value, setValue] = React.useState(8);
    const {i18n} = useTranslation();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
        
        <ThemeProvider theme={customTheme}>
            
      <Box sx={{overflow:'hidden', flexGrow:1}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="history-tabs" sx={{'.MuiTabs-scroller':{ overflow:"scroll !important"},'.MuiTabs-indicator': {background: "#AE2424"}, '.Mui-selected': {color: "#000000 !important"}, '.MuiTab-root': {fontWeight: 'bold', fontSize:{xs:'medium', lg:'xxx-large'}}, ".MuiTabs-flexContainer": {gap:'5%'}}}>
          <Tab label="2012" {...a11yProps(0)}/>
            <Tab label="2013" {...a11yProps(1)}/>
            <Tab label="2014" {...a11yProps(2)} />
            <Tab label="2015" {...a11yProps(3)} />
            <Tab label="2016" {...a11yProps(4)}/>
            <Tab label="2017" {...a11yProps(5)}/>
          
          <Tab label="2018" {...a11yProps(6)}/>
            <Tab label="2019" {...a11yProps(7)}/>
            <Tab label="2020" {...a11yProps(8)} />
            <Tab label="2021" {...a11yProps(9)} />
            <Tab label="2022" {...a11yProps(10)} />
            <Tab label="2023" {...a11yProps(11)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={4}>
            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>11</Box>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"(주) 토브넷 설립":"Established Tovnet Co., Ltd."}</Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"벤쳐 기업 인증":"Venture business certification"}</Typography>
          
              </Grid>
              </Box>
            </Grid>

          </Grid>

        </CustomTabPanel>


        <CustomTabPanel value={value} index={1}>
        <Grid container spacing={8}>
            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>01</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"(주) 위버스마인드에 어학용 테블릿 PC 개발 / 공급 계약":"Development and supply contract for language learning tablets with WeaversMind Ltd"}</Typography>
              </Grid>
              </Box>
            </Grid>

        

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>06</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"일본 TOK사에 방폭시스템 구축 컨설팅 계약":" Consulting contract for the establishment of explosion-proof systems with TOK Corporation in Japan"}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>08</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"ISO 9001 , ISO 14001 인증":"ISO 9001 , ISO 14001 Certification "}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>09</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"식약청 의료기기 품폭(09-202호) 및 제조업(4277호) 허가":"Received approval from the Ministry of Food and Drug Safety for medical device manufacturing (No. 09-202) and manufacturing industry (No. 4277)"}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"가톨릭대학교 생명공학과, 전북대학병원과 업무제휴":"Business partnership with the Department of Biotechnology at Catholic University and Jeonbuk University Hospital"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"일본 TOK사에 방폭시스템 공급":"Supply of explosion-proof systems to TOK Corporation in Japan"}</Typography>
              </Grid>
              </Box>
            </Grid>

            
            
          </Grid>
          <img src={history13} style={{marginTop:'2vh'}} alt="history1"></img>

        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Grid container spacing={8}>
            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>04</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"가톨릭대학교 LINC 사업단과 업무제휴":"Business Partnership with Catholic University LINC Business Division "}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>05</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"순천향 대학교와 업무 제휴":"Business Partnership with Soonchunhyang University"}</Typography>
          
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>06</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"순천향 대학교와 ICT 힐링 플랫폼 수주 (미래 창조 과학부)":"Soonchunhyang University and ICT Healing Platform Contract Award (Ministry of Science and ICT)"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"순천향 대학교 LINC 사업단과 모바일 자동제어 시스템 개발 협약":"Soonchunhyang University LINC Business Division and Mobile Automatic Control System Development Agreement"}</Typography>

              
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>08</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"서강대학교 가족기업 협약":"Sogang University Family Business Agreement"}</Typography>
              </Grid>
              </Box>
            </Grid>
            
            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>10</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"토브넷캠 : 무선 LED 전구 CCTV 제품 KC 인증":"Tovnetcam: Wireless LED bulb CCTV product KC certificatio"}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"토브넷캠 : 무선 LED 전구 CCTV 서비스 시스템 국가 신기술(NET) 인증":"TOVNET Cam: Wireless LED Bulb CCTV Service System National New Technology (NET) Certification"}</Typography>
              </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
          <Grid item xs={6} lg={2}>
        <img src={history14_1} style={{marginTop:'2vh'}} alt="history2"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history14_2} style={{marginTop:'2vh'}} alt="history3"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history14_3} style={{marginTop:'2vh'}} alt="history4"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history14_4} style={{marginTop:'2vh'}} alt="history5"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history14_5} style={{marginTop:'2vh'}} alt="history6"></img>

        </Grid>

          </Grid>
        
        </CustomTabPanel>


        <CustomTabPanel value={value} index={3}>
        <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'},height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>01</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"(주)토브넷 공장 등록":"Registration of TOVNET Ltd Factory"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'국가신기술(NET) 적용 제품 인증 “무선 LED전구 카메라”':'National Emerging Technology (NET) Product Certification for "Wireless LED Bulb Camera"'}</Typography>

              </Grid>
              </Box>
            </Grid>

        

            <Grid item xs={12} md={6}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>07</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'“무선 LED 전구 CCTV” 특허청 디자인 등록, 전기안전인증':'Design Registration with Patent Office and Electrical Safety Certification for “Wireless LED Bulb CCTV"'}</Typography>
              </Grid>
              </Box>
            </Grid>


            <Grid item xs={12} md={6}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>08</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'“무선 LED 전구 CCTV” 국제특허(PCT) 출원':'International Patent (PCT) Application for “Wireless LED Bulb CCTV”'}</Typography>
              </Grid>
              </Box>
            </Grid>


            <Grid item xs={12} md={6}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>11</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'삼성 SDS 빅데이타 무선LED전구CCTV 개발 계약':'Samsung SDS Big Data Wireless LED Bulb CCTV Development Contract'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'대만 NCC 인증 등록':' Taiwan NCC Certification Registration'}</Typography>

              </Grid>
              </Box>
            </Grid>
            
          </Grid>
          <Grid container>
          <Grid item xs={6} lg={2}>
          <img src={history15_1} style={{marginTop:'2vh'}} alt="history7"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history15_2} style={{marginTop:'2vh'}} alt="history8"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history15_3} style={{marginTop:'2vh'}} alt="history9"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history15_4} style={{marginTop:'2vh'}} alt="history10"></img>

        </Grid>
      

          </Grid>



        </CustomTabPanel>

        <CustomTabPanel value={value} index={6}>
          <Grid container spacing={8}>



            <Grid item xs={12} md={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'},height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>07</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'공장 등록':'Factory Registration'}</Typography>

              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'Kickstarter 펀딩':'Kickstarter Crowdfunding'}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>08</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'직접생산확인증명':'Direct Production Confirmation'}</Typography>

              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'토브펫: 중소기업진흥공단 2018년도 HIT 500 제품 선정':'TOV Pet: Selected as one of the HIT 500 Products by the Small and Medium Business Administration in 2018'}</Typography>
          
              </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>09</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'Indiegogo 펀딩':'Indiegogo Crowdfunding'}</Typography>
              
              </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>10</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'조달공급업체등록':'Procurement Supplier Registration'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'벤처나라등록':'Venture Business Registration'}</Typography>

              </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'산업통상자원부장관 표창':'Minister of Trade, Industry, and Energy Commendation'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'으뜸 중기상 수상':'Excellence in Small and Medium-Sized Business Award'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'중소벤처기업부 장관 표창':'Commendation from the Minister of the Small and Medium Venture Business Department'}</Typography>

              </Grid>
              </Box>
            </Grid>
            
          </Grid>
          <img src={history18} style={{marginTop:'2vh'}} alt="history11"></img>


        </CustomTabPanel>


        <CustomTabPanel value={value} index={4}>
        <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'},height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>07</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'미국디자인 특허 등록':'Registration of U.S. Design Patent'}</Typography>
              </Grid>
              </Box>
            </Grid>

        

            <Grid item xs={12} md={6}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>08</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'토브넷캠:중소기업진흥공단 2016년도 HIT 500 제품 선정':'TOVNET Cam: Selected as one of the HIT 500 Products by the Small and Medium Business Administration in 2016'}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1',marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>10</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'무선LED전구카메라 EMC 인증':'EMC Certification for Wireless LED Bulb Camera'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'태국 SCS 인증 등록':'Registration of Thailand SCS Certification'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'일본 Telec 인증 등록':'Registration of Japan Telec Certification'}</Typography>

              </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'중소기업청 으뜸 상품 선정':'Nominated as a Leading Product by the Small and Medium Business Administration'}</Typography>
          
              </Grid>
              </Box>
            </Grid>
            
          </Grid>
          <Grid container>
          <Grid item xs={6} lg={2}>
          <img src={history16_1} style={{marginTop:'2vh'}} alt="history12"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history16_2} style={{marginTop:'2vh'}} alt="history13"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history16_3} style={{marginTop:'2vh'}} alt="history14"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history16_4} style={{marginTop:'2vh'}} alt="history15"></img>

        </Grid>
      

          </Grid>

        



        </CustomTabPanel>


        <CustomTabPanel value={value} index={5}>
          <Grid container spacing={8}>
            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>03</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'행동분석 관리시스템 특허 등록':'Behavioral Analysis Management System Patent Registration'}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'화재 감시시스템 특허 등록':'Fire Monitoring System Patent Registration'}</Typography>
          
              </Grid>
              </Box>
            </Grid>

            
          </Grid>

        </CustomTabPanel>


        <CustomTabPanel value={value} index={7}>
        <Grid container spacing={8}>
            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'},height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>05</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'애완동물용 자동급식기 디자인 등록':'Design Registration for Pet Automatic Feeder'}</Typography>
              </Grid>
              </Box>
            </Grid>

        

            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'중소벤처기업부장관 표창':'Commendation by the Minister of Small and Medium-sized Enterprises and Startups'}</Typography>
              </Grid>
              </Box>
            </Grid>
            
          </Grid>
          <img src={history19} style={{marginTop:'2vh'}} alt="history16"></img>

        </CustomTabPanel>



        <CustomTabPanel value={value} index={8}>
        <Grid container spacing={8}>
            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1',marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>01</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'모니터 디자인 등록 감시용 카메라 디자인 등록':'Monitor Design Registration and Surveillance Camera Design Registration'}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>02</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'이동형 CCTV 원격 감시 시스템 특허 등록':'Patent Registration for Portable CCTV Remote Surveillance System'}</Typography>
          
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>03</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'ISO14001 환경경영시스템인증':'ISO14001 Environmental Management System Certification'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'ISO 9001 품질경영시스템 인증':'ISO 9001 Quality Management System Certification'}</Typography>

              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'서울어워드 우수아이디어 수상':'Seoul Award for Excellence in Ideas'}</Typography>
              
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>04</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'조달청 벤처창업혁신조달청상품 지정':'Designation of Procurement Service Innovation for Venture Startups by the Public Procurement Service '}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>05</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'서울산업진흥원 서울어워드 - 태양광카메라':'Seoul Industrial Promotion Foundation Seoul Award - Solar Camera'}</Typography>
              </Grid>
              </Box>
            </Grid>


            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>06</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'서울어워드 우수상품 수상':'Seoul Award for Excellence in Product'}</Typography>
              </Grid>
              </Box>
            </Grid>


            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>08</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'중소기업벤처부  기술개발시범제품지정':'Small and Medium Business Venture Department Technology Development Pilot Product Designation'}</Typography>
              
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>09</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'성능인증 - 태양광카메라':'Performance Certification - Solar Camera'}</Typography>
              
              </Grid>
              </Box>
            </Grid>

            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>10</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'태양광 무선 CCTV 성능인증':'Performance Certification for Solar-Powered Wireless CCTV'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?'Good Software 1등급 인증 - 태양광 카메라':'Good Software Grade A Certification - Solar Camera'}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"에너지 독립형 태양관 무선 CCTV 중소벤처기업 성능인증":"Performance Certification for Solar-Powered, Standalone Wireless CCTV by Small and Medium-sized Enterprises (SMEs)"}</Typography>
              </Grid>
              </Box>
            </Grid>


            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>11</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"(사)장애인동반성장협회 제휴":"Partnership with the Association for Inclusive Growth of Persons with Disabilities"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"과학기술정보통신부 신S/W 장관장- 태양광카메라":"Minister's Award from the Ministry of Science and ICT - Solar Camera"}</Typography>

              </Grid>
              </Box>
            </Grid>


            <Grid item sm={12} md={6} lg={4}>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"국무총리 소프트웨어 대상- 태양광카메라":"Prime Minister's Software Award - Solar Camera"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"산업통상자원부 장관 표창":"Commendation by the Minister of Trade, Industry, and Energy"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"으뜸중기상 수상":"Excellence in Small and Medium-Sized Business Award"}</Typography>

              </Grid>
              </Box>
            </Grid>
            
          </Grid>

          <Grid container>
          <Grid item xs={6} lg={2}>
          <img src={history20_1} style={{marginTop:'2vh'}} alt="history17"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history20_2} style={{marginTop:'2vh'}} alt="history18"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history20_3} style={{marginTop:'2vh'}} alt="history19"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history20_4} style={{marginTop:'2vh'}} alt="history20"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history20_5} style={{marginTop:'2vh'}} alt="history21"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history20_6} style={{marginTop:'2vh'}} alt="history22"></img>

        </Grid>

          </Grid>




        </CustomTabPanel>

        <CustomTabPanel value={value} index={9}>
        <Grid container spacing={8}>
            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'},height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>01</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"조달청 혁신시제품 지정 – 화재예방 열화상카메라":'“Procurement Office Innovation Product Designation - Fire Prevention Thermal Camera'}</Typography>
              </Grid>
              </Box>
            </Grid>

        

            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>04</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"국방 우수상용품 지정":"Designation as Excellent Defense Commodity"}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>09</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"조달청 G-Pass 지정 – 무선 태양광 카메라":"Procurement Office G-Pass Designation - Wireless Solar Camera"}</Typography>

              </Grid>
              </Box>
            </Grid>
            
          </Grid>
          <Grid container>
          <Grid item xs={6} lg={2}>
          <img src={history21_1} style={{marginTop:'2vh'}} alt="history23"></img>

        </Grid>
        <Grid item xs={6} lg={2}>
        <img src={history21_2} style={{marginTop:'2vh'}} alt="history24"></img>

        </Grid>
       

          </Grid>


        </CustomTabPanel>

        <CustomTabPanel value={value} index={10}>
        <Grid container spacing={8}>
            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'},height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>09</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"조달청 G-Pass 지정 – 무선 태양광 카메라":"Procurement Office G-Pass Designation - Wireless Solar Camera"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"국방부 적합제품 지정 – 화재예방 열화상카메라":"Ministry of National Defense Suitable Product Designation - Fire Prevention Thermal Camera"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"국방부 우수상용품 지정 – 4G 태양광 카메라":"Ministry of National Defense Excellent Commercial Product Designation - 4G Solar Camera"}</Typography>
              </Grid>
              </Box>
            </Grid>

        

            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>11</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"환국환경기술원장상 – 최우수기업":"Presidential Award from Hwankook Environmental Technology Institute - Best Company"}</Typography>
              </Grid>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'}, height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>12</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"백만불 수출탑 수상":"Million Dollar Export Tower Award"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"생산성경영체계(PMS) 인증":"Productivity Management System (PMS) Certification"}</Typography>


              </Grid>
              </Box>
            </Grid>
            
          </Grid>
         



        </CustomTabPanel>


        <CustomTabPanel value={value} index={11}>
        <Grid container spacing={8}>
            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'},height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>04</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"과학기술정보통신부 장관 표창":"Commendation from the Minister of the Ministry of Science and ICT"}</Typography>
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"글로벌강소기업 1000+ 선정":"Selected as a Global Strong Company with 1000+ Employees"}</Typography>
              </Grid>
              </Box>
              
            </Grid>
            <Grid item>
              <Box sx={{display:'flex',alignItems:'center', gap:'1vw'}}>
              <Box sx={{backgroundColor:'red', alignSelf:'flex-start', borderRadius:'50%', aspectRatio:'1', marginRight:{xs:'15px', md:'0'},height:{xs:'50px',lg:'80px'}, width:{xs:'50px', lg:'80px'}, textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>11</Box>
              <Grid >
              <Typography sx={{typography:{xs:'body1', lg:'h6'}}}>{i18n.language === 'kr'?"산업통상자원부 장관 표창":"Ministry of Trade, Industry, and Energy Minister's Commendation"}</Typography>
              </Grid>
              </Box>
              
            </Grid>
        

       
           
            
          </Grid>
         



        </CustomTabPanel>



      </Box>
      
      </ThemeProvider>
      
    );
  }

function HistorySection() {
  const historyItem = {
    year: ['2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012'],
    items: [
    { year:'2023',
      content:
      [{ 
        month: '11',
        info: [['산업통상자원부 장관 표창', "Ministry of Trade, Industry, and Energy Minister's Commendation"]],
      },
      {
        month: '04',
        info: [['과학기술정보통신부 장관 표창', "Ministry of Sciecne, Technology, and Information Communication Minister's Commendation"], ["글로벌강소기업 1000+ 선정", "Selected as a Global Leading Company 1000+"]]

      }
    ],
    img_kr: [history20_1],
    img_en: [history20_1_eng]
    }, 
   { year: '2022',
   content: [
    {
      month: '12',
      info: [['백만불 수출탑 수상','Million-Dollar Export Tower Award'],['생산성경영체계(PMS) 인증', 'Productivity Management System (PMS) Certification']]
  },
  {
    month: '11',
    info: [['한국환경기술원장상 - 최우수 기업', 'Grand Prize from the Korea Environmental Industry & Technology Institute - Grand Prize']]
  },
  {
    month: '09',
    info: [['국방부 우수상용품 지정 - 4G 태양광 카메라', 'Outstanding Commercial Products Designation by the Ministry of National Defense - 4G Solar Camera'], ['국방부 적합제품 지정 - 화재예방 열화상카메라', 'Designation of Suitable Products by the Ministry of National Defense - Fire Prevention Thermal Imaging Camera']],

  }
   ],
   img_kr: [history21_1],
   img_en: [history21_1]
  }, 
    {year: '2021',
    content: [
      {
        month: '12',
        info: [['환경부 혁신시제품 지정 - 무선 태양광 카메라', 'Innovative Product Designation by the Ministry of Environment - Wireless Solar Camera']]
      },
      {
        month: '09',
        info: [['조달청 G-Pass 지정 – 무선 태양광 카메라', 'G-Pass Designation by the Public Procurement Service - Wireless Solar Camera']],
      },
      {
        month: '01',
        info: [['조달청 혁신시제품 지정 – 화재예방 열화상카메라', 'Innovative Product Designation by the Public Procurement Service - Fire Prevention Thermal Imaging Camera']]
      }
    ],
    img_kr: [history21_1, history21_2],
    img_en: [history21_1, history21_2]
  }, 
    {year: '2020',
    content: [
      {
        month: '12',
        info: [['산업통상자원부 장관 표창', "Ministry of Trade, Industry, and Energy Minister's Commendation"], ['국무총리 소프트웨어 대상- 태양광카메라', "Prime Minister's Software Award - Solar Camera"]],
      },
      {
        month: '11',
        info: [['과학기술정보통신부 신S/W 장관장- 태양광카메라', 'Ministry of Science, Technology, and Information Communication New S/W Director General - Solar Camera']],
      },
      {
        month: '10',
        info: [['Good Software 1등급 인증 - 태양광카메라', 'Good Software Grade 1 Certification - Solar Camera']], 
      },
      {
        month: '09',
        info: [['성능인증 - 태양광카메라', 'Performance Certification - Solar Camera']], 
      },
      {
        month: '08',
        info:[['중소기업벤처부 기술개발시범제품지정', 'Designation as a Pilot Product for Technology Development by the Ministry of SMEs and Startups']],
      },
      {
        month: '05',
        info: [['서울산업진흥원 서울어워드 - 태양광카메라', 'Seoul Business Agency Seoul Award - Solar Camera']],
      },
      {
        month: '03',
        info: [['ISO 9001 품질경영시스템 인증', 'ISO 9001 Quality Management System Certification'], ['ISO14001 환경경영시스템 인증', 'ISO 14001 Environmental Management System Certification']]
      }
    ],
    img_kr: [history20_1, history20_2, history20_3, history20_4, history20_5, history20_6],
    img_en: [history20_1_eng, history20_2_eng, history19_eng, history20_4_eng]

  }, 
    {year: '2019',
    content: [
      {
        month: '12',
        info:[['중소벤처기업부 장관 표창', "Minister's Commendation from the Ministry of SMEs and Startups"]]
      }
    ],
    img_kr: [history19],
    img_en: [history19_eng]

  }, 
    {year: '2018',
    content: [
      {
        month: '12',
        info: [['산업통상자원부 장관 표창', "Ministry of Trade, Industry, and Energy Minister's Commendation"]],
      },
      {
        month: '10',
        info: [['벤처나라등록', 'Venture Company Registration'], ['조달공급업체등록', 'Procurement Supplier Registration']],
      },
      {
        month: '08',
        info: [['직접생산확인증명', 'Direct Production Verification']]
      },
      {
        month: '07',
        info: [['공장 등록', 'Factory Registration']]
      }
    ] ,
    img_kr: [history18],
    img_en: [history20_1_eng]
  }, 
   { year: '2017',
   content : [
    {
      month: '12',
      info: [['화재 감시시스템 특허 등록', 'Patent Registration for Fire Monitoring System']]
    },
    {
      month: '03',
      info: [['행동분석 관리시스템 특허 등록', 'Patent Registration for Behavior Analysis Management System']]
    }
   ]
  }, 
    {year: '2016',
    content: [
      {
        month: '12',
        info: [['중소기업청 으뜸 상품 선정', 'Selected as an Excellent Product by the Small and Medium Business Administration']],
      },
      {
        month: '10',
        info: [['일본 Telec 인증 등록', 'Registered for Telec Certification in Japan'], ['태국SCS인증등록', 'Registered for SCS Certification in Thailand'], ['무선LED전구카메라 EMC 인증', 'Wireless LED Bulb Camera EMC Certification']],
      },
      {
        month: '08',
        info: [['토브넷캠:중소기업진흥공단 2016년도 HIT 500 제품 선정', 'TOVNETCAM: Selected as one of the 2016 HIT 500 Products by the Small and Medium Business Promotion Agency']],
      },
      {
        month: '07',
        info: [['미국디자인 특허 등록', 'Registered a Design Patent in the United States']]
      }
    ] ,
    img_kr: [history16_1, history16_2, history16_3, history16_4],
    img_en: [history16_1, history16_2, history16_4]

  }, 
    {year: '2015',
    content: [
      {
        month: '11',
        info: [['대만NCC인증등록', 'Registered for NCC Certification in Taiwan'], ['삼성 SDS 빅데이터 무선LED전구CCTV 개발 계약', 'Development Contract with Samsung SDS for Big Data Wireless LED Bulb CCTV']],
      },
      {
        month: '08',
        info: [['“무선 LED 전구 CCTV” 국제특허(PCT) 출원', 'Filed an International Patent (PCT) for "Wireless LED Bulb CCTV"']],
      },
      {
        month: '07',
        info: [['“무선 LED 전구 CCTV” 특허청 디자인 등록, 전기안전인증', 'Registered a Design Patent for "Wireless LED Bulb CCTV" with Electrical Safety Certification']],
      },
      {
        month: '01',
        info: [['국가신기술(NET) 적용 제품 인증', 'Certification for the Application of National Emerging Technology (NET) Products'], ['(주)토브넷 공장 등록', 'TOVNET Company Factory Registration']]
      }
    ],
    img_kr: [history15_1, history15_2, history15_3, history15_4],
    img_en: [history15_1_eng, history15_2, history15_3, history15_4]

  }, 
    {year: '2014',
    content: [
      {
        month: '12',
        info: [['토브넷캠 : 신기술(NET) 인증', 'TOVNETCAM: NET (New Excellent Technology) Certification']],
      },
      {
        month: '10',
        info: [['토브넷캠:무선LED전구CCTV제품KC인증', 'TOVNETCAM: KC Certification for Wireless LED Bulb CCTV Product']],
      },
      {
        month: '08',
        info: [['서강대학교 가족기업 협약', 'Agreement with Sogang University for Family Business']],
      },
      {
        month: '06',
        info: [['순천향 대학교 모바일 자동제어 시스템 개발 협약', 'Signed an Agreement for the Development of Mobile Automatic Control System with Soonchunhyang University'], ['순천향 대학교와 ICT 힐링 플랫폼 수주 (미래 창조 과학부)', 'Received an Order for an ICT Healing Platform with Soonchunhyang University (Ministry of Science, ICT, and Future Planning)']],
      },
      {
        month: '05',
        info: [['순천향 대학교와 업무 제휴', 'Collaboration with Soonchunhyang University']],
      },
      {
        month: '04',
        info: [['가톨릭대학교 LINC 사업단과 업무제휴', 'Collaboration with the Catholic University LINC Business Division']]
      }
    ],
    img_kr: [history14_1, history14_2, history14_3, history14_4, history14_5],
    img_en: [history14_1, history14_2, history14_3, history14_4, history14_5]

  }, 
    {year: '2013',
    content: [
      {
        month: '12',
        info: [['일본 TOK사에 방폭시스템 공급', ' Supply of Explosion-Proof Systems to TOK Corporation in Japan'], ['가톨릭대학교 생명공학과, 전북대학병원과 업무제휴', 'Collaboration with the Department of Biotechnology at Catholic University and Jeonbuk University Hospital']],
      },
      {
        month: '09',
        info: [['식약청 의료기기 등록(09-202호) 및 제조업(4277호)', 'Registered as a Medical Device Manufacturer (No. 09-202) and a Pharmaceutical Manufacturer (No. 4277) with the Korea Food and Drug Administration']],
      }, 
      {
        month: '08',
        info: [['ISO 9001 , ISO 14001 인증', 'ISO 9001 and ISO 14001 Certification']],
      },
      {
        month: '06',
        info: [['일본 TOK사에 방폭시스템 구축 컨설팅 계약', 'Consulting Contract for Explosion-Proof Systems with TOK Corporation in Japan']],
      },
      {
        month: '01',
        info: [['(주) 위버스마인드에 어학용 테블릿 PC 개발 / 공급 계약', 'Development/Supply Contract for Language Learning Tablet PC with Webersmind Co., Ltd.']]
      }
    ],
    img_kr: [history13],
    img_en: [history13]

  }, 
    {year: '2012',
    content: [
      {
        month: '12',
        info: [['벤쳐기업인증', 'Certification as a Venture Enterprise']],
      },
      {
        month: '11',
        info:  [['(주)토브넷 설립', 'Establishment of TOVNET Co., Ltd.']]
      }
    ]}
  ]
    
  };
  const {i18n, t} = useTranslation();
  const [active, setActive] = React.useState(false);
  const showMore = () => {
    setActive((prev)=> (!prev))
  }

  return (
    <div className="history-section">
      <div className='history-circle'>
        <Typography className="history-circle-text" sx={{fontWeight:'bold'}}>Now</Typography>
      </div>
      
      <ul style={{margin:'0'}}>
        <div className="history-line"/>
        {historyItem.items.map((ele, index) => (
          <div key={`history-item-${index}`} className={active?"history-section-active history-item":"history-item"}>
            

          
            <li  key={`history-list-${index}`} className={`history-list-left`}>
            <Typography variant="h4" className='h4'>{ele.year}</Typography>
              {ele.content.map((contentItem, itemIdx) => (
                <Typography key={`contentItem-${index}-${itemIdx}`} className='p' variant='h6'>
                  <span key={`contentItem-span-${index}-${itemIdx}`}>{contentItem.month}</span>
                  {contentItem.info.map((infoEle, eleIdx)=> (
                    <div key={`infoele-${index}-${eleIdx}`}>
                    {i18n.language ==='kr'? infoEle[0]:infoEle[1]}
                    <br/>
                    </div>
                  ))}
                </Typography>
              ))}
              
          </li>
          <li  key={`history-list-${index}-right`} className={`history-list-right`}>
              {i18n.language === 'kr'?
              ele.img_kr?.map((image, imgIdx) => (
                  <img key={`history-img-kr-${index}-${imgIdx}`} src={image} alt={`historyimage${index}`}></img>

              )):
              ele.img_en?.map((image, imgIdx) => (
                <img key={`history-img-en-${index}-${imgIdx}`} src={image} alt={`historyimage${index}`}></img>

            ))
              }
              
              
          </li>
          </div>
       
          )
        )}
      </ul>
      <div style={{width:'100%', height:'3em', backgroundColor:'white', position:'relative', zIndex:'1'}}></div>
      <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
      <Button onClick={showMore} sx={{ color: 'white', boxShadow:1, backgroundColor:'red', fontWeight:'bold', "&:hover": {backgroundColor:'white', color:'red'}, transition:'background-color 0.2s ease-in-out, color 0.2s ease-in-out'}}>{active?t('history_less'):t("history_more")}{active?<KeyboardArrowDUp/>:<KeyboardArrowDown/>}</Button>

      </div>
    </div>  
  )
}



function AboutUs() {
  const {t} = useTranslation();
  


  const triggerHistory = useScrollTrigger({
    disableHysteresis: true,
    threshold: 900,
  });

  const triggerCulture = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1200
  })

  const triggerCert = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1500
  })

  const [playAnimation, setPlayAnimation] = React.useState(true);
  const [isMobile, setIsMobile] = React.useState(false);

  const mobile = () => {
    if (window.innerWidth < 1000) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }


    

      document.body.style.overflow = 'scroll'

      React.useEffect(() => {
        mobile();
        const onPageLoad = () => {
          setTimeout(()=> {
            setPlayAnimation(false)}, 1000)
          
        }
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad, false);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
        }

       
      }, []);



    return (
            <div id='aboutus'>

{playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

            <GeneralBanner label={t('aboutus')} preset={'aboutus'}/>

            
            <Box sx={{marginTop: '5vh', boxShadow: '0 15px 18px #eeeeee'}}>
             {/* Ceo's greeting */}

                
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'auto',  padding: {sm:"0 10%", xs:"0 10%", md:"0 10%", lg:'0 15%'}, gap:'5%'}}>

                    <Box sx={{backgroundColor: 'backgroundGrey', display:'flex', flexDirection: {xs:'column', sm:'column', md:'column', lg:'row'},  width:'100%', padding:{xs:'10%', lg:'5%'}, gap:'3vw',boxShadow:10, aspectRatio:'3'}}>
                        <Box display='flex' flexDirection='column' gap='5%' alignContent='center' alignItems='center' height='inherit' justifyContent='center'>
                            <Avatar src={ceoImage} sx={{width:{xs:'50vw', lg:'20vw'}, height:{xs:'50vw', lg:'20vw'}, mb:{xs:'5%', lg:'0'}}}/>
                            <Box sx={{display:'flex', flexDirection:'column'}}>
                            <Typography alignSelf='center' variant="h4" sx={{fontWeight: 'bold', textAlign:'center'}}>{t('ceo_name')}</Typography>
                            <Typography alignSelf='center' sx={{typography:{xs:'body2', md:'h5'}}}>{t('ceo_position')}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}> 
                          <Typography alignSelf='left' sx={{typography:{xs:'body2', md:'h6'}, color:'darkGrey', my:'auto', }}>{t('ceo_message1')}</Typography>
                          <Typography alignSelf='left' sx={{typography:{xs:'body2', md:'h6'}, color:'darkGrey', my:'auto', }}>{t('ceo_message2')}</Typography>
                          <Typography alignSelf='left' sx={{typography:{xs:'body2', md:'h6'}, color:'darkGrey', my:'auto', }}>{t('ceo_message3')}</Typography>
                          <Typography alignSelf='left' sx={{typography:{xs:'body2', md:'h6'}, color:'darkGrey', my:'auto', }}>{t('ceo_message4')}</Typography>
                        </Box>
                    </Box>
                   
                </Box>

                {/* History Section */}
                 <Fade in={triggerHistory}>
                <Box sx={{flexDirection:'column', display:'flex', padding: '0 10%',  my:'10vh'}}>
                    <Typography variant='h3' color='red' sx={{flex:'0 0 25%', fontWeight: 'bold',  marginBottom: {xs:'5vh', lg:'0'}, textAlign:'center'}}>{t('history')}</Typography>
                   {isMobile? <HistoryTabs/>:<HistorySection/>}

                   
                </Box>
                   </Fade>

                {/* Culture */}
                <Box sx={{backgroundColor:'backgroundGrey'}}>
                <Box sx={{flexDirection:{xs:'column', lg:'row'}, display:'flex', padding: '10vh 10% 10vh 10%'}}>
                    <Typography variant='h3' color='red' sx={{flex:'0 0 25%', fontWeight:'bold', marginBottom: {xs:'5vh', md:'0'}, textAlign:{xs:'center', md:'left'}}}>{t("aboutus_culture")}</Typography>
                    <Grid container spacing={3} sx={{minHeight: 'inherit', justifyContent:'space-evenly', display:'flex', marginTop:{md:'5vh', lg:'0'},}}>
                      <Zoom in={triggerCulture}>
                        <Grid item xs={12} sm={6}  display='flex' flexDirection='row' gap='5%'>
                            <TipsAndUpdatesIcon sx={{color:'red', fontSize:{xs:'1.5rem', md:'2rem', lg:'2.5rem'}}}/>
                            <Box sx={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
                            <Typography sx={{fontWeight:'bold !important', mb:'2vh', typography:{xs:'h6', lg:'h5'}}}>{t('culture1')}</Typography>
                            <Typography sx={{color:'darkGrey', typography:{xs:'body1', lg:'h6'}}}>{t('culture2')}</Typography>
                            </Box>
                            
                        </Grid>
                        </Zoom>
                        <Zoom in={triggerCulture} style={{ transitionDelay: triggerCulture ? '200ms' : '0ms' }}>
                        <Grid item xs={12} sm={6} display='flex' flexDirection='row' gap='5%'>
                            <VolunteerActivismIcon sx={{color:'red', fontSize:{xs:'1.5rem', md:'2rem', lg:'2.5rem'}}}/>
                            <Box sx={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
                            <Typography sx={{fontWeight:'bold !important', mb:'2vh', typography:{xs:'h6', lg:'h5'}}}>{t('culture3')}</Typography>
                            <Typography sx={{color:'darkGrey', typography:{xs:'body1', lg:'h6'}}}>{t('culture4')}</Typography>
                            </Box>
                            
                        </Grid>
                        </Zoom>
                        <Zoom in={triggerCulture} style={{ transitionDelay: triggerCulture ? '400ms' : '0ms' }}>
                        <Grid item xs={12} sm={6} display='flex' flexDirection='row' gap='5%'>
                            <SentimentVerySatisfiedIcon sx={{color:'red', fontSize:{xs:'1.5rem', md:'2rem', lg:'2.5rem'}}}/>
                            <Box sx={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
                            <Typography sx={{fontWeight:'bold !important', mb:'2vh', typography:{xs:'h6', lg:'h5'}}}>{t('culture5')}</Typography>
                            <Typography sx={{color:'darkGrey', typography:{xs:'body1', lg:'h6'}}}>{t('culture6')}</Typography>
                            </Box>
                            
                        </Grid>
                        </Zoom>
                        <Zoom in={triggerCulture} style={{ transitionDelay: triggerCulture ? '600ms' : '0ms' }}>

                        <Grid item xs={12} sm={6} display='flex' flexDirection='row' gap='5%'>
                            <Diversity2Icon sx={{color:'red', fontSize:{xs:'1.5rem', md:'2rem', lg:'2.5rem'}}}/>
                            <Box sx={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
                            <Typography sx={{fontWeight:'bold !important', mb:'2vh', typography:{xs:'h6', lg:'h5'}}}>{t('culture7')}</Typography>
                            <Typography sx={{color:'darkGrey', typography:{xs:'body1', lg:'h6'}}}>{t('culture8')}</Typography>
                            </Box>
                            
                        </Grid>
                        </Zoom>
                        
                        
                    </Grid>

                </Box>
                </Box>
                
                 
                <Fade in={triggerCert}>

                <Box sx={{flexDirection:{sm:'column', xs:'column', md:'column', lg:'row', xl:'row'}, display:'flex', padding: '0 10% 10vh 10%', marginTop:'10vh', height: 'auto'}}>
                    <Typography variant='h3' color='red' sx={{flex:'0 0 25%', fontWeight: 'bold',  marginBottom: {xs:'5vh', lg:'0'}, textAlign:{xs:'center', md:'left'}}}>{t('aboutus_cert')}</Typography>
                   
                    <CertificationTabs/>
                 
                   
                </Box>
                   </Fade>

                
                </Box>
                </div>

    )
}

export {AboutUs};