import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    if (message.includes('product')) {
        actions.handleProduct();
    }
    else if (message.includes('상품')) {
      actions.handleProduct();
    }
    else if (message.includes('제품')) {
      actions.handleProduct();
    }
    else if (message.includes('contact')) {
        actions.handleContact();
    }
    else if (message.includes('영업시간')) {
      actions.handleContact();
    } 
    else if (message.includes('문의')) {
      actions.handleContact();
    }
    else if (message === 'quit' || message === '돌아가기') {
      actions.handleQuit();
    }
    else {
        actions.handleGeneral();
    }
    
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;