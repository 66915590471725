import { Box, Select, MenuItem, Typography} from '@mui/material'
import GeneralBanner from '../general_section/GeneralBanner.js';
import React from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { FormLabel } from '@mui/material';
import LoadingAnimation from '../general_section/LoadingAnimation.js';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_URL } from '../../../constants.js';


function ContactUs() {

    const {t} = useTranslation();

    const [questionType, setQuestionType] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");


    const handleTypeChange = (event) => {
        setQuestionType(event.target.value)
    }


    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const [playAnimation, setPlayAnimation] = React.useState(true)
    document.body.style.overflow = 'scroll';


    const submit = (event) => {
        event.preventDefault()

        fetch(`https://${API_URL()}/email`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify({
                email: email,
                questionType: questionType,
                title: title,
                message: message
            }),
          })
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    alert(t('email_success'))
                  } else {
                    throw new Error('Request Failed.')
                  }
            })
            .catch((error) => {
                console.log(error)
                alert(t('email_unsuccess'))
            });

        
    }

    React.useEffect( () => {
       
        const onPageLoad = () => {
               setTimeout(()=> {
              setPlayAnimation(false)}, 1000)
            
          }
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }

        
      }, []);

    return (
<div id='contactus'>
{playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

            <GeneralBanner label={t('contactus')} preset={'contactus'}/>
            <Box sx={{height:{xs:'110vh', md:'90vh'}}}>
            <Box sx={{position:'absolute', backgroundColor:'#F7F8F9', top:0, width:'80vw', marginLeft:'10vw', marginTop:'35vh'}}>
            <Box sx={{padding: '5% 10%',mx:'auto'}}>
                <Box sx={{display: 'grid', direction:'column', mb:'2em'}}>
                     <Typography variant="h5" sx={{fontWeight:'bold', textAlign:'center'}}>{t("contactusInfo")}</Typography>
                <Typography sx={{ fontWeight:'bold', fontSize:'1rem', color:'red'}}>*{t("required")}</Typography>
                </Box>
               
  

                <form onSubmit={submit} method="post" action={`https://${API_URL()}/email`}>

                    <Box sx={{display:'flex', width:'100%', gap:'2%'}}>
                 <Box sx={{ display: 'flex', justifyContent:'space-between', flexDirection:'column', width:'50%'}}>

                 
                 <FormLabel sx={{color:'red', fontWeight:'bold', "&.Mui-focused": {color:'red'}}}>* {t('category')}</FormLabel>
                 <Select required id="question_type" value={questionType} onChange={handleTypeChange} sx={{"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:'red'}}}>
                    <MenuItem value="General">{t('contactus1')}</MenuItem>
                    <MenuItem value="Purchase">{t('contactus2')}</MenuItem>
                 </Select>
                 </Box>
     
                 <Box sx={{display:'flex', flexDirection:'column', width:'50%'}}>
                 <FormLabel  sx={{color:'red', fontWeight:'bold'}}>* {t('email')}</FormLabel>
                 <TextField required id="email" variant="outlined" onChange={handleEmailChange} sx={{'.MuiFormLabel-root.Mui-focused': {color: 'red'},  "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "red"}}}}/>
    
                

                 </Box>
                 </Box>
                 <Box sx={{display:'flex', justifyContent: 'center', width: '100%', flexDirection:'column'}}>
                 <Box sx={{mt:'0.5em'}}>
                 <FormLabel sx={{color:'red', fontWeight:'bold'}}>* {t("title")}</FormLabel>
                 <TextField required fullwidth="true" id="title" onChange={handleTitleChange} variant="outlined" sx={{'.MuiFormLabel-root.Mui-focused': {color: 'red'},  "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "red"}}, width:'100%', '.MuiInputBase-root': {height:'100%'}}}/>
             
                 </Box>
                 <FormLabel sx={{color:'red', fontWeight:'bold',mt:'0.5em'}}>{t("message")}</FormLabel>

                 <TextField multiline onChange={handleMessageChange} fullwidth="true" id={"content"} variant="outlined" rows={16} sx={{'.MuiFormLabel-root.Mui-focused': {color: 'red'}, "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "red"}}}}/>
 
                 </Box>
                 <Box sx={{display:'flex', justifyContent: 'center', mt:'2em'}}>
                     <Button type='submit' size='large' variant='contained' sx={{fontWeight:'bold', backgroundColor: 'red', "&:hover": {backgroundColor:'white', color:'red'}}}>{t('submit')}</Button>
                 </Box>
                 </form>

                 </Box>
 
                 
                
            
            </Box>
            </Box>

       
  


            </div>
    )
}

export {ContactUs};