import { createTheme, responsiveFontSizes } from '@mui/material/styles';


let theme = createTheme({ 
    components: {
        
        MuiCssBaseLine: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: ["Noto Sans", 'sans-serif'].join(','),
                    src: "url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;700&display=swap')"
                }
             
    }}},

typography: {
fontFamily: ['Noto Sans', 'sans-serif'].join(',')}});


theme = createTheme(theme, {
    
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {

                    fontSize: {xl: '2.5rem', lg: '2rem', md:'2rem', sm:'1rem' }





                 
                }
            }},
    },
    palette: {
        red: "#AE2424",
        white: "#FFFFFF",
        black: "#000000",
        backgroundGrey: "#F7F8F9",
        darkGrey: "#6A6465",
        lightGrey: "#9F9697",
    },
    typography: {
                    [theme.breakpoints.up('lg')]: {
                        fontSize: '1.5rem',
                      },
                    [theme.breakpoints.between('md', 'lg')]: {
                        fontSize: '1.2rem',
                    },
                    [theme.breakpoints.between('sm', 'md')]: {
                        fontSize: '0.7rem',
                    },
                    [theme.breakpoints.between('xs', 'sm')]: {
                        fontSize: '0.5rem',
                    },
                    [theme.breakpoints.down('xs')]: {
                        fontSize: '0.2rem',
                    },
                 h1: {fontSize: '3.75rem'}
   
}})


theme = responsiveFontSizes(theme);


export default theme;