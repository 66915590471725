import { AccordionDetails, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import GeneralBanner from "../general_section/GeneralBanner";
import { CustomAccordion, AccordionSummary } from "../general_section/BoardAccordion";
import React from "react";
import axios from "axios";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import LoadingAnimation from "../general_section/LoadingAnimation";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { API_URL } from "../../../constants";





function FnQ() {
  const {i18n} = useTranslation();
    const [expanded, setExpanded] = React.useState(null);
    const [fnq, setFnq] = React.useState([]);

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const [playAnimation, setPlayAnimation] = React.useState(true)
    document.body.style.overflow = 'scroll'



    React.useEffect( () => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/FAQ/actions/list?filters.language=${i18n.language}`).then((res)=>{
          setFnq(res.data.records);
        }).catch(error => {console.log(`Error: ${error}`)});

        const onPageLoad = () => {
           setTimeout(()=> {
            setPlayAnimation(false)}, 1000)
          
        }
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad, false);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
        } 

        i18next.on('languageChanged', function(lng) {
          axios.get(`https://${API_URL()}/adminAPI/api/resources/FAQ/actions/list?filters.language=${i18n.language}`  ).then((res)=>{
              setFnq(res.data.records);
              }).catch(error => {console.log(`Error: ${error}`)});

      })

        
      });



    return (
          <div id='fnq'>
            {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

            <GeneralBanner label={"FAQ"} preset={'faq'}/>
            
            <Box sx={{marginTop: '5vh',boxShadow: '0 15px 18px #eeeeee'}}>
            <div style={{padding: '0 10% 5% 10%'}}>

                {fnq.map((singleFnq, index) => (
                    <CustomAccordion key={`fnq-${index}`} expanded={expanded === singleFnq.params.id} onChange={handleChange(singleFnq.params.id)}>
                        <AccordionSummary expandIcon={expanded === singleFnq.params.id ? <RemoveIcon/>:<AddIcon sx={{color:"red"}}/>}>
                            <Typography variant='h6' sx={{fontWeight:'bold'}} color={expanded===singleFnq.params.id?"red":"black"}>
                              {singleFnq.params.title}
                          
                              </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography dangerouslySetInnerHTML={{__html: singleFnq.params.content}} sx={{color:'darkGrey', fontWeight:'bold'}}/>

                        </AccordionDetails>
                    </CustomAccordion>
                ))}

            </div>








            </Box>
            
           
            </div>
    )
}

export {FnQ};