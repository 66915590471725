import { Box,  ThemeProvider, createTheme, Grid, Typography, IconButton, Pagination, Card, CardMedia, CardContent } from "@mui/material";

import theme from "../../Theme";
import GeneralBanner from "../general_section/GeneralBanner";
import React from "react";
import axios from "axios";
import { calculatePagesCount } from "../../Functions/maxPage";
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import LoadingAnimation from "../general_section/LoadingAnimation";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { API_URL } from "../../../constants";


const defaultTheme = createTheme(theme, {
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2.5rem'
                }
            }
        }
    }
});


function Userguide() {
    const [userGuide, setUserGuide] = React.useState([]);
    const [maxPageNum, setMaxPageNum] = React.useState(1);
    const {i18n, t} = useTranslation()

    const handlePageChange = (event, newValue) => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/user_guide/actions/list?page=${newValue}&perPage=6&filters.language=${i18n.language}`).then((res)=> {
            setUserGuide(res.data.records);
        }).catch(error => {console.log(`Error: ${error}`)});
    }

    const [playAnimation, setPlayAnimation] = React.useState(true)
    document.body.style.overflow = 'scroll'



    React.useEffect( () => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/user_guide/actions/list?page=1&perPage=6&filters.language=${i18n.language}`).then((res)=>{
          setUserGuide(res.data.records);
          setMaxPageNum(calculatePagesCount(6, res.data.meta.total));
          
        }).catch(error => {console.log(`Error: ${error}`)});

        const onPageLoad = () => {
            setTimeout(()=> {
              setPlayAnimation(false)}, 1000)
            
          }
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }
          
          i18next.on('languageChanged', function(lng) {
            axios.get(`https://${API_URL()}/adminAPI/api/resources/user_guide/actions/list?page=1&perPage=6&filters.language=${i18n.language}`).then((res)=>{
                setUserGuide(res.data.records);
                setMaxPageNum(calculatePagesCount(6, res.data.meta.total));
                
              }).catch(error => {console.log(`Error: ${error}`)});
        })
        
        
      }, [i18n.language]);




    return (
        <div id='userguide'>
            <ThemeProvider theme={defaultTheme}>
            {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

            <GeneralBanner label={t('userguide')} preset={'userguide'}/>
 
            <Box sx={{marginTop: '5vh',boxShadow: '0 15px 18px #eeeeee'}}>
            <Grid container spacing={4} padding="0 10%" pb='10vh'>
        {userGuide.map((ele) => (
            <Grid item key={ele.params.id} xs={12} sm={6} md={4} lg={4} xl={3}>
            <Card>
            
            <CardMedia sx={{pt: '80%', }} image={ele.params.thumbnailFilePath}></CardMedia>
            <CardContent sx={{position:'relative', display:'flex', flexDirection:'row', backgroundColor:'#f6f6f6', padding: '5%'}}> 
                <Typography variant="h5" sx={{marginRight:'1em', fontWeight:'bold', my: 'auto'}}>
                    {ele.params.title}
                </Typography>
                <IconButton href={ele.params.filePath} style={{ backgroundColor: "white"}} sx={{marginLeft:'auto', aspectRatio:'1', borderRadius: '5px', stroke: "white", strokeWidth: 1, boxShadow:10, '.MuiIconButton-root&:hover':{backgroundColor:'#AE2424 !important', color:'white !important', stroke: '#AE2424 !important', strokeWidth: 1}}}><ArrowCircleDownOutlinedIcon/></IconButton>
            </CardContent>
           

            </Card>


        </Grid>
        ))}
        </Grid>
        <Box sx={{display:'flex', justifyContent: 'center', paddingBottom: '10vh'}}>

<Pagination count={maxPageNum} onChange={handlePageChange} size='large'/>

</Box>
            </Box>
                
           
            </ThemeProvider>


        </div>
    )
}


export {Userguide};