
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';

function Intro({badge, title, content}) {
    return (
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around', flexWrap:'nowrap', padding:"0 10%", gap:"2vh"}}>
        <Box sx={{alignSelf:'center', backgroundColor:'#AE2424', width:{xs:'30%', sm:'15%', md:'10%', lg:'10%', xl:'5%'}, py:"2px", borderRadius: '10px', alignItems:'center', display:'flex', justifyContent:'center'}}>
            <Typography textAlign='center' color="#FFFFFF">{badge}</Typography>
        </Box>
            <Typography variant='h3' sx={{fontWeight: 'bold', textAlign:'center'}}>{title}</Typography>
            <Typography sx={{textAlign:content==='event_banner2'?'left':'center', width:{xs:'initial',lg:'60vw'}, fontWeight:'bold', color:'darkGrey', typography: {xs:'body2', md:'h6'}}}>{<Trans i18nKey={content} components={{ 1: <br /> }}/>}</Typography>
    </Box>
    )
    
}

export default Intro;