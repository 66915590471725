import { MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = ({mobile}) => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);
  };

  return (

    <div id="language-switcher" style={{flexDirection:'row', display:'flex'}}>
    <Typography sx={{alignSelf:'center', marginRight:'5px'}}>Language /</Typography>
    <Select value={i18n.language??"en"} onChange={handleLanguageChange} sx={{"&:hover": {borderColor:'transparent'}, padding:"0", color:mobile?'black':'white', ".MuiSvgIcon-root": {color:mobile?'black':'white'},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:'transparent'}, ".MuiOutlinedInput-notchedOutline": {borderColor:'transparent'}, ".MuiOutlinedInput-input": {padding:"0"}}}>
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="kr">Korean</MenuItem>

        
</Select>

    </div>
    
 );
};

export default LanguageSwitcher;