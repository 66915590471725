import config from './config.js';
import MessageParser from './MessageParser.jsx';
import ActionProvider from './ActionProvider.jsx';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import { useTranslation } from 'react-i18next';
import { createChatBotMessage } from 'react-chatbot-kit';
import React from 'react';
import getConfig from './config.js';


export default function ChatBot () {
  const {i18n} = useTranslation();
  const [state, setState] = React.useState(getConfig())

  React.useEffect(() => {
    i18n.on("languageChanged", () => {
    setState(getConfig())
  })
  })
  
  
  return (
    <div>
      <Chatbot
        key={i18n.language}
        config={state}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </div>
  );
};