import Box from '@mui/material/Box';
import image from '../../../assets/homebanner_video.mp4';
import { Button, Divider, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom'

export default function HomeBanner() {
    const {t} = useTranslation();
    return (
        <ThemeProvider theme={theme}>
        <Box sx={{width: "100vw", height:"100vh", position:"relative", top:"0", display:'flex', alignItems:'center'}}>
                <video  autoPlay="autoplay" loop muted playsInline  className="home-banner-video">
                    <source src={image} type='video/mp4'/>
                    </video>

                <Box sx={{width:'100%',  display:'flex',  flexDirection:'column',  padding: {xs:'10vh 10%', sm:'0 10%'}, alignItems: {xs:'center', lg:'flex-start'}, position:{xs:'relative', md:'initial'}, alignSelf:{xs:'flex-end', sm:'initial'}}}>

            <Typography variant='h1' sx={{color:{xs:'black', sm:"white"}, fontWeight:'bold', textAlign:{xs:'center', lg:'left'}, mb:'3vh'}}>{t("home_banner1")}</Typography>
            <Typography  sx={{color:{xs:'black', sm:'white'}, width: {lg:'50vw', sm:'100%'}, textAlign: {lg:'left', xs:'center'}, typography:{ md:'h4', xs:'h5'}, fontSize:{lg: '2.2rem !important'}, marginBottom: '1em'}}>{t('home_banner2')}</Typography>
            <Typography  sx={{color:{xs:'black', sm:'white'}, width: {lg:'50vw', sm:'100%'}, textAlign: {lg:'left', xs:'center'}, typography:{ md:'h4', xs:'h5'}, fontSize:{lg: '2.2rem !important'}, mb:'3vh'}}>{t('home_banner3')}</Typography>

                <Button component={Link}  size='large' sx={{borderRadius: '10px', color: 'white', backgroundColor: 'red', width:{xs:'50%', sm:'30%', md:'20%', xl:'15%'}, fontSize: {xl:'1.2rem', lg:'1.1rem', xs:'0.875rem'}, fontWeight:'bold', "&:hover": {backgroundColor:'white', color:'red'} }} to="/products">{t('home_viewmore')}</Button>

    </Box>
        </Box>
        <Divider sx={{display:{xs:'block', md:'none'}}}/>

        </ThemeProvider>
            
    )
    }