
import * as React from 'react';

import {Link} from 'react-router-dom';

import {AppBar, Toolbar, Menu, Container, Button, MenuItem, Stack} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import theme from '../Theme.js';

import tovnetLogo from "../../assets/tovnet_logo.png"
import tovnetLogo3 from "../../assets/tovnet_logo3.png"


import { Image } from 'mui-image';
import { useTranslation } from "react-i18next";





function NavBar() {
  const [CompanyAnchorEl, setCompanyAnchorElNav] = React.useState(null);
  const [MediaAnchorEl, setMediaAnchorElNav] = React.useState(null);
  const [SupportAnchorEl, setSupportAnchorElNav] = React.useState(null);
  const openCompany = Boolean(CompanyAnchorEl);
  const openMedia = Boolean(MediaAnchorEl);
  const openSupport = Boolean(SupportAnchorEl);
  const { t } = useTranslation();




  const handleOpenCompany = (event) => {
      setCompanyAnchorElNav(event.currentTarget);

    }
  const handleOpenMedia = (event) => {
    setMediaAnchorElNav(event.currentTarget)
  };
  const handleOpenSupport = (event) => {
    setSupportAnchorElNav(event.currentTarget);

  };

  const handleCloseCompany = () => {
      setCompanyAnchorElNav(null);

  };
  
  const handleCloseMedia = (menu) => {
    setMediaAnchorElNav(null);

  };

  const handleCloseSupport = (menu) => {
    setSupportAnchorElNav(null);

  };


let customTheme = createTheme(theme, {
  palette: {
    primary: {
      main:"#FFFFFF"
    },
    red: "#AE2424",
    white: "#FFFFFF",
    black: "#000000",
    backgroundGrey: "#F7F8F9",
    darkGrey: "#6A6465",
    lightGrey: "#9F9697"
},
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            [theme.breakpoints.up('lg')]: {
            fontSize: '1.3rem',
          },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '1.0rem',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.5rem'
        },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.0rem',
          },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '1.0rem',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.7rem'
        }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('lg')]: {
              fontSize: '1.5rem'
          },
          [theme.breakpoints.between('md', 'lg')]: {
              fontSize: '1.0rem'
          },
          [theme.breakpoints.down('md')]: {
              fontSize: '0.5rem'
          }
      }
      }
      
  },
},
  
  typography: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.0rem',
      },
    [theme.breakpoints.up('md')]: {
        fontSize: '0.7rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.5rem',
  },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.3rem',
    }
    }
    

});
customTheme = responsiveFontSizes(customTheme);

const trigger = useScrollTrigger({
  disableHysteresis: true,
  threshold: 300,
});








  return (
    <div id="navbar" style={{position:'fixed', width: '100vw', top:'0', zIndex:'1'}}>
    <ThemeProvider theme={customTheme}>
      
    <AppBar className="navbar" sx={{ backgroundColor: trigger? "red":"transparent", boxShadow: 'none', padding:trigger?"5px 0":"3% 0 0 0", transition:'padding 0.3s', animationTimingFunction: 'ease-in'}}>
      <Container maxWidth="100vw" >
        <Toolbar disableGutters sx={{padding:'0 5%'}}>
        
          
          <Link to="/" style={{textDecoration: 'none'}}><Image height={trigger?"auto":"7vh"} width={trigger?"5vw":"auto"} sx={{marginRight:"auto", flexGrow:'1', backgroundCover:'contain'}} src={trigger?tovnetLogo3:tovnetLogo}/></Link>
         

          <Stack direction="row" spacing={{sm:3, md:6}} marginLeft="auto">
            <Button 
            sx={{fontWeight:'bold', '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.1)'}}}

              id='CompanyButton' 
              onClick={handleOpenCompany}
              endIcon={<KeyboardArrowDownIcon />}
             
               
              aria-haspopup='true' 
              aria-controls={openCompany ? 'CompanyMenu' : undefined} 
              aria-expanded={openCompany ? true:false}
              
              >{t("company")}</Button>
            <Button component={Link} sx={{fontWeight:'bold', '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.1)'}}} id='ProductsButton' to="/products">{t("products")}</Button>
            <Button 
              sx={{fontWeight:'bold', '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.1)'}}}
              id='MediaButton'
              onClick={handleOpenMedia} 
              open={openMedia} 
              aria-haspopup='true' 
              aria-controls={openMedia ? 'MediaMenu' : undefined} 
              aria-expanded={openMedia ? true:undefined}
              endIcon={<KeyboardArrowDownIcon />}
              >{t("media")}</Button>
            <Button 
            sx={{fontWeight:'bold', '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.1)'}}}
            id='SupportButton'
            onClick={handleOpenSupport} 
            open={openSupport} 
            aria-haspopup='true' 
            aria-controls={openSupport ? 'SupportMenu' : undefined} 
            aria-expanded={openSupport ? true:undefined}
            endIcon={<KeyboardArrowDownIcon/>}
            >{t("support")}</Button>
          </Stack>
            <Menu id='CompanyMenu' open={openCompany} anchorEl={CompanyAnchorEl} MenuListProps={{'aria-labelledby':'CompanyButton'}}  sx={{ "& .MuiPaper-root": {backgroundColor: "red"}}} onClose={handleCloseCompany}>
            <Link to="/company/about-us" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseCompany} href="/Company/AboutUs">{t("aboutus")}</MenuItem></Link>
            <Link to="/company/tovnet-ci" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseCompany} >{t("logo")}</MenuItem></Link>
            <Link to="/company/head-office" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseCompany} >{t("headofficebanner")}</MenuItem></Link>
            {/* <Link to="/company/store-location" style={{textDecoration: 'none', color:'white', display:'none'}}><MenuItem onClick={handleCloseCompany} >{t("storelocation")}</MenuItem></Link> */}
            <Link to="/company/store-location" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseCompany} >{t("storelocation")}</MenuItem></Link>

            </Menu>
            <Menu id='MediaMenu' open={openMedia} anchorEl={MediaAnchorEl}  MenuListProps={{'aria-labelledby':'MediaButton'}} sx={{ "& .MuiPaper-root": {backgroundColor: "red"}}} onClose={handleCloseMedia}>
            <Link to='/media/news' style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseMedia}>{t("news")}</MenuItem></Link>

            <Link to="/media/installation-case" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseMedia}>{t("installationcase")}</MenuItem></Link>
            <Link to="/media/catalogue" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseMedia}>{t("catalogue")}</MenuItem></Link>
            </Menu>
            <Menu id='SupportMenu' open={openSupport} anchorEl={SupportAnchorEl} MenuListProps={{'aria-labelledby':'SupportButton'}} sx={{ "& .MuiPaper-root": {backgroundColor: "red"}}} onClose={handleCloseSupport}>
            <Link to="/support/faq" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseSupport}>{t("faq")}</MenuItem></Link>
            <Link to="/support/notice" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseSupport}>{t("notice")}</MenuItem></Link>
            <Link to="/support/user-guide" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseSupport}>{t("userguide")}</MenuItem></Link>
            <Link to="/support/contact-us" style={{textDecoration: 'none', color:'white'}}><MenuItem onClick={handleCloseSupport}>{t("contactus")}</MenuItem></Link>
            </Menu>

          
        
        </Toolbar>
      </Container>
    </AppBar>
    </ThemeProvider>
    </div>
  );
}
export default NavBar;
