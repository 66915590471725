import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { Pagination, Tab } from '@mui/material';
import theme from '../../Theme.js';

import GeneralBanner from '../general_section/GeneralBanner.js';
import axios from 'axios';
import React from 'react';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from "@mui/lab/TabContext";
import TabList from '@mui/lab/TabList';

import { calculatePagesCount } from '../../Functions/maxPage.js';
import LoadingAnimation from '../general_section/LoadingAnimation.js';

import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { API_URL } from '../../../constants.js';
import { AiSolution } from './AiSolution.js';




export default function Products() {

  const [products, setProducts] = React.useState([]);
  const [tabs, setTabs] = React.useState([]);
  const [tabValue, setTabValue] = React.useState('1');
  const [, setPageNum] = React.useState(1);
  const [maxPageNum, setMaxPageNum] = React.useState(1);
  const [playAnimation, setPlayAnimation] = React.useState(true);
  const {i18n, t} = useTranslation();
  const [searchParams] = useSearchParams();

  
  

  document.body.style.overflow = 'unset'

  



  


  const handlePageChange = (event, newValue) => {
    setPageNum(newValue);
    
    axios.get(`https://${API_URL()}/adminAPI/api/resources/product/actions/list?filters.product_type_id=${tabValue}&page=${newValue}&perPage=12&sortBy=post_date&direction=desc`).then((res)=>{

      setProducts(res.data.records);
    }).catch(error => {console.log(`Error: ${error}`)});
    
  }
  

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        axios.get(`https://${API_URL()}/adminAPI/api/resources/product/actions/list?filters.product_type_id=${newValue}&page=1&perPage=12&sortBy=post_date&direction=desc`).then((res)=>{

          setProducts(res.data.records);
            setMaxPageNum(calculatePagesCount(9, res.data.meta.total))
          }).catch(error => {console.log(`Error: ${error}`)});
      
    };






  



  

  React.useEffect( () => {
    axios.get(`https://${API_URL()}/adminAPI/api/resources/product_type/actions/list?sortBy=product_type_id`).then((res)=>{
      setTabs(res.data.records);
    }).catch(error => {console.log(`Error: ${error}`)});

    axios.get(`https://${API_URL()}/adminAPI/api/resources/product/actions/list?filters.product_type_id=1&page=1&perPage=12&sortBy=post_date&direction=desc`).then((res)=>{
      if (searchParams.get('producttype')) {
        setTabValue(searchParams.get('producttype'))
      } 
      else {
        setTabValue("1");
      } 
      setPageNum("1");
      setMaxPageNum(calculatePagesCount(12, res.data.meta.total))
    }).catch(error => {console.log(`Error: ${error}`)});

    // getProduct();
    axios.get(`https://${API_URL()}/adminAPI/api/resources/product/actions/list?filters.product_type_id=${tabValue}&page=1&perPage=12&sortBy=post_date&direction=desc`).then((res)=>{

      setProducts(res.data.records);
      }).catch(error => {console.log(`Error: ${error}`)});

    const onPageLoad = () => {
      setTimeout(()=> {
        setPlayAnimation(false)
      }, 1000)
      
    }
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
   
      
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    
    }

 



    
  },[]);






  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)
    }

      <GeneralBanner label={t('products')} preset={"product"}/>
      
      <Box sx={{margin: '5vh 0', padding: '0 10% 5%', boxShadow: '0 15px 18px #eeeeee'}}>
      
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
          }}
        >

              <TabContext value={`${tabValue}`}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent:'center', display: 'flex', gap: "7%"}}>
            <TabList onChange={handleTabChange} sx={{'.MuiTabs-indicator': {background: "#AE2424"}, '.Mui-selected': {color: "#000000 !important"}, '.MuiTab-root': {fontWeight:'bold', fontSize:{xs:'small',sm:'medium', lg:'xx-large'}}}}>


              <Tab key={`producttab-1`} label={t('General Product')} value={'1'} sx={{width: '25vw'}}/>
              <Tab key={`producttab-2`} label={t('Accessory')} value={'2'} sx={{width: '25vw'}}/>

              <Tab key={`producttab-3`} label={t('Solution')} value={'3'} sx={{width: '25vw'}}/>

            </TabList>
            </Box>
              {tabs.map((tab, index)=> (
                <TabPanel key={`producttabpanel-${index}`} value={tab.params.product_type_id} sx={{padding:'0'}} >
                  <Grid container spacing={4} sx={{mt:'2vh'}}>
                    {products.map((product,index) => (
                      <Grid item key={product.params.product_id} xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Link to={`/products/product-specification?productid=${product.params.product_id}`} style={{textDecoration:'none'}}>
                          <Card className='product-card' sx={{position:'relative', display: 'flex', flexDirection: 'column' , boxShadow: '15px', height:'100%', '&:hover':{borderTop:'3px solid #AE2424'}, transition: 'border-color 0.2s ease-in-out'}} >
                            <div className="product-card-img-wrapper" style={{overflow:'hidden'}}>
                              <CardMedia className="product-img" component="div" sx={{pt: '80%', }} image={product.params.filePath}/>
                            </div>
                            <CardContent  sx={{display:'flex', flexDirection:'column', flexGrow:1, width:'100%', backgroundColor:'#f6f6f6', paddingBottom:'0'}}>
                              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', flexDirection:'column',height:'100%', textAlign:'center'}}>
                                <Typography className="product-card-content" variant='h5' sx={{color:'red', fontWeight:'bold', marginBottom:'5%'}}>
                                {i18n.language==='kr'?product.params.name_kr:product.params.name}
                                </Typography>
                              </div>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
              ))}

          <TabPanel key={`producttabpanel-3`} value={'3'} sx={{padding:'0', marginTop:'5vh'}} > 
          <AiSolution/>
          </TabPanel>

            

           
            </TabContext>


        </Box>

        <Box sx={{display:tabValue==="3"?"none":"flex", justifyContent: 'center', paddingBottom: '10vh', paddingTop:'10vh'}}>

                    <Pagination count={maxPageNum} onChange={handlePageChange} size='large'/>
         


                </Box>
      
        </Box>
  
     </ThemeProvider>
  );
}

