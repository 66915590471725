import React from 'react';
import { createCustomMessage } from 'react-chatbot-kit';
import { useTranslation } from 'react-i18next';



const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const {i18n ,t} = useTranslation()
    const handleProduct = () => {
        const botMessage = [
            createChatBotMessage(t('chatbot_product')),
            createCustomMessage("test", 'custom', {withAvatar:false, delay:1000}),
            
    ]


        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, ...botMessage],
        }))
    }

    const handleContact = () => {
        const botMessage = [
            createChatBotMessage(i18n.language === 'kr'?"고객지원센터를 안내드립니다.":"You can contact us through following details."),
            createCustomMessage('test', 'contactus', {withAvatar:false, delay:1000})
        ]
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, ...botMessage],
        }))
    }

    const handleGeneral = () => {
        const botMessage = [
          createChatBotMessage(t('chatbot_general')),
          createCustomMessage('test', 'general', {withAvatar:false, delay: 1000})

        ] 
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, ...botMessage],
      }))

    }

    const handleService = () => {
      const botMessage = [
        createChatBotMessage(i18n.language === 'kr'?"A/S를 전문적으로 수행하는 자체 C/S센터 및  전국 A/S지정점을 운영하고 있으며, 보다 신속한 서비스를 제공하기 위해 노력합니다.":"We operate our own Customer Service Center and nationwide Authorized Service Centers specializing in A/S (After-Sales Service) and strive to provide faster service."),
        createCustomMessage('test', 'contactus', {withAvatar:false, delay:1000}),
        createChatBotMessage(i18n.language === 'kr'?`출동 서비스를 요청하는 경우 별도의 출장비용이 추가 될 수 있습니다. 아울러 내방 수리를 원하는 고객님께서는 사전 센터 전화문의 후 방문하여 주시기 바랍니다.
        택배로 발송하여 수리를 원하시는 경우 발송시에 고장내역, 연락처, 상호를 꼭 기록하여 제품과 같이 발송하여 주십시오
        `:"If you request an on-site service, additional service fees may apply. Additionally, if you wish to visit for in-person repairs, please contact the center in advance and visit after making a phone inquiry. If you want to send the product for repair via courier, please make sure to record the malfunction details, contact information, and your name, and send them along with the product."),
        createCustomMessage('test', 'service', {withAvatar:false})
      ]
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, ...botMessage],
    }))
    }

    const handleWarranty = () => {
      const botMessage = [
        createCustomMessage('test', 'warranty', {withAvatar:false, delay:1000}),
        createCustomMessage('test', 'warranty2', {withAvatar:false, delay:1000})

      ]
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, ...botMessage],
    }))
    }

    const handleRepair = () => {
      const botMessage = [
        createCustomMessage('test', 'repair',{withAvatar:false, delay:1000})
      ]
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, ...botMessage],
    }))
    }

    const handleQuestion = () => {
      const botMessage = [
        createChatBotMessage(i18n.language === 'kr'?"문의 상담 페이지에서 문의하세요.":"Please inquire on the Contact Us page."),
        createCustomMessage('test', 'general')
      ]
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, ...botMessage],
    }))
    }

    const handleQuit = () => {
      const initialMessages= [
        createChatBotMessage(i18n.t("chatbot_intro1")),
        createChatBotMessage(i18n.language==='kr'?"처음으로 돌아가시려면 '돌아가기'를 입력하세요.":"Type 'quit' to go back to the first step."),
        
        createChatBotMessage(
          i18n.t("chatbot_intro2"),
            {
              withAvatar: false,
              delay: 500,
              widget: "options",
            }
            
          ),
      
         
    ]
    

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, ...initialMessages]
      }))
    }
    
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleProduct,
            handleContact, 
            handleGeneral,
            handleService,
            handleWarranty,
            handleRepair,
            handleQuestion,
            handleQuit
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;