import {Box, Typography} from "@mui/material";
import { keyframes } from "@emotion/react";
import iconImage from "../../../assets/tovnet.png"


const myEffect = keyframes`
    0% {
        top: 0px;
    }
    50% {
        top: -15px;
    }
    100% {
        top: 0px;
    }
`;


export default function LoadingAnimation() {
    const str = 'Watching...'
    let delay = 200;

    return (
        
        <Box sx={{position:'absolute', width:'100vw', height:'100vh !important', backgroundColor:'red', display:'flex', justifyContent:'center', alignItems:'center', zIndex:'4', overflow:'hidden'}}>
          <Box sx={{boxShadow:'15px',display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <img src={iconImage} style={{marginBottom:'3vh', height:"15vh"}} alt="animation icon"/>
            <Box sx={{display:'flex', flexDirection:'row'}}>
            {
                str.split("").map((element,index) => {
                    return (
                    <Typography key={`animation-${element}-${index}`} sx={{color:'white', fontWeight:'bold', fontSize:{xs:'2rem', md:'3rem'}, animation: `${myEffect} 1.3s ease infinite`, position: 'relative', animationDelay:`${index * 60 + delay}ms`}}>{element}</Typography>
                    )

                    
                })
            }
            </Box>
            
            




          </Box>

        </Box>
   
    )
}
