import { Box, Typography, AccordionDetails } from "@mui/material";
import GeneralBanner from "../general_section/GeneralBanner.js";
import { CustomAccordion, AccordionSummary } from "../general_section/BoardAccordion";
import React from "react";
import axios from "axios";
import RemoveIcon from '@mui/icons-material/Remove';
import {red} from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import LoadingAnimation from "../general_section/LoadingAnimation.js";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { API_URL } from "../../../constants.js";





function Notice() {
    const [expanded, setExpanded] = React.useState(null);
    const [notice, setNotice] = React.useState([]);
    const {i18n, t} = useTranslation()

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const [playAnimation, setPlayAnimation] = React.useState(true)
    document.body.style.overflow = 'scroll'


    React.useEffect( () => {
        axios.get(`https://${API_URL()}/adminAPI/api/resources/notice_board/actions/list?filters.language=${i18n.language}`).then((res)=>{
          setNotice(res.data.records);
        }).catch(error => {console.log(`Error: ${error}`)});

        const onPageLoad = () => {
     
            setTimeout(()=> {
              setPlayAnimation(false)}, 1000)
            
          }
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }


        i18next.on('languageChanged', function(lng) {
            axios.get(`https://${API_URL()}/adminAPI/api/resources/notice_board/actions/list?filters.language=${i18n.language}` ).then((res)=>{
                setNotice(res.data.records);
                }).catch(error => {console.log(`Error: ${error}`)});
  
        })

        
      });



    return (
        <div id='notice'>
        {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

        <GeneralBanner label={t("notice")} preset={'notice'}/>
 

            <Box sx={{marginTop: '5vh',boxShadow: '0 15px 18px #eeeeee'}}>
            <div style={{padding: '0 10% 5% 10%'}}>
            {notice.map((singleNotice, index) => (
                    <CustomAccordion key={`notice-${index}`} expanded={expanded === singleNotice.params.id} onChange={handleChange(singleNotice.params.id)}>
                        <AccordionSummary expandIcon={expanded === singleNotice.params.id ? <RemoveIcon/>:<AddIcon sx={{color:red[500]}}/>}>
                            <Typography variant='h6' sx={{fontWeight:'bold'}} color={expanded===singleNotice.params.id?"red":"black"}>
                            {singleNotice.params.title}
                                </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography  dangerouslySetInnerHTML={{__html: singleNotice.params.content}} sx={{color:'darkGrey', fontWeight:'bold', 'img': {width: {xs:'100%', md:'auto'}}}}/>

                        </AccordionDetails>
                    </CustomAccordion>
                ))}
                </div>
            </Box>
        </div>
    );
}

export {Notice};