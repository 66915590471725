import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import { Box, Grid, ThemeProvider, IconButton} from "@mui/material";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Image from 'mui-image';
import logo from '../assets/tovnet_logo_1.png';
import theme from "./Theme.js";
import { useTranslation } from "react-i18next";
import PhoneIcon from '@mui/icons-material/Phone';



export default function Footer() {
  const {t} = useTranslation();
  const elements = [[t("company"), t("products"), t("media"), t("support")], [t("aboutus"), t("General Product"), t("news"), t("faq")], [t("logo"), t("Accessory"), t("installationcase"), t("notice")], [t("headofficebanner"), t("Solution"), t("catalogue"), t("userguide")], [t("storelocation"), "","", t("contactus")]]
  const links = [['','/products', '',''],['/company/about-us', '/products?producttype=1', '/media/news','/support/faq'], ['/company/tovnet-ci', '/products?producttype=2', '/media/installation-case', '/support/notice'], ['/company/head-office', '/products?producttype=3','/media/catalogue' , '/support/user-guide'], ['/company/store-location', '', '', '/support/contact-us']]
  return (
    <ThemeProvider theme={theme}>
    <div className="footer">

    <Grid container flexDirection={{md:'row',  xs:'column'}} justifyContent='space-evenly' alignItems='center' alignContent='center' height='auto' minHeight='40vh' marginTop='10vh' boxShadow="0 -10px 18px #eeeeee">
        
        <Grid item  display='flex' flexDirection='column' sx={{justifyContent:{xs:'space-evenly', md:'center'}}} minHeight='inherit' alignItems='center' >
            <Image src={logo} sx={{width:{lg:'10vw !important', md:'25vw !important', sm:'40vw !important', xs:'50vw !important'}, my:{xs:'5vh', md:''}}}/>

            <Box sx={{px:{xs:'5%'}}}>


            <Box sx={{display:{xs:'block', sm:'none'}}}>

                <Typography sx={{fontWeight:'bold', color:'lightGrey'}}>{t("address")}</Typography>
                <Typography sx={{typography:{xs:'body1', md:'h6'},fontWeight:'bold !important', color:'red'}}>info@tovnet.co.kr</Typography>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center', gap: {xs:'3%', md:'10%'}}}>
                  <IconButton href="https://www.facebook.com/100072024465833/videos" target="__blank"><FacebookOutlinedIcon sx={{fontSize: {sm:'2rem', md:'2.5rem'}, color: 'lightGrey'}} /></IconButton>
                  <IconButton href="https://www.instagram.com/tovnet_official/" target="__blank"><InstagramIcon sx={{fontSize: {sm:'2rem', md:'2.5rem', color: 'lightGrey'}}}/></IconButton>
                  <IconButton href="https://www.youtube.com/@user-np6zh9ol8i" target="__blank"><YouTubeIcon sx={{fontSize: {sm:'2rem', md:'2.5rem'}, color: 'lightGrey'}}/></IconButton>
                  </Box>
                  </Box>
            </Box>
            
        </Grid>

        <Box sx={{padding:'5% 0', flexDirection:'row', minHeight:'inherit', width: '40vw', display:{xs:'none', lg:'flex'}, justifyContent: 'space-evenly'}}>
              
                
                <Grid container>
      {elements.map((row, rowIndex) => (
        <Grid container item key={rowIndex}>
          {row.map((col, colIndex) => (
            <Grid
              item
              xs={3}
              key={colIndex}
            > {rowIndex === 0 && (
              <Typography sx={{fontWeight:'bold !important', color: 'red', textAlign:'center', typography: {xs:'body', md:'h6'}}}>
                {col}
              </Typography>

            )}  {rowIndex > 0 && (
              <Typography sx={{fontWeight:'bold', textAlign:'center'}}>

                  <Link to={links[rowIndex][colIndex]} style={{color:'inherit'}}>
                  {col}
                </Link>

              

              
                
                
               
                
              </Typography>
            )}
              
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  

                
                
            
            
            

        </Box>
      
        <Box sx={{display:{xs:'none', sm:'flex'}, flexDirection:'column', justifyContent:'center',rowGap:{xs:'0', md:'2rem'}, minHeight:'inherit', alignItem:'center', textAlign:'center'}}>
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <PhoneIcon sx={{color:'red', marginRight:'3%'}}/><Typography  sx={{typography:{xs:'body1', md:'h6'},fontWeight:'bold !important', color:{xs:'lightGrey', sm:'darkGrey'}}}>{t("phone")}</Typography></Box>
                <Typography sx={{typography:{xs:'body1', md:'h6'},fontWeight:'bold !important', color:{xs:'lightGrey', sm:'darkGrey'}}}>{t("address")}</Typography>
                <Typography sx={{typography:{xs:'body1', md:'h6'},fontWeight:'bold !important', color:'red'}}>info@tovnet.co.kr</Typography>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center', gap: {xs:'3%', md:'10%'}}}>
                  <IconButton href="https://www.facebook.com/100072024465833/videos" target="__blank" ><FacebookOutlinedIcon sx={{fontSize: {sm:'2rem', md:'2.5rem'}, color: 'lightGrey'}} /></IconButton>
                  <IconButton href="https://www.instagram.com/tovnet_official/" target="__blank" ><InstagramIcon sx={{fontSize: {sm:'2rem', md:'2.5rem', color: 'lightGrey'}}}/></IconButton>
                  <IconButton href="https://www.youtube.com/@user-np6zh9ol8i" target="__blank" ><YouTubeIcon sx={{fontSize: {sm:'2rem', md:'2.5rem'}, color: 'lightGrey'}}/></IconButton>
                  
                  </Box>

                </Box>
         
        </Grid>


    <Box
      sx={{
        backgroundColor: 'black',
        p: "15px"
      }}
      component="footer"
    >
      <Container maxWidth="sm">
        <Typography variant="body2" color="white" align="center">
          {"© "}
          {new Date().getFullYear()}
          {"."}{" "}
          <Link style={{color:"inherit"}} to="/">
            TOVNET.
          </Link>
          {" All rights reserved."}
        </Typography>
      </Container>
    </Box>
    </div>
  </ThemeProvider>
  );
}