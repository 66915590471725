import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination, Autoplay, EffectFade, EffectCoverflow} from 'swiper/modules';
import {Button, CardContent, CardMedia, Fade, Grow } from '@mui/material'
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Link} from 'react-router-dom';

import DateFormatter from '../Functions/dateFormatter.js';

import HomeBanner from './general_section/HomeBanner.js';
import React from 'react';
import axios from 'axios';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import LoadingAnimation from './general_section/LoadingAnimation.js';
import { useTranslation } from 'react-i18next';
import '../../App.css'
import youtubeThumbnail from 'youtube-thumbnail';
import i18next from 'i18next';
import { API_URL } from '../../constants.js';
import Modal from './general_section/Modal.js';
import { useRecoilState } from 'recoil';
import { modalState } from './general_section/globalState.js';




const SocialMedia=[[ "https://www.youtube.com/watch?v=OnSxiBWCQlo&t=30s","https://www.youtube.com/watch?v=R9QjKtY87z4","https://www.youtube.com/watch?v=aM3an2GFW40"],["https://www.youtube.com/watch?v=NJHORVnzBRE", "https://www.youtube.com/watch?v=hQFCw9VLc6M", "https://www.youtube.com/watch?v=egnsMepLnl4"]];
const socialMedia_en = [["https://youtu.be/Evb8pY80SXk", "https://youtu.be/lutDdNOcvio", "https://youtu.be/NWCGGJddC78"], ["https://youtu.be/Z96jONRPJ7Q", "https://youtu.be/Evb8pY80SXk", "https://youtu.be/lutDdNOcvio"]]

function MobileSolution() {
  const {i18n, t} = useTranslation();

  return (
    <Swiper
       
        loop={true}
        speed={1000}
        centeredSlides={false}
        spaceBetween={0}
        navigation={false}
        pagination={false}
        breakpoints= {{
          460: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          },
          1440: {
            slidesPerView: 4
          }
        }}
        className={window.innerWidth>1200?'solution-swiper':'solution-swiper mobile-solution-swiper'}
        key={`solution-swiper-${i18n.language}`}
      >
    <SwiperSlide key={`solution-swiper-1`} >
    <div className="solution-item solution-item-one">
      <div className="solution-overlay"/>
      <span className="heading">01</span>

      <div className="solution-content">

      <Typography className="solution-heading" sx={{typography:{xs:'h5', xl:'h4'}, color:'white', fontWeight:'bold !important', mt:'20px'}}>{t("solution_heading1")}</Typography>

          <Typography variant='h6' className="solution-content-info" sx={{color:'white'}}>{t("solution_content1")}</Typography>
      </div>

    </div>
    </SwiperSlide>

    <SwiperSlide key={`solution-swiper-2`} >

    <div className="solution-item solution-item-two">
      <div className="solution-overlay"/>
      <span className="heading">02</span>
      <div className="solution-content">
          <Typography sx={{typography: {xs:'h5', xl:'h4'}, color:'white', fontWeight:'bold !important', mt:'20px'}}>{t("solution_heading2")}</Typography>
          <Typography variant='h6'  className="solution-content-info" sx={{color:'white'}}>{t("solution_content2")}</Typography>
      </div>
      
    </div>
    </SwiperSlide>

    <SwiperSlide key={`solution-swiper-3`} >

    <div className="solution-item solution-item-three">
      <div className="solution-overlay"/>
      <span className="heading">03</span>
      <div className="solution-content">
          <Typography sx={{typography:{xs:'h5', xl:'h4'}, color:'white', fontWeight:'bold !important', mt:'20px'}}>{t("solution_heading3")}</Typography>
          <Typography variant='h6'  className="solution-content-info" sx={{color:'white'}}>{t("solution_content3")}</Typography>
      </div>
      
    </div>
    </SwiperSlide>

    <SwiperSlide key={`solution-swiper-4`} >

    <div className="solution-item solution-item-four">
      <div className="solution-overlay"/>
      <span className="heading">04</span>
      <div className="solution-content">
          <Typography sx={{typography:{xs:'h5', xl:'h4'}, color:'white', fontWeight:'bold !important', mt:'20px'}}>{t("solution_heading4")}</Typography>
          <Typography variant='h6'  className="solution-content-info" sx={{color:'white'}}>{t("solution_content4")}</Typography>
      </div>
    </div>
    </SwiperSlide>

            
</Swiper>
  )
}




function Home() {
    const [news, setNews] = React.useState([]);
    const [product, setProduct] = React.useState([]);
    const container = React.useRef(null);
    const newsContainer = React.useRef(null)
    const [, setUserGuide] = React.useState([]);

    const [playAnimation, setPlayAnimation] = React.useState(true);
    const {i18n, t} = useTranslation();
    const [youtube, setYoutube] = React.useState([]);
    const [isMobile, setIsMobile] = React.useState(false);
    const [showModal, setShowModal] = useRecoilState(modalState);

    const mobile = () => {
      if (window.innerWidth < 900) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
  



    React.useEffect( () => {

     
        function getList(language) {
          axios.get(`https://${API_URL()}/adminAPI/api/resources/news/actions/list?sortBy=post_date&direction=desc&page=1&perPage=6&filters.language=${language}`).then((res)=>{
            setNews(res.data.records)
            }).catch(error => {console.log(`Error: ${error}`)});
    
            axios.get(`https://${API_URL()}/adminAPI/api/resources/product/actions/list?sortBy=post_date&direction=desc&page=1&perPage=10`).then((res)=>{
                setProduct(res.data.records)
            }).catch(error => {console.log(`Error: ${error}`)});
    
            axios.get(`https://${API_URL()}/adminAPI/api/resources/user_guide/actions/list?sortBy=post_date&direction=desc&page=1&perPage=6&filters.language=${language}`).then((res)=>{
                setUserGuide(res.data.records)
            }).catch(error => {console.log(`Error: ${error}`)});
        }

        getList(i18n.language);
        setYoutube(i18n.language==='kr'?SocialMedia:socialMedia_en)

        i18next.on('languageChanged', function(lng) {
          axios.get(`https://${API_URL()}/adminAPI/api/resources/user_guide/actions/list?sortBy=post_date&direction=desc&page=1&perPage=6&filters.language=${i18n.language}`).then((res)=>{
              setUserGuide(res.data.records);
              }).catch(error => {console.log(`Error: ${error}`)});
          if (i18n.language === 'kr') setYoutube(SocialMedia);
          else setYoutube(socialMedia_en)



      })

      mobile()

 

       

        const onPageLoad = () => {
            setTimeout(()=> {
              setPlayAnimation(false)}, 1000)
            
          }
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }


    
      
        
       
        

    
    
  }, [i18n.language]);


  const newstrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: window.innerHeight>900?700:400,
  });


  const producttrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: window.innerHeight > 900?1000:900,
});


  const howtousetrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: window.innerHeight > 900?2000:1500,
  });

  const youtubetrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: window.innerHeight > 900?2700:2200,
  });
  








  document.body.style.overflow = 'scroll'




    return (





    <div id='home'>
      
        
        
            {playAnimation && (document.body.style.overflow = 'hidden') && (<LoadingAnimation/>)}

      {showModal? <Modal onClose={setShowModal}/>:null}
      
        
        <HomeBanner/>
       

        <Box sx={{marginTop: '10vh'}} className={newstrigger?"home-news-section-1-active":"home-news-section-1"}>
            
      
        <Box padding="0 10%" sx={{display:'flex', alignItems:'flex-end', marginTop:'5vh', flexDirection:'row',mb: {xs:'5vh', md:'0'}}}>
          <Typography variant='h3' sx={{fontWeight:'bold', color:'red', width:{xs:'50%', xl:'unset'}}}>{t('news')}</Typography>
          <Typography variant='h5' sx={{marginLeft: '3%', fontWeight:'bold', display:{xs:'none', xl:'block'}}}>{t('news_content')}</Typography>
          {isMobile?
          <Link to="/media/news" style={{display:'flex', textDecoration:'none',color:'black', marginLeft:'auto'}}><Typography sx={{typography: {xs:'body2', md:'h6'}, "&:hover": {color:'red', textDecoration:'underline'}}}>View More</Typography><KeyboardArrowRightIcon sx={{fontSize:{xs:'1rem', md:'1.5rem'}}}/></Link>:
          <Button component={Link}  size='large' sx={{padding:'0.5rem 0.7rem', marginLeft:'auto', borderRadius: '10px', color: 'white', backgroundColor: 'red',  fontWeight:'bold', "&:hover": {backgroundColor:'white', color:'red', border:'1px solid #AE2424'} }} to="/media/news">{t('home_news_viewmore')}</Button>
          }

        </Box>
        
        </Box>

        <Fade in={newstrigger} timeout={1500}> 
          
        <Box sx={{padding:"0 10%", mb:'10vh'}}>
      
       { news.length > 0 &&
        <Swiper
       
        loop={true}
        speed={1000}
        slideToClickedSlide={true}
        centeredSlides={false}
        spaceBetween={10}
        autoplay={{
            delay:2000,
            disableOnInteraction:true
        }}
        navigation={false}
        pagination={{
          clickable: true,
          dynamicBullets:true,
          dynamicMainBullets:2

        }}
        breakpoints= {{
          460: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 2
          },
          1600: {
            slidesPerView: 3
          }
        }}
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        className={window.innerHeight>900?"news-swiper":"news-swiper swiper-mobile"}
        key={`news-${i18n.language}`}
        
      >

            
{news.map((singlenews, index) => (
  <SwiperSlide key={`news-swiper-slide-${index}`} style={{padding:'5% 0'}}>
                    <Link className='card' to={`/media/news/news-page?newsid=${singlenews.params.news_id}`} style={{textDecoration:'none'}}> {/*transition:'transform 500ms ease-in-out, background-color 500ms ease-in-out',  "&:hover": {transform:'rotateY(180deg)', backgroundColor:'black', ".news-card": {display:'none'}, '.card-back':{display:'flex'}}*/}
                    <Box className='card-front' position='relative' sx={{mx:'1vw', borderRadius:'15px', boxShadow:2,  overflow:'hidden', }} >
                  
                        <Box >
                        

                        <Card className="news-card" ref={newsContainer} sx={{minHeight:'45vh', maxHeight:'45vh', display:'flex', flexDirection:'column', padding:'0', boxShadow:"none", "&:hover":{".MuiTypography-root": {textDecoration:'underline'}, backgroundColor:'#eee'}}} >

                       
                            <div style={{overflow:'hidden'}}>
                            <CardMedia className="news-card-media" component="div" sx={{aspectRatio:'1.35', zIndex:'0', '&:hover':{transform:'scale(1.2)'}, transition:'transform 0.2s'}} image={singlenews.params.filePath} />

                            </div>
                            <CardContent className="news-card-content" sx={{display:'flex', flexGrow:1, flexDirection:'column', paddingTop:'1%'}}>
                                <Typography sx={{display:'flex', fontWeight:'bold !important', overflow:'hidden', typography:{xs:'h6', md:'h5'}, marginTop:'5%'}}>{singlenews.params.title}</Typography>
                                <Typography sx={{color:'red', typography:{xs:'body2', md:'body1'}, marginTop:'auto'}}>{DateFormatter(singlenews.params.post_date)}</Typography>
                            </CardContent>

                            
                           
                          
                            

                        </Card>
                        
                        
                        </Box>

                    
                      </Box>
                      </Link>
                      
                    </SwiperSlide>
                    
                ))}

</Swiper>}

                    
        
        </Box>
   
        </Fade>


         <Box sx={{padding:'10vh 10%', boxShadow: '0 15px 18px #eeeeee', background:"linear-gradient(to top, #434343 0%, black 100%);"}}>
                <Box sx={{display:'flex', width:'inherit', flexDirection:'row', mb: {xs:'5vh', md:'0'}, alignItems:'flex-end'}}>
                    <Typography variant='h3' sx={{fontWeight:'bold', color:'white', width:{xs:'50%', xl:'unset'}}}>{t('home_products')}</Typography>
                    <Typography variant='h5' sx={{fontWeight:'bold', marginLeft:'3%', color:'white', display:{xs:'none', xl:'block'}}}>{t('home_product_content')}</Typography>
                    {isMobile?
                      <Link to="/products" style={{display:'flex', textDecoration:'none',color:'white', marginLeft:'auto'}}><Typography sx={{typography: {xs:'body2', md:'h6'}, "&:hover": {color:'red', textDecoration:'underline'}}}>View More</Typography><KeyboardArrowRightIcon sx={{fontSize:{xs:'1rem', md:'1.5rem'}}}/></Link>:
                      <Button component={Link}  size='large' sx={{padding:'0.5rem 0.7rem', marginLeft:'auto', borderRadius: '10px', color: 'white', backgroundColor: 'red',  fontWeight:'bold', "&:hover": {backgroundColor:'white', color:'red', border:'1px solid #AE2424'} }} to="/products">{t('home_news_viewmore')}</Button>
                    }
                    
                </Box>
                
<Grow in={producttrigger} timeout={1500}>
  <div className='swiper-container'>          
 { product.length > 0 && 
<Swiper
        effect="coverflow"
        spaceBetween={30}
        centeredSlides={false}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }}
        loop={true}
        pagination={{
          el: ".swiper-pagination",
          clickable: true
        }}
        keyboard={{
          enabled: true
        }}
        
        breakpoints={{
          460: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 1.5
          },
          1024: {
            slidesPerView: 2.5
          },
          1600: {
            slidesPerView: 3.6
          }
        }}
        slideToClickedSlide={true}
        autoplay= {{
          delay: 3000,
      }}

        modules={[Pagination, Navigation, Autoplay, EffectCoverflow]}
        className={window.innerHeight>900?"product-swiper":"product-swiper swiper-mobile"}
        key={`product-${i18n.language}`}
        
      >

        {product.map((singleProduct,index)=> (

            <SwiperSlide key={`swiper-slide swiper-slide--${index}`} >
            <Link to={`/products/product-specification?productid=${singleProduct.params.product_id}`} style={{height:'100%', width:'100%', overflow:'hidden'}}>
              <div className='product-img' style={{height:'100%', width:'100%', background: `linear-gradient(to bottom, #2c536400, #203a4303, #0f2027cc), url('${singleProduct.params.filePath}') no-repeat 50% 50%`, backgroundSize:'cover', backgroundColor:'#f4eeee'}} />
            
            <div className='label'></div>
          <div className="slide-content" >
            <Typography className="product-name" variant="h5" sx={{fontWeight:'bold', display:'none', color:'white', transform:'display 0.2s ease-in-out'}}>{i18n.language==='kr'?singleProduct.params.name_kr:singleProduct.params.name}</Typography>

          
          </div>
      
          </Link>
            </SwiperSlide>
  




        ))}
        <div className="swiper-pagination"></div>
        </Swiper>
 }



</div>    


</Grow>



        </Box>
    

{/* Solution */}

<Box sx={{padding: '10vh 0 0'}}>
                <Box sx={{display:'flex', width:'inherit', flexDirection:'row', mb: '5vh', alignItems:'flex-end', padding:'0 10%'}}>
                    <Typography variant='h3' sx={{fontWeight:'bold', color:'red', mx:'auto'}}>{t('home_solution')}</Typography>
                   
                    
                </Box>
     
                <Grow in={howtousetrigger} timeout={1500}>
<div className='solution-container'>
 

  <MobileSolution/>
  </div>
  </Grow>
</Box>








               {/* youtube */}
                <Box sx={{padding:'10vh 10%'}}>

                <Box sx={{display:'flex', width:'inherit', flexDirection:'column', mb:'5vh'}}>
                    <Typography variant='h3' sx={{fontWeight:'bold', color:'red'}}>{t("YouTube")}</Typography>

                </Box>
                <Grow in={youtubetrigger} timeout={1500}>
                  <div className="youtube-section">
                  <Swiper
                          slidesPerView={1}
                          loop={true}
                          speed={1000}
                          spaceBetween={20}
                          direction={"vertical"}
                          autoplay= {{
                          delay: 2000,
                      }}
                      pagination={{
                        clickable: true,
                      

                      }}
                      grabCursor={true}
                      
                      
                          modules={[Autoplay, Pagination]}
                          className={window.innerHeight>900?"socialMedia-swiper":"socialMedia-swiper swiper-mobile"}
                          key={`socailMedia-swiper-${i18n.language}`}
                        >
        
        
                    {youtube.map((ele, index) => (
                      <SwiperSlide key={`socialmedia-slide-${index}`}>
                        <Grid container direction="row" spacing={2}>
                        {ele.map((singleSocialMedia, index) => (
                          <Grid item key={`SocialMedia-${index}`} xs={12} sm={6} md={4}>

                            <Link to={singleSocialMedia} target="__blank">
                        

                        <Card sx={{ aspectRatio:'1.7', display: 'flex', flexDirection: 'column', boxShadow: 'none'}} overflow='hidden' ref={container}>
                        

                            <CardMedia component="div" sx={{ aspectRatio:'1.7', zIndex:'0'}} image={youtubeThumbnail(singleSocialMedia).high.url}/>



                        </Card>
                        </Link>
                     </Grid>
                        ))}
                        </Grid>
                     </SwiperSlide>
                        
                    ))}
                    
                    </Swiper>
                    
                    </div>
                    </Grow>

                    
                </Box>
                

               

                




  


      

</div>

)};

export default Home;
