import { CssBaseline, ThemeProvider, Typography, Box } from "@mui/material";
import React from "react";
import theme from "../../Theme";
import GeneralBanner from "../general_section/GeneralBanner";
import { useSearchParams } from 'react-router-dom';
import axios from "axios";
import {Link} from "react-router-dom";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../constants";


function EventPage() {
    const [searchParams] = useSearchParams();
    const [eventPage, setEventPage] = React.useState([]);
    const {i18n} = useTranslation();

    const setYoutube = (language) => {
            if (eventPage.video1 && eventPage.video2 && eventPage.video3) {
                return (
    <>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:eventPage.video1}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:eventPage.video2}}/>
                <Box className="youtube-video3" dangerouslySetInnerHTML={{__html:eventPage.video3}}/>
                </>
                )
            } else if (eventPage.video1 && eventPage.video2) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:eventPage.video1}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:eventPage.video2}}/>
                </>
                )
            } else if (eventPage.video1 && eventPage.video3) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:eventPage.video1}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:eventPage.video3}}/>
                </>
                )
            } else if (eventPage.video2 && eventPage.video3) {
                return (
                    <>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:eventPage.video2}}/>
                <Box className="youtube-video2" dangerouslySetInnerHTML={{__html:eventPage.video3}}/>
                </>
                )
            } else if (eventPage.video1) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:eventPage.video1}}/>

                )
            } else if (eventPage.video2) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:eventPage.video2}}/>

                )
            } else if (eventPage.video3) {
                return (
                    <Box className="youtube-video1" dangerouslySetInnerHTML={{__html:eventPage.video3}}/>

                )
            }
    
       
        
        
        
    }

    React.useEffect( () => {
        
        axios.get(`https://${API_URL()}/adminAPI/api/resources/event_board/records/${searchParams.get('eventid')}/show`).then((res)=>{
          setEventPage(res.data.record.params)
            
        }).catch(error => {console.log(`Error: ${error}`)});


        
        
      });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <GeneralBanner label={eventPage.title} preset={"event"}/>
            <Box sx={{padding:'5% 10%'}}>
            <Link to="/media/event" id="backEventButton" style={{display:'flex', textDecoration:'none', color:'black', "&:hover": {color:'red', textDecoration:'underline'}}} ><KeyboardArrowLeft/><Typography variant="h6">Back</Typography></Link>

                <Box sx={{borderBottom:'0.5px solid grey', paddingLeft:'3%', mt:'2vh'}}>
                    <Typography variant="h3" sx={{color: 'red'}}>{eventPage.title}</Typography>
                </Box>
                <Box sx={{width:'100%', padding:'0 15%', mt:'5%', display:'flex', flexDirection:{xs:'column', lg:'row'}, justifyContent:'space-between'}}>



{setYoutube(i18n.langauge)}


</Box>
                <Box sx={{paddingLeft:'3%', mt:'5vh', width:'100%'}} className="page-content" dangerouslySetInnerHTML={{__html:eventPage.content}}>

                </Box>

            </Box>



        </ThemeProvider>
    )
}

export {EventPage}